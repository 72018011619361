import React, { Component } from 'react'
import InventoryGroupList from './InventoryGroupList';
import InventoryItemList from './InventoryItemList';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as inventoryGroupActions from '../../shared/actions/inventoryGroupActions'
import ManageTypeModal from './ManageTypeModal'
class Inventory extends Component {

    state = {
        pickedTypeUid: 'all',
        pickedGroupUid: 'none',
        forceRender: false,
        pickedItemsUid: {},
        filteredGroups: [],
    }

    componentDidMount = () => {
        const { pickedTypeUid } = this.state
        this.filterGroupByType(pickedTypeUid, this.props.groups)
    }

    componentWillReceiveProps(nextProps) {
        const { pickedTypeUid } = this.state

        this.filterGroupByType(pickedTypeUid, nextProps.groups)
    }

    sortGroupByType = () => {
        const { types, groups } = this.props
        let groupArr = []
        _.forEach([...types, { name: 'Other', uid: 'other', row: 99999999 }], type => {
            let filteredGroups = _.filter(groups, group => group.type === type.uid)

            if (_.size(filteredGroups) > 0) {
                groupArr = [...groupArr, ...filteredGroups]
            }
        })
        return groupArr
    }

    filterGroupByType = (pickedTypeUid, forceGroups) => {
        let groups = forceGroups || this.props.groups
        let filteredGroups = []
        if (pickedTypeUid !== 'all') {
            filteredGroups = _.filter(groups, group => group.type === pickedTypeUid)
        } else {
            filteredGroups = this.sortGroupByType(groups)
        }

        this.setState({
            filteredGroups,
            forceRender: true
        })
    }

    clearGroupAndItems = () => {
        this.setState({
            pickedGroupUid: 'none',
            pickedItemsUid: {},
            forceRender: true
        })
    }

    handleClickType = (pickedTypeUid) => {
        this.filterGroupByType(pickedTypeUid)
        this.setState({
            pickedTypeUid,
            pickedGroupUid: 'none',
            pickedItemsUid: {},
            forceRender: true
        })
    }

    handleClickGroup = (pickedGroupUid) => {
        this.setState({
            pickedGroupUid,
            pickedItemsUid: {},
            forceRender: true
        })
    }
    pickItem = (itemUid, forceUnPick) => {
        const { pickedItemsUid } = this.state
        let newItems = _.cloneDeep(pickedItemsUid)
        if (forceUnPick == true) {
            if (newItems[itemUid]) {
                delete newItems[itemUid]
            }
            this.setState({
                pickedItemsUid: newItems,
                forceRender: true
            })
            return
        }

        if (newItems[itemUid]) {
            delete newItems[itemUid]
        }
        else {
            newItems[itemUid] = true
        }

        this.setState({
            pickedItemsUid: newItems,
            forceRender: true
        })
    }

    setForceRender = (mode) => {
        this.setState({
            forceRender: mode
        })
    }

    render() {
        let { pickedGroupUid, forceRender, pickedItemsUid, pickedTypeUid, filteredGroups } = this.state
        let { history, match, linked, hasOtherType } = this.props

        return (
            [
                <InventoryGroupList
                    handleClickGroup={(groupUid) => {
                        this.handleClickGroup(groupUid)
                    }}
                    handleClickType={(typeUid) => {
                        this.handleClickType(typeUid)
                    }}
                    clearGroupAndItems={this.clearGroupAndItems}
                    clearType={() => this.handleClickType('none')}
                    pickedTypeUid={pickedTypeUid}
                    pickedGroupUid={pickedGroupUid}
                    history={history} match={match}
                    readOnly={linked}
                    groups={filteredGroups}
                    hasOtherType={hasOtherType}
                />,
                <InventoryItemList 
                    pickedGroupUid={pickedGroupUid}
                    forceRender={forceRender}
                    setForceRender={this.setForceRender}
                    history={history} match={match}
                    readOnly={linked}
                    pickItem={this.pickItem}
                    pickedItemsUid={pickedItemsUid}
                />,
                <ManageTypeModal history={history} match={match} />
            ]
        )
    }
}

const mapStateToProps = (state) => {
    let hasOtherType = false

    _.forEach(state.inventory.groups, group => {
        if (group.type === 'other') {
            hasOtherType = true
        }
    })

    return {
        hasOtherType,
        groups: state.inventory.groups,
        types: state.inventory.types,
        timestamp: state.inventory.timestamp,
        linked: state.shop.linked.inventory
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ ...inventoryGroupActions }, dispatch)
    return {
        actions,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Inventory)