

import React, { useState } from 'react';

function ActionsBar() {
const [count, setCount] = useState(0);
return (

                <div class="card card-flat">
                  <div class="card-body">
                      <button class="btn btn-rounded btn-space btn-secondary"><i class="icon icon-left mdi mdi-globe"></i>Set Members</button>
                      <button class="btn btn-rounded btn-space btn-secondary"><i class="icon icon-left mdi mdi-globe"></i>Appl</button>
                      <button class="btn btn-rounded btn-space btn-secondary"><i class="icon icon-left mdi mdi-globe"></i>Secondary</button>
                    </div>
                </div>
);
}

export default ActionsBar