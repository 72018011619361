import { createSelector } from "reselect";
import _ from "lodash";

const branchList = state => state.shop.list.inventory;
const hqShop = state => state.shop.detail;

export const branchOptionsSelector = createSelector(
  //ไม่มี HQ OPTION
  [branchList],
  branchList => {
    let branchOptions = [];
    _.map(branchList, branch => {
      branchOptions.push({
        ...branch,
        value: branch.uid,
        label: `${branch.branchId} - ` + branch.name
      });
    });
    return branchOptions;
  }
);
export const branchIdOptionsSelector = createSelector(
  //ไม่มี HQ OPTION
  [branchList],
  branchList => {
    let branchOptions = [];
    _.map(branchList, branch => {
      branchOptions.push({
        ...branch,
        value: branch.branchId,
        label: `${branch.branchId} - ` + branch.name
      });
    });
    return branchOptions;
  }
);

export const branchOptionsWithHqSelector = createSelector(
  //มี HQ OPTION
  [branchOptionsSelector, hqShop],
  (branchOptionsSelector, hqShop) => {
    let hqOption = {
      value: hqShop.uid,
      label: hqShop.name + ` (${hqShop.branchId})`
    };

    return [hqOption, ...branchOptionsSelector];
  }
);

export const branchObjSelector = createSelector(
  //มี HQ OPTION
  [branchList],
  branchList => {
    let branchObj = {};

    _.forEach(branchList, branch => {
      branchObj[branch.uid] = branch;
    });

    return branchObj;
  }
);
