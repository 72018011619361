import randomString from "random-string";
import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
import moment from "moment";
import async from "async";
import swal from 'sweetalert'
import * as type from '../types/inventoryGroups'
// import * as linkedSelecter from '../selector/linkedSelector'


export const fetchInventoryGroup = () => {
    return (dispatch, getState) => {

        const { user, shop } = getState()
        let shopId = user.loggedIn.shopId
        const link = shop.detail.link

        if (link !== undefined) {
            if (link.inventory !== undefined) {
                shopId = link.inventory
            }
        }

        let param = {
            TableName: "Minor_Inventory_Groups",
            KeyConditionExpression: "shopId = :shopId",
            ExpressionAttributeValues: {
                ":shopId": shopId
            }
        };
        fetch(param, data => {
            dispatch({
                type: type.FETCH_INVENTORY_GROUP,
                payload: (data && _.sortBy(data.Items, i => i.row)) || []
            });
        });
    };
};



const fetch = (params, cb) => {
    documentClient.query(params, (err, data) => {
        if (err) {
            console.log(err);
            // alert(err)
        } else {
            cb(data);
        }
    });
};

export function addInventoryGroups(items, pickedTypeUid, callBack) {
    return (dispatch, getState) => {
        const { user } = getState()

        async.eachSeries(
            items,
            function (name, cb) {
                let randomUID = randomString({
                    length: 8,
                    numeric: true,
                    letters: true,
                    special: false
                });
                let params = {
                    TableName: "Minor_Inventory_Groups",
                    Item: {
                        shopId: user.loggedIn.shopId,
                        uid: randomUID,
                        name: name,
                        row: moment().valueOf(),
                        type: pickedTypeUid
                    }
                };
                documentClient.put(params, function (err, data) {
                    if (err) {
                        swal("เกิดข้อผิดพลาด", err, "error");
                        return cb(err);
                    } else {
                        cb(null);
                    }
                });
            },
            function (err) {
                if (err) {
                    swal("เกิดข้อผิดพลาด", err, "error");
                } else {
                    dispatch(fetchInventoryGroup());
                    if (callBack !== undefined) {
                        callBack();
                    }
                }
            }
        );
    };
}

export const reorderInventoryGroups = (groups, callBack) => {
    return (dispatch, getState) => {
        const { user } = getState()

        let index = 1
        async.eachSeries(groups, (group, cb) => {
            let params = {
                TableName: "Minor_Inventory_Groups",
                Key: {
                    shopId: user.loggedIn.shopId,
                    uid: group.uid
                },
                UpdateExpression: "set #row = :newRow",
                ExpressionAttributeNames: { "#row": "row" },
                ExpressionAttributeValues: {
                    ":newRow": index
                }
            };
            documentClient.update(params, function (err, data) {
                if (err) {
                    alert(err)
                } else {
                    index = index++
                    cb()
                }
            });

        }, (err) => {
            if (err) {
                swal("เกิดข้อผิดพลาด", err, "error");
            } else {
                dispatch(
                    fetchInventoryGroup()
                );
                if (callBack !== undefined) {
                    callBack();
                }
            }
        })



    };
}

export const editGroupName = (groupName, group) => {
    return (dispatch, getState) => {
        const { user } = getState()

        let params = {
            TableName: "Minor_Inventory_Groups",
            Key: {
                shopId: user.loggedIn.shopId,
                uid: group.uid
            },
            UpdateExpression: "set #name = :newName",
            ExpressionAttributeNames: { "#name": "name" },
            ExpressionAttributeValues: {
                ":newName": groupName
            }
        };
        documentClient.update(params, function (err, data) {
            if (err) {
                alert(err)
            } else {
                swal("สำเร็จ", 'เปลี่ยนชื่อกลุ่มสำเร็จ', "success");
                dispatch(fetchInventoryGroup());
            }
        });
    };
}


export const deleteGroup = (group, cb) => {
    return (dispatch, getState) => {

        const { user } = getState()

        let params = {
            TableName: "Minor_Inventory_Groups",
            Key: {
                shopId: user.loggedIn.shopId,
                uid: group.uid
            },
        };
        documentClient.delete(params, function (err, data) {
            if (err) {
                alert(err)
            } else {
                cb && cb()
                swal("สำเร็จ", 'ลบกลุ่มสำเร็จ', "success");
                dispatch(fetchInventoryGroup());
            }
        });
    };
}

export const editGroupType = (group, cb) => {
    return (dispatch, getState) => {
        const { user } = getState()

        let params = {
            TableName: "Minor_Inventory_Groups",
            Key: {
                shopId: user.loggedIn.shopId,
                uid: group.uid
            },
            UpdateExpression: "set #type = :newType",
            ExpressionAttributeNames: { "#type": "type" },
            ExpressionAttributeValues: {
                ":newType": 'other'
            }
        };
        documentClient.update(params, function (err, data) {
            if (err) {
                cb && cb(err)
            } else {
                cb && cb()
                // swal("สำเร็จ", 'เปลี่ยนชื่อกลุ่มสำเร็จ', "success");
                // dispatch(fetchInventoryGroup());
            }
        });
    };
}

export const moveGroupToAnotherType = (group, typeUid, cb) => {
    return (dispatch, getState) => {
        let params = {
            TableName: "Minor_Inventory_Groups",
            Key: {
                shopId: group.shopId,
                uid: group.uid
            },
            ExpressionAttributeNames: { "#type": "type" },
            UpdateExpression: "set #type = :newType",
            ExpressionAttributeValues: {
                ":newType": typeUid
            }
        };
        documentClient.update(params, function (err, data) {
            if (err) {
                // alert(err)
                console.log(err);

            } else {
                cb()
                dispatch(fetchInventoryGroup());
            }
        });
    }
}
