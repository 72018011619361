import React, { Component } from "react";
import _ from "lodash";
import ProductSummaryTable from "../../shared/components/ProductSummaryTable";
import moment from "moment";
import { connect } from "react-redux";
import api from "../../shared/api";
import swal from "sweetalert";
import * as purchaseHistoryAction from "../../shared/actions/purchaseHistoryActions";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import ButtonInvoiceStatus from "../../shared/components/ButtonInvoiceStatus";
import * as isHqSelector from "../../shared/selector/isHqSelector";
import * as cartAction from "../../shared/actions/cartActions";
import * as cartHqActions from "../../shared/actions/cartHqActions";
import * as appStateAction from "../../shared/actions/appStateActions";
import { bindActionCreators } from "redux";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import getStatusName from "../../shared/functions/getStatusName";
import getStatusColor from "../../shared/functions/getStatusColor";
import * as linkedSelector from "../../shared/selector/linkedSelector";
import examplePaymentTypes from "../../shared/exampleData/examplePaymentTypeData";
import {
  exportInvoiceExcel,
  exportSendEmail,
  exportInvoicePdf
} from "../../shared/api/export";
import { downloadableLinkUrl } from "../../shared/utils/index";
import getStatusColorButton from "../../shared/functions/getStatusColorButton";

const topSpace = 20;
const styleBlock = {
  display: "flex",
  justifyContent: "space-between"
};

class PurchaseDetail extends Component {
  state = {
    currentTX: {},
    historyData: [],
    cantEdit: false,
    paymentType: {
      uid: "32kf03f",
      name: "Cash",
      value: "32kf03f",
      label: "Cash"
    }
  };
  componentDidMount() {
    this.fetchThisInvoice();
  }

  fetchThisInvoice = () => {
    const { match, isLinked, dispatch } = this.props;
    const { shopId, timestamp } = match.params;
    let params = {
      TableName: "Minor_Invoices",
      Key: {
        shopId: shopId,
        timestamp: timestamp
      }
    };

    let self = this;

    documentClient.get(params, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        // let historyData = getCombinedStatus(data.Item);
        let cantEdit = true;
        if (
          data.Item.status.toString() === "200" ||
          data.Item.status === "300"
        ) {
          cantEdit = false;
        }
        let payment = data.Item.payment[0];

        self.setState({
          currentTX: data.Item,
          historyData: data.Item.statusHistory,
          cantEdit,
          paymentType: {
            ...payment,
            value: payment.uid,
            label: payment.name
          }
        });

        dispatch(purchaseHistoryAction.markInvoiceAsRead(data.Item));
      }
    });
  };

  downloadInvoiceExcel = async paperType => {
    try {
      this.props.dispatch(appStateAction.loading(true));
      var res = await exportInvoiceExcel(this.state.currentTX, paperType);
      this.props.dispatch(appStateAction.loading(false));
      downloadableLinkUrl("Invoice_Excel.xlsx", res.data);
    } catch (error) {
      console.log(error);
    }
  };
  downloadInvoicePdf = async paperType => {
    try {
      this.props.dispatch(appStateAction.loading(true));

      var res = await exportInvoicePdf(this.state.currentTX, paperType);
      this.props.dispatch(appStateAction.loading(false));

      downloadableLinkUrl("Invoice_PDF.pdf", res.data);
    } catch (error) {
      console.log(error);
    }
  };

  sendEmail = async defaultEmailInput => {
    let params = {
      title: `Please enter email?`,
      text: "multiple email seperate by comma",
      content: {
        element: "input",
        attributes: {
          inputValue: defaultEmailInput || ""
        }
      },
      buttons: ["Cancel", "Submit"],
      buttons: true
    };

    swal(params).then(inputEmailList => {
      if (inputEmailList) {
        var invoice = this.state.currentTX;
        exportSendEmail(inputEmailList, invoice);
      }
    });

    setTimeout(() => {
      window.$("input.swal-content__input").val(defaultEmailInput);
    }, 10);
    swal(params).then(inputEmailList => {
      var text = inputEmailList || window.$("input.swal-content__input").val();
      if (text) {
        var invoice = this.state.currentTX;
        exportSendEmail(text, invoice);
        swal("Email has been sent.", "", "success");
      }
    });
  };

  onClickChangeStatus = status => {
    const { cbSuccess2, actions } = this.props;
    // actions.updateInvoiceStatus({ tx, status, cbSuccess, reason })
    var tx = this.state.currentTX;
    // var cbSuccess = this.fetchThisInvoice;
    let cancelParam = {
      title: `คุณต้องการยกเลิก Invoice เลขที่ \"${tx.invoiceId}\" ?`,
      text: "กรอกเหตุผล",
      icon: "warning",
      content: "input",
      buttons: ["Cancel", "Submit"],
      // buttons: true,
      dangerMode: true
    };
    let confirmParam = {
      title: `คุณต้องการยืนยัน Invoice เลขที่ \"${tx.invoiceId}\" ?`,
      text:
        "กรุณาตรวจสอบข้อมูลก่อนการทำการยืนยัน หากทำการยืนยันแล้วจะไม่สามารถแก้ไขสินค้าได้",
      icon: "warning",
      buttons: ["Cancel", "Confirm"]
    };

    var self = this;
    const cbSuccess = () => {
      self.fetchThisInvoice();
      swal("Process has completed", "", "success");
    };
    let param;
    if (status === "600") {
      param = confirmParam;
      swal(param).then(reason => {
        if (reason) {
          actions.updateInvoiceStatus({ tx, status, cbSuccess, cbSuccess2 });
        }
      });
      return;
    }
    if (status === "900") {
      param = cancelParam;
      swal(param).then(reason => {
        if (reason) {
          actions.updateInvoiceStatus({
            tx,
            status,
            cbSuccess,
            reason,
            cbSuccess2
          });
        }
      });
    }

    // else if (status === '400') {
    //     param = statusParam
    // }
  };

  getStatusAction = tx => {
    if (tx.status === "900") {
      return;
    }
    const { isInventoryHq } = this.props;

    if (isInventoryHq) {
      return (
        <ButtonInvoiceStatus
          tx={tx}
          topSpace={-3}
          cbSuccess2={this.fetchThisInvoice}
        />
      );
    }
  };

  isShowVoidButton = currentTX => {
    const { isLinked } = this.props;
    const { cantEdit } = this.state;
    let isShow = false;
    if (isLinked) {
      if (currentTX.status !== "900" && !cantEdit) {
        isShow = true;
      }
    } else {
      if (currentTX.status !== "900") {
        isShow = true;
      }
    }

    return isShow;
  };

  updatePaymentType = paymentType => {
    const { currentTX } = this.state;

    var params = {
      TableName: "Minor_Invoices",
      Key: { shopId: currentTX.shopId, timestamp: currentTX.timestamp },
      UpdateExpression: "set payment = :payment",
      // ExpressionAttributeNames: { "#a": "Sum" },
      ExpressionAttributeValues: {
        ":payment": [{ ...currentTX.payment[0], ...paymentType }]
      }
    };

    let self = this;

    documentClient.update(params, (err, res) => {
      if (err) {
        return console.log(err);
      }

      self.setState({ paymentType: paymentType });
      this.fetchThisInvoice();
    });
  };

  onClickUpdateOwnerApprove = async () => {
    const { actions } = this.props;
    let confirm = await swal({
      title: `ยืนยันการอนุมัติงาน ?`,
      // text: customerNames,
      icon: "warning",
      buttons: true
    });

    if (!confirm) return;

    let self = this;
    var tx = this.state.currentTX;

    const cbSuccess = () => {
      self.fetchThisInvoice();
      swal("Process has completed", "", "success");
    };

    actions.updateOwnerApprove({ tx, cbSuccess });
  };

  render() {
    let { currentTX, historyData, cantEdit, paymentType } = this.state;
    // let { history, actions, isLinked, userOwnerApproved } = this.props;

    if (_.isEmpty(currentTX)) {
      return <h3>Loading...</h3>;
    }

    return [
      <ActionControlBar
        {...this.props}
        downloadInvoicePdf={this.downloadInvoicePdf}
        sendEmail={this.sendEmail}
        downloadExcel={this.downloadInvoiceExcel}
        invoice={currentTX}
        onClickChangeStatus={this.onClickChangeStatus}
        onClickUpdateOwnerApprove={this.onClickUpdateOwnerApprove}
      />,

      <div class="row mt-8">
        <div class="col-md-8">
          <CustomCard>
            <div
              class="mt-5 mb-5 widget-counter-group widget-counter-group-center"
              style={styleBlock}
            >
              <TitleBlock value={currentTX.branchId} name="รหัสสาขา" />
              <TitleBlock value={currentTX.shopName} name="สาขา" />
              <TitleBlock value={currentTX.invoiceId} name="เลขที่บิล" />
            </div>

            {currentTX.remark && (
              <p>
                {" "}
                <mark>หมายเหตุ : {currentTX.remark}</mark>
              </p>
            )}
            <ProductSummaryTable products={currentTX.items} />
          </CustomCard>
        </div>

        <div class="col-md-4">
          <CustomCard>
            <div class="card-header card-header-divider">รายละเอียด</div>
            <div
              class="widget-counter-group widget-counter-group-center"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <TitleBlock
                value={currentTX.grandTotal.toLocaleString() + " บาท"}
                name="GRANDTOTAL"
                isColumn={true}
              />
              <TitleBlock
                value={
                  <Select
                    value={paymentType}
                    onChange={data => {
                      this.updatePaymentType(data);
                    }}
                    options={examplePaymentTypes}
                    isDisabled={parseInt(currentTX.status) >= 600}
                  />
                }
                name="การจ่ายเงิน"
                isColumn={true}
              />
              <TitleBlock
                value={`${currentTX.qtyAll} รายการ`}
                name="จำนวนสินค้า"
                isColumn={true}
              />
            </div>

            <div class="card-header card-header-divider mb-5 mt-5">
              Timeline
            </div>
            <Timeline list={historyData} />
          </CustomCard>
        </div>
      </div>
    ];
  }
}
const mapStateToProps = state => {
  var defaultEmailInput = isHqSelector.isInventoryHq(state)
    ? state.shop.detail && state.shop.detail.emailReport
    : "";

  return {
    isInventoryHq: isHqSelector.isInventoryHq(state),
    isLinked: linkedSelector.isLinkedSelector(state),
    defaultEmailInput: defaultEmailInput || "",
    userOwnerApproved: state.user.loggedIn.ownerApproved || false
  };
};
function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators(
    { ...cartAction, ...cartHqActions, ...purchaseHistoryAction },
    dispatch
  );
  return {
    actions,
    dispatch
  };
}

const CustomCard = ({ title, children }) => {
  return (
    <div className="card card-border-color card-border-color-primary">
      {title && <div className="card-header card-header-divider">{title}</div>}
      <div className="card-body widget-chart-container">{children}</div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseDetail);

function TitleBlock({ value, name, isColumn }) {
  return (
    <div
      // class="counter counter-big"
      style={isColumn ? { marginTop: 30, marginLeft: 0 } : {}}
    >
      {/* <div class="value">{value}</div>
      <div class="desc">{name}</div> */}
      <div style={{ fontSize: "2.077rem" }}>{value}</div>
      <div style={{ fontSize: ".8462rem", color: "grey" }}>{name}</div>
      {/* 2.077rem
      .8462rem */}
    </div>
  );
}

function Timeline({ list }) {
  return (
    <ul class="user-timeline">
      {_.map(_.reverse(list), (s, i) => {
        return (
          <li class={i == 0 ? "latest" : ""}>
            <div class="user-timeline-date">
              {moment(s.timestamp).format("DD/MMM/YYYY hh:mm")}
            </div>
            <div class="user-timeline-title">By: {s.by.name}</div>
            <div class="user-timeline-description">
              {/* <span>{getStatusName(s.status).toString()}</span> */}
              {getStatusColorButton(s.status.toString())}
              <span style={{ marginLeft: 8 }}>{s.reason}</span>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

function ActionControlBar(props) {
  const { userOwnerApproved, invoice } = props;
  const { status } = props.invoice;
  const currentTX = invoice;
  return (
    <div
      class="d-flex flex-row justify-content-between align-items-stretch"
      style={{
        backgroundColor: "white",
        margin: -25,
        height: 48,
        width: "calc(100% - 230px)",
        zIndex: 99,
        position: "fixed",
        borderBottom: "1px solid rgb(212, 212, 212)"
      }}
    >
      <div class="mt-2 " style={{ backgroundColor: " ", paddingLeft: 25 }}>
        <button
          style={{ widht: 80 }}
          onClick={() => props.history.push("/purchaseHistory")}
          class="btn btn-rounded btn-space btn-secondary"
        >
          <i class="icon icon-left mdi mdi-arrow-left" />
          Back
        </button>
        <label style={{ marginLeft: 12, marginTop: 8, marginRight: 8 }}>
          Current Status :{" "}
          <strong style={{ color: getStatusColor(status) }}>
            {getStatusName(status)}
          </strong>{" "}
        </label>
      </div>
      <div
        class=" mt-2 d-flex  justify-content-end"
        style={{ backgroundColor: " ", marginRight: 25 }}
      >
        <label style={{ marginLeft: 24, marginTop: 8, marginRight: 8 }}>
          Export
        </label>
        {/* ACTION BUTTONS */}
        <ActionButton
          label="PDF"
          buttons={[
            {
              label: "ใบสั่งซื้อ",
              onClick: () => props.downloadInvoicePdf("order")
            },
            {
              label: "ใบแจ้งหนี้/ใบวางบิล",
              onClick: () => props.downloadInvoicePdf("invoice")
            },
            {
              label: "ใบส่งของ",
              onClick: () => props.downloadInvoicePdf("transport")
            },
            {
              label: "ใบเสร็จ",
              onClick: () => props.downloadInvoicePdf("receipt")
            }
          ]}
        />
        <ActionButton
          label="Excel"
          buttons={[
            {
              label: "ใบสั่งซื้อ",
              onClick: () => props.downloadExcel("order")
            },
            {
              label: "ใบแจ้งหนี้/ใบวางบิล",
              onClick: () => props.downloadExcel("invoice")
            },
            {
              label: "ใบส่งของ",
              onClick: () => props.downloadExcel("transport")
            },
            {
              label: "ใบเสร็จ",
              onClick: () => props.downloadExcel("receipt")
            }
          ]}
        />
        {/* ACTION BUTTONS */}

        {/* <button
          onClick={() => props.downloadExcel()}
          class="btn btn-rounded btn-space btn-secondary"
        >
          <i class="icon icon-left mdi mdi-border-all" /> Excel
        </button> */}
        {/* <button
          onClick={() => props.downloadInvoicePdf()}
          class="btn btn-rounded btn-space btn-secondary"
        >
          <i class="icon icon-left mdi mdi-collection-pdf" /> PDF
        </button> */}
        <button
          onClick={() => props.sendEmail(props.defaultEmailInput)}
          class="btn btn-space btn-secondary"
        >
          <i class="icon icon-left mdi mdi-mail-send" />
          Send Email
        </button>

        {/* {parseInt(status) < 900 && (
          <label style={{ marginLeft: 24, marginTop: 8, marginRight: 8 }}>
            Status
          </label>
        )} */}
        <label style={{ marginLeft: 24, marginTop: 8, marginRight: 8 }}>
          Actions
        </label>
        {parseInt(status) < 400 && (
          <button
            onClick={() => {
              props.actions.initEditInvoiceItem(currentTX);
              if (!props.isLinked) {
                //HQ set branch ด้วย
                props.actions.setDestinationBranch({
                  value: currentTX.shopId,
                  label: currentTX.shopName
                });
              }
              props.history.push(
                `/shopping/edit/${currentTX.invoiceId}/${currentTX.shopId}/${currentTX.timestamp}`
              );
            }}
            class="btn btn-rounded btn-space btn-secondary"
          >
            <i class="icon icon-left mdi mdi-edit " />
            Edit{" "}
          </button>
        )}
        <ReactTooltip
          id="approveGrantDenie"
          type="dark"
          disable={userOwnerApproved}
          place="bottom"
        >
          คุณยังไม่ได้รับสิทธิในการอนุมัติ
        </ReactTooltip>
        {currentTX.ownerApproved === false && (
          <span
          data-tip data-for="approveGrantDenie"
          >
            <button
              style={{ width: 180 }}
              onClick={() => props.onClickUpdateOwnerApprove()}
              disabled={userOwnerApproved !== true}
              class="btn btn-rounded btn-space btn-success"
            >
              <i class="icon icon-left mdi mdi-check-circle" />
              Approve
            </button>
          </span>
        )}
        {props.isLinked === false && parseInt(status) < 600 && (
          <button
            style={{ width: 180 }}
            onClick={() => props.onClickChangeStatus("600")}
            class="btn btn-rounded btn-space btn-primary"
          >
            <i class="icon icon-left mdi mdi-check-circle" />
            Confirm
          </button>
        )}
        {parseInt(status) < 900 && (
          <button
            onClick={() => props.onClickChangeStatus("900")}
            class="btn btn-rounded btn-space btn-secondary"
          >
            <i class="icon icon-left mdi mdi-close-circle" />
            Void{" "}
          </button>
        )}
      </div>
    </div>
  );
}

const ActionButton = ({ label, buttons = [] }) => {
  return (
    <div class="btn-group btn-rounded btn-space">
      <button
        class="btn btn-secondary dropdown-toggle"
        type="button"
        data-toggle="dropdown"
      >
        {label} <span class="icon-dropdown mdi mdi-chevron-down"></span>
      </button>
      <div class="dropdown-menu" role="menu">
        {_.map(buttons, button => {
          return (
            <a
              class="dropdown-item"
              href="#"
              onClick={e => {
                e.preventDefault();
                button.onClick && button.onClick();
              }}
            >
              {button.label}
            </a>
          );
        })}
        {/* <div class="dropdown-divider"></div> */}
      </div>
    </div>
  );
};
