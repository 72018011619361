import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import Select from "react-select";
import documentClient from "../../shared/awsAPIs/dynamoDb";

const BalanceSummary = props => {
  const { history, match } = props;
  const [selectedBranch, setSelectedBranch] = useState({
    label: "All",
    value: "all"
  });
  const [branchBalance, setBranchBalance] = useState({});
  const { branchOptions, shopList, shopDetail } = props;

  const fetchBalanceSummary = () => {
    var params = {
      TableName: "Minor_Wallet_Summary",
      KeyConditionExpression: "hqId = :hqId",
      IndexName: "hqId-index",
      ExpressionAttributeValues: {
        ":hqId": shopDetail.uid
      }
    };
    documentClient.query(params, function(err, data) {
      if (err) console.log(err);
      else {
        let balanceHolder = {};
        _.map(data.Items, item => {
          balanceHolder[item.shopId] = item;
        });
        setBranchBalance(balanceHolder);
      }
    });
  };

  useEffect(() => {
    fetchBalanceSummary();
  }, []);

  return (
    <div>
      <div className="row m-0">
        <div
          className="col-md-4"
          style={{
            marginTop: 10,
            marginBottom: 10,
            marginLeft: 0,
            paddingleft: 0
          }}
        >
          <Select
            style={{ marginLeft: 0 }}
            value={selectedBranch}
            onChange={setSelectedBranch}
            options={branchOptions}
          />
        </div>
      </div>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Store</th>
            <th>Balance</th>
            <th>Last Update</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {_.map(shopList, (shop, index) => {
            let data = branchBalance[shop.uid] || {};
            return (
              <tr
                // onClick={() => viewTX(tx)}
                key={`t${index}`}
              >
                {/* <td /> */}
                <td className="cell-detail">
                  {" "}
                  <span>{shop.name}</span>
                  <span className="cell-detail-description">
                    {shop.branchId}
                  </span>
                </td>
                <td className="cell-detail">
                  <span>{(data.balance || 0).toLocaleString()}</span>
                  {/* <span>{data.balance}</span> */}
                </td>
                <td className="cell-detail">
                  {data.lastUpdate && (
                    <span>
                      {moment(data.lastUpdate).format("MMM D, YYYY HH:mm")}
                    </span>
                  )}
                </td>
                <td className="cell-detail">
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      history.push(
                        `${match.url}/branchWalletTxHistory/${shop.uid}`
                      );
                    }}
                  >
                    View
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BalanceSummary;
