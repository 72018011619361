import firebase from 'firebase';
import 'firebase/firestore';

var config = {
    apiKey: "AIzaSyApj9Qjo1uqVpmI-_0PUTexqF-18MhUmOQ",
    authDomain: "minor-hackathon.firebaseapp.com",
    databaseURL: "https://minor-hackathon.firebaseio.com",
    projectId: "minor-hackathon",
    storageBucket: "minor-hackathon.appspot.com",
    messagingSenderId: "614997360901"
};
firebase.initializeApp(config);

export const rootRef = firebase.database().ref();
export const notificationRef = rootRef.child('inbox_lastupdate')