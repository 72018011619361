const examplePaymentTypes = [
  {
    uid: "32kf03f",
    name: "Cash",
    value: "32kf03f",
    label: "Cash"
  },
  {
    uid: "onAccount",
    name: "On Account",
    value: "onAccount",
    label: "On Account"
  }
];

export default examplePaymentTypes;
