import React, { useState } from "react";
import _ from 'lodash'
function Pending() {
  const [count, setCount] = useState(0);
  return (
    <div>
      <h4>Zone</h4>

      <Table/>
    </div>
  );
}

export default Pending;

function Table({  }) {
  return (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th />
          <th>Zone</th>
          <th>Rooms</th>
          <th>Guest</th>
          <th>Start-End</th>
          <th>Sales</th>
          <th>GrandTotal</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {_.map(['transactions','asd',2], (tx, index) => {
          return (
            <tr>
              <td />

              <td className="cell-detail">
                <span>A</span>
              </td>
              <td className="cell-detail">
                <span>1001</span>
              </td>

              <td className="cell-detail">
                <span>Warit Tantivirasut</span>
              </td>

              <td className="cell-detail">
                <span>16:40</span>
                <span className="cell-detail-description">16:40</span>
              </td>

              <td className="cell-detail">
                <span>Employee A</span>
              </td>

              <td className="cell-detail">
                <span>10,000</span>
                <span className="cell-detail-description">12314BC</span>

              </td>

              <td className="cell-detail">
                <span>Pending</span>
              </td>

              <td className="cell-detail">
                <span>Add</span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
