import * as type from '../types/appState'


export const loading = (isLoading) => {
    return  {
            type: type.APP_STATE_LOADING,
            payload: isLoading
        }
    
};
