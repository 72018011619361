import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
import moment from "moment";
import swal from "sweetalert";
import * as type from "../types/employee";
import randomString from "random-string";

export const fetchEmployee = cbSuccess => {
  return (dispatch, getState) => {
    const { user, employee } = getState();
    let shopId = user.loggedIn.shopId;

    let params = {
      IndexName: "shopId-index",
      TableName: "Employees",
      KeyConditionExpression: "shopId = :shopId",
      ExpressionAttributeValues: {
        ":shopId": shopId
      }
    };
    fetch(params, data => {
      dispatch({
        type: type.FETCH_EMPLOYEE,
        payload: _.orderBy(data.Items, ["name"], ["asc"])
      });
      if (employee.current) {
        //ALSO SET NEW CRRENT DATA
        dispatch({
          type: type.SET_LOGGED_IN_EMPLOYEE,
          payload: _.find(data.Items, i => i.uid === employee.current.uid)
        });
      }
      cbSuccess && cbSuccess();
    });
  };
};

const fetch = (params, cb) => {
  documentClient.query(params, (err, data) => {
    if (err) {
      console.log(err);
      // alert(err)
    } else {
      cb(data);
    }
  });
};

export const setLoggedInEmployee = employee => {
  return (dispatch, getState) => {
    dispatch({
      type: type.SET_LOGGED_IN_EMPLOYEE,
      payload: employee
    });
  };
};

export const createEmployee = (emp, cb) => {
  return (dispatch, getState) => {
    const { user } = getState();
    let randomUID = randomString({
      length: 8,
      numeric: true,
      letters: true,
      special: false
    });
    let params = {
      TableName: "Employees",
      Item: {
        shopId: user.loggedIn.shopId,
        uid: randomUID,
        name: emp.name,
        pin: emp.pin,
        permission: emp.permission
      }
    };
    documentClient.put(params, function(err, data) {
      if (err) {
        swal("เกิดข้อผิดพลาด", err, "error");
      } else {
        cb();
        dispatch(fetchEmployee());
      }
    });
  };
};

export const editEmployee = (emp, cb) => {
  return (dispatch, getState) => {
    const { user } = getState();

    let params = {
      TableName: "Employees",
      Key: {
        shopId: emp.shopId,
        uid: emp.uid
      },
      UpdateExpression:
        "set #name = :name, pin = :pin, #permission= :permission",
      ExpressionAttributeNames: {
        "#name": "name",
        "#permission": "permission"
      },
      ExpressionAttributeValues: {
        ":name": emp.name,
        ":pin": emp.pin,
        ":permission": emp.permission
      }
    };
    documentClient.update(params, function(err, data) {
      if (err) {
        // alert(err)
        console.log(err);
      } else {
        cb();
        dispatch(fetchEmployee());
      }
    });
  };
};

export const deleteEmployee = (emp, cb) => {
  return (dispatch, getState) => {
    const { employee } = getState();

    let params = {
      TableName: "Employees",
      Key: {
        shopId: emp.shopId,
        uid: emp.uid
      }
    };
    documentClient.delete(params, function(err, data) {
      if (err) {
        // alert(err)
        console.log(err);
      } else {
        cb();
        if (emp.uid === employee.current.uid) {
          dispatch({
            type: type.SET_LOGGED_IN_EMPLOYEE,
            payload: null
          });
        }
        dispatch(fetchEmployee());
      }
    });
  };
};
// dispatch({
//   type: type.SET_LOGGED_IN_EMPLOYEE,
//   payload: _.find(data.Items, i => i.uid === employee.current.uid)
// });
