import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as employeeActions from '../actions/employeeActions'
const EmployeeLockBtn = props => {
  return (
    <button
      class="btn btn-space btn-primary"
      onClick={e => props.actions.setLoggedInEmployee(null)}
    >
      <i class="icon icon-left mdi mdi-lock-outline" />
      {props.currentEmp.name || ""}
    </button>
  );
};

const mapStateToProps = state => {
  return {
    currentEmp: state.employee.current || {}
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...employeeActions }, dispatch);
  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeLockBtn);
