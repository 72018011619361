import React from 'react'
import ReactDataSheet from 'react-datasheet';
import { connect } from 'react-redux'
import _ from 'lodash'
import swal from 'sweetalert'
import documentClient from '../../shared/awsAPIs/dynamoDb'
import async from 'async'
import Select from 'react-select';


class ManageStockExcel extends React.Component {
    state = {
        grid: [],
        oldData: [],
        groupOptions: [],
        selectedGroup: { value: '', label: 'เลือกกลุ่ม' }

    }

    componentDidMount = () => {
        this.fetchInventoryStock('')
        this.createGroupOptions()

    }

    createGroupOptions = () => {
        const { groups } = this.props
        let groupOptions = [{ value: '', label: 'เลือกกลุ่ม' }]

        _.forEach(groups, group => {
            groupOptions.push({ value: group.uid, label: group.name })
        })
        this.setState({ groupOptions })
    }


    selectGroup = (selectedGroup) => {
        let value = selectedGroup.value
        if (this.warningBeforeChangeGroup() === false) {
            swal({
                title: `แน่ใจแล้วหรือ ?`,
                text: `คุณเพิ่งทำการเปลี่ยนแปลงข้อมูล หากคุณเปลี่ยนกลุ่มโดยที่ไม่ได้ทำการบันทึก คุณจะต้องกรอกข้อมูลใหม่`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.fetchInventoryStock(value)
                        this.setState({ selectedGroup })
                    }
                });
        } else {
            this.fetchInventoryStock(value)
            this.setState({ selectedGroup })
        }

    }
    warningBeforeChangeGroup = () => {
        const { grid, oldData } = this.state

        let isWarning = _.isEqual(grid, oldData)

        return isWarning
    }

    fetchInventoryStock = (chosenGroupUid) => {
        const { user } = this.props

        let params = {
            TableName: "Minor_Stock",
            IndexName: 'shopId-index',
            KeyConditionExpression: "shopId = :shopId",
            ExpressionAttributeValues: {
                ":shopId": user.loggedIn.shopId
            }
        };

        documentClient.query(params, (err, data) => {
            if (err) {
                alert(err)
            } else {
                let stockObj = {}
                _.forEach(data.Items, item => {
                    stockObj[item.uid] = item
                })
                this.createRows(chosenGroupUid, stockObj)
            }
        });


    }

    createRows = (chosenGroupUid, stock) => {

        const { inventoryItems } = this.props

        let rowButton = <span></span>
        let grid = [
            [{ readOnly: true, value: rowButton, width: '7%' },
            { readOnly: true, value: 'Name', cantAdd: true },
            { readOnly: true, value: 'Price', width: '15%' },
            { readOnly: true, value: 'Unit', width: '15%' },
            { readOnly: true, value: 'On Hand' },
            { readOnly: true, value: 'Action' },
            ]
        ]



        let filteredItems = []

        if (chosenGroupUid === '') {
            filteredItems = []
        } else {
            filteredItems = _.filter(inventoryItems, i => i.groupUid === chosenGroupUid)
        }

        _.forEach(filteredItems, (item, index) => {
            grid.push([{ readOnly: true, value: index + 1, uid: item.uid },
            { readOnly: true, value: item.name, },
            { readOnly: true, value: item.price },
            { readOnly: true, value: item.unit },
            { value: (stock[item.uid] && stock[item.uid].onHand.toString()) || '' },
            {
                readOnly: true, value: stock[item.uid] ? <a href="#" onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    this.deleteInventoryStock(item)
                }}>ไม่ตัดสต๊อค</a> : ''
            },

            ])
        })

        this.setState({ grid, oldData: grid })
    }

    submit = () => {
        const { grid } = this.state
        let cuttedHead = grid.slice(1)
        let noneEmptyItems = []
        _.forEach(cuttedHead, item => {
            if (item[4].value !== '') {
                noneEmptyItems.push(item)
            }
        })

        if (noneEmptyItems.length < 1) {
            swal("เกิดข้อผิดพลาด", 'กรุณากรอกข้อมูลอย่างน้อย 1 Item', "error");
            return
        }

        this.updateOnHand(noneEmptyItems)


    }

    updateOnHand = (items) => {
        const { user } = this.props
        const { selectedGroup } = this.state
        let groupUid = selectedGroup.value
        async.each(items, (item, cb) => {

            let params = {
                TableName: "Minor_Stock",
                Key: {
                    shopId: user.loggedIn.shopId,
                    uid: item[0].uid
                },
                UpdateExpression: "set onHand = :newOnHand",
                // ExpressionAttributeNames: { "#row": "row" },
                ExpressionAttributeValues: {
                    ":newOnHand": parseInt(item[4].value)
                }
            };

            documentClient.update(params, function (err, data) {
                if (err) {
                    cb(err)
                } else {
                    cb()
                }
            });

        }, (err) => {
            if (err) {
                return alert(err)
            }
            this.fetchInventoryStock(groupUid)
            swal("ทำรายการสำเร็จ", 'บันทึกข้อมูล Stock สำเร็จ', "success");

        })
    }

    deleteInventoryStock = (item) => {
        const { selectedGroup } = this.state
        const { loggedIn } = this.props
        let groupUid = selectedGroup.value
        let params = {
            TableName: "Minor_Stock",
            Key: {
                shopId: loggedIn.shopId,
                uid: item.uid
            },
        };
        let self = this

        swal({
            title: `ยกเลิกการตัดสต๊อค "${item.name}" ?`,
            text: `ยอดคงเหลือในคลังของ "${item.name}" จะถูกลบทั้งหมดและผู้สั่งสินค้าจะสามารถสั่งสินค้าได้เลยโดยระบบจะไม่ทำการเช็คสต๊อค`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    documentClient.delete(params, function (err, data) {
                        if (err) {
                            // alert(err)
                            console.log(err);
                        } else {
                            self.fetchInventoryStock(groupUid)
                        }
                    });
                }
            });



    }

    render() {
        const { selectedGroup, groupOptions } = this.state
        return (
            <div >
                <div className="card card-contrast" >
                    <div className="card-header card-header-contrast card-header-featured">
                        <div className="row justify-content-between" style={{ marginLeft: 15, marginRight: 15 }}>
                            <div><div style={{ marginTop: 16 }}>จัดการสินค้าคงเหลือ</div></div>
                            <div className="col-md-5">
                                <div className="form-group row pt-1">
                                    <label style={{ marginTop: 3 }} className="col-md-3 col-form-label text-sm-right">Group: </label>
                                    <div className="col-md-9">
                                        {/* <select className="form-control" onChange={this.selectGroup} value={groupUid}>
                                            <option value={'all'}>All</option>
                                            {_.map(groups, group => {
                                                return <option value={group.uid} >{group.name}</option>
                                            })}
                                        </select> */}
                                        <Select
                                            value={selectedGroup}
                                            onChange={this.selectGroup}
                                            options={groupOptions}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                {
                                    selectedGroup.value !== '' &&
                                    <button style={{ marginTop: 16 }}
                                        onClick={this.submit}
                                        className="btn btn-space btn-success hover">
                                        <i className="icon icon-left mdi mdi-collection-plus"></i>SAVE</button>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div >
                            {
                                selectedGroup.value !== '' &&
                                <ReactDataSheet
                                    // width="100%"
                                    data={this.state.grid}
                                    valueRenderer={(cell) => cell.value}
                                    onCellsChanged={changes => {
                                        const grid = this.state.grid.map(row => [...row])
                                        changes.forEach(({ cell, row, col, value }) => {
                                            let numberVal = parseInt(value) //รับ int เท่านั้น
                                            if (_.isNaN(numberVal)) {
                                                return
                                            }
                                            grid[row][col] = { ...grid[row][col], value: numberVal }
                                        })
                                        this.setState({ grid })
                                    }}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let sortedInventory = _.orderBy(state.inventory.items, ['groupUid', 'row'], ['asc', 'asc'])
    return {
        loggedIn: state.user.loggedIn,
        inventoryItems: sortedInventory,
        groups: state.inventory.groups,
        user: state.user

    }
}

// function mapDispatchToProps(dispatch) {
//     let actions = bindActionCreators({ ...userActions }, dispatch)
//     return {
//         actions,
//     }
// }

export default connect(mapStateToProps)(ManageStockExcel)