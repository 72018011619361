import React, { Component } from "react";
import LeftAsideContainer from "../../shared/components/LeftAsideContainer";
import { connect } from "react-redux";
import * as linkedSelector from "../../shared/selector/linkedSelector";
import examplePaymentTypes from "../../shared/exampleData/examplePaymentTypeData";
import Select from "react-select";

class ProductSummaryAside extends Component {
  state = {
    remark: "",
    paymentType: {
      uid: "32kf03f",
      name: "Cash",
      value: "32kf03f",
      label: "Cash"
    }
  };

  render() {
    const {
      totalQty,
      totalPrice,
      onClickPurchase,
      onClickEditInvoice,
      isEdit,
      isLinked,
      cartHqInfo,
      loading,
      handleClickBackStep
    } = this.props;
    const { paymentType } = this.state;

    return (
      <div class="card">
        <div class="card-header card-header-divider">
          <button
            class="btn btn-rounded btn-space btn-secondary"
            onClick={handleClickBackStep}
          >
            ย้อนกลับแก้ไข
          </button>
        </div>
        {!isLinked && (
          <div class="card-header card-header-divider">
            <span style={{ fontSize: 20, fontWeight: "normal", color: "blue" }}>
              {(cartHqInfo.destination && cartHqInfo.destination.label) || ""}
            </span>
          </div>
        )}
        <div class={`card-header card-header${!isLinked ? "" : "-divider"}`}>
          <span style={{ fontSize: 20, fontWeight: "normal" }}>
            ยอดรวมสุทธิ
          </span>
        </div>
        <div class="card-body">
          <table className="table table-sm table-hover table-bordered">
            <tr>
              <td>จำนวนสินค้า</td>
              <td>{totalQty} ชิ้น</td>
            </tr>
            <tr>
              <td>ยอดรวมทั้งหมด</td>
              <td>{totalPrice.toLocaleString()} บาท</td>
            </tr>
          </table>
          {!isEdit && (
            <div class="form-group pt-2">
              <label for="">รูปแบบการชำระเงิน</label>
              <Select
                value={paymentType}
                onChange={data => {
                  this.setState({ paymentType: data });
                }}
                options={examplePaymentTypes}
                //   isDisabled={parseInt(currentTX.status) >= 600}
              />
            </div>
          )}

          {!isEdit && (
            <div class="form-group pt-2">
              <label for="">หมายเหตุ</label>
              <textarea
                value={this.state.remark}
                class="form-control"
                onChange={e => this.setState({ remark: e.target.value })}
              ></textarea>
            </div>
          )}

          {isEdit ? (
            <button
              onClick={() => onClickEditInvoice(paymentType)}
              style={{ width: "100%", marginTop: 10 }}
              class="btn btn-rounded btn-space btn-warning"
              disabled={loading}
            >
              {loading ? "Loading..." : "แก้ไข"}
            </button>
          ) : (
            <button
              onClick={() => onClickPurchase(this.state.remark, paymentType)}
              style={{ width: "100%", marginTop: 10 }}
              class="btn btn-rounded btn-space btn-primary"
              disabled={loading}
            >
              {loading ? "Loading..." : "สั่งซื้อสินค้า"}
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cartHqInfo: state.cart.cartHqInfo,
    isLinked: linkedSelector.isLinkedSelector(state)
  };
};
function mapDispatchToProps(dispatch) {
  // let actions = bindActionCreators({ ...cartAction }, dispatch)
  return {
    // actions,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSummaryAside);
