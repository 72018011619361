const getTxTypeColor = status => {
  let toUpperCase = status.toLowerCase();
  switch (toUpperCase) {
    case "topup":
      return "green";
    case "void":
      return "green";
    case "order":
      return "red";
    case "spend":
      return "red";
    default:
      return "black";
  }
};

export default getTxTypeColor;
