import React, { useState } from "react";
import { connect } from "react-redux";
import _ from 'lodash'
import { promotionItems } from "./selector";
function PosCartShoppingCartPromotionList(props) {
  const [count, setCount] = useState(0);
  const { qtyHolder , items, promotionItems } = props
  return (
    <div class="row">
      <div class="col-lg-12">
        {items.length >=1 && <table class="table table-sm table-striped">
          <thead>
            <tr>
            <th style={{ width: '5%' }}>Qty</th>

<th style={{width : '55%'}}>Promotion Discount</th>
<th class="number" style={{width : '15%'}}>Price</th>
<th class="number" style={{width : '15%'}}>Total</th>

<th class="actions" style={{width : '10%'}} />
            </tr>
          </thead>
          <tbody>
            {_.map(items, (line) => {
               return <tr>
                <td class=" " style={{width : 48}}><b>{line.qty}</b></td>

                <td>{line.name}</td>
                <td class="number">{line.price}</td>
                <td class="number">{line.qty * line.price}</td>

                <td class="actions">
                  <a class="icon" href="#">
                    <i class="mdi mdi-settings" />
                  </a>
                </td>
              </tr>;
            })}
          </tbody>
        </table>}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    items : promotionItems(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(PosCartShoppingCartPromotionList);
