import React from "react";
import moment from "moment";
import _ from "lodash";
class TopUpRequest extends React.Component {
  render() {
    return (
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th />
            <th style={{ width: "20%" }}>Time</th>
            <th>Store</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {_.times(5, index => {
            return (
              <tr
                // onClick={() => viewTX(tx)}
                key={`t${index}`}
              >
                <td />
                <td className="cell-detail">
                  <span>{moment().format("MMM D, YYYY HH:mm")}</span>
                </td>
                {/* Store Info */}
                <td className="cell-detail">
                  {" "}
                  <span>Niceloop Lardprow</span>
                  <span className="cell-detail-description">001</span>
                </td>
                {/* Items Info */}
                <td className="cell-detail">
                  {" "}
                  <span>{(5000).toLocaleString()} บาท</span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default TopUpRequest;
