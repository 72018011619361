import React, { useState,useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import * as posCartActions from "./actions";
import _ from "lodash";
const css = {
  height: 32
};
const cssPassingTop = {
  paddingTop: 12
};

const salesList = [
  { name: "Mr. sales-X", uid: "b0", row: 0 },
  { name: "Mr. sales-Y", uid: "b1", row: 0 },
  { name: "Mr. sales-Z", uid: "b2", row: 0 }
];

const memberList = [
  { name: "Mr. member-A", uid: "a0", row: 0 },
  { name: "Mr. member-B", uid: "a1", row: 0 },
  { name: "Mr. member-C", uid: "a2", row: 0 }
];

function ShoppingCartContainer(props) {
  const [scanRef, setscanRef] = useState(React.createRef());
  const [item, setItem] = useState(null);
  const [qty, setQty] = useState(1);
  const [member, setMember] = useState(null);
  const [sales, setSales] = useState(null);
  const [barcodeInput , setBarcodeInput] = useState('')
  function confimScan(evt){
    var text = evt.target.value;
    const { dispatch } = props;

    var res = _.find(props.menu_items_original, (itemLine) =>    itemLine.barcode == text );
    if(res){
       dispatch(posCartActions.addItem(res, 1));
       setBarcodeInput('')

    }else{
      setBarcodeInput(text)
    }
  }
  function addItem(selectedOption) {
    console.log(`Option selected:`, selectedOption);

    const { dispatch } = props;

    dispatch(posCartActions.addItem(selectedOption.value, qty));
    setQty(1);
  }

  function setOptionSelected(setKey, value) {}
  useEffect(() => {
    scanRef.current.focus();
     
  }, [])
  const { setView, view, qtyCounts } = props;
  return (
    <div class="card card-flat">
      <div
        class="row pb-4"
        style={{ padding: "0px 20px", borderBottom: "2px solid #ebebeb" }}
      >
        <div class="col-lg-6">
          <h4>Member </h4>
          <Select
            styles={{
              container: () => {
                return { height: 36, width: "90%", display: "inline-block" };
              }
            }}
            onChange={d => setMember(d)}
            value={member}
            options={props.memberOptions}
          />
          <span
            class="ml-3"
            style={{ color: "gray" }}
            onClick={() => setMember(null)}
          >
            X
          </span>
        </div>
        <div class="col-lg-6">
          <h4>Sales </h4>
          <Select
            styles={{
              container: () => {
                return { height: 36, width: "90%", display: "inline-block" };
              }
            }}
            onChange={data => setSales(data)}
            value={sales}
            options={props.salesOptions}
          />
          <span
            class="ml-3"
            style={{ color: "gray" }}
            onClick={() => setSales(null)}
          >
            X
          </span>
        </div>
      </div>

      <div class="tab-container">
        <ul class="nav nav-tabs card-nav-tabs" role="tablist">
          <li class="nav-item" style={cssPassingTop}>
            <a
              class="nav-link active show"
              href="#active2"
              data-toggle="tab"
              role="tab"
              aria-selected="true"
              onClick={() => {
                setView(0);
                setTimeout(()=>{
                  debugger
                   scanRef.current.focus();

                }, 200)
              }}
            >
              Cart {qtyCounts !== 0 && <span>({qtyCounts})</span>}
            </a>
          </li>
          <li class="nav-item" style={cssPassingTop}>
            <a
              class="nav-link"
              href="#link2"
              data-toggle="tab"
              role="tab"
              aria-selected="false"
              onClick={() => setView(1)}
            >
              Product List
            </a>
          </li>
          {view === 0 && (
            <li class="nav-item" style={{ width: "70%" }}>
              <a class="nav-link   show">
                <div class="row">
                  {/* SKU */}
                  <div class="col-lg-8 d-flex flex-row">
                    <div class="align-self-end">
                      <label
                        class=""
                        for="inputText3"
                        style={{ paddingtop: 8 }}
                      >
                        Scan
                      </label>
                    </div>
                    <div class="flex-grow-1 " style={{ marginLeft: 16 }}>
                      <input
                        ref={scanRef}
                        class="form-control"
                        type="text"
                        style={css}
                        value= {barcodeInput}
                        onChange={confimScan}
                        style={{ height: 38 }}
                      />
                    </div>
                  </div>

                  {/* QTY */}
                  <div class="col-lg-4 d-flex flex-row">
                    {" "}
                    <div class="align-self-end">
                      {" "}
                      <label class="" for="inputText3">
                        Qty
                      </label>{" "}
                    </div>
                    <div class="input-group mb-0" style={{ marginLeft: 16 }}>
                      <div
                        class="input-group-prepend"
                        onClick={() => setQty(qty - 1 == 0 ? 1 : qty - 1)}
                        style={css}
                      >
                        <span class="input-group-text">-</span>
                      </div>
                      <input
                        class="form-control"
                        type="text"
                        style={css}
                        value={qty}
                      />
                      <div
                        class="input-group-append"
                        style={css}
                        onClick={() => setQty(qty + 1)}
                      >
                        <span class="input-group-text">+</span>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </a>
            </li>
          )}
          {view === 1 && (
            <li class="nav-item" style={{ width: "70%" }}>
              <a class="nav-link   show">
                <div class="row justify-content-end">
                  {/* SKU */}
                  <div class="col-lg-8 d-flex justify-content-end flex-row">
                    <div class="align-self-end">
                      <label class="" for="inputText3">
                        Search
                      </label>
                    </div>
                    <div class="flex-grow-1 " style={{ marginLeft: 16 }}>
                      <Select
                        styles={{
                          container: () => {
                            return { height: 36 };
                          }
                        }}
                        onChange={addItem}
                        value={item}
                        options={props.menu_items}
                      />
                    </div>
                  </div>
                </div>{" "}
              </a>
            </li>
          )}
        </ul>
      </div>

      <div class="card-body">{props.children}</div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  let search = _.map(state.menu_items, line => {
    return {
      label: line.name,
      value: line
    };
  });

  let memberOptions = _.map(memberList, line => {
    return {
      label: line.name,
      value: line
    };
  });

  let salesOptions = _.map(salesList, line => {
    return {
      label: line.name,
      value: line
    };
  });

  return {
    menu_items_original : state.menu_items,
    menu_items: search,
    memberOptions,
    salesOptions,
    qtyCounts: _.reduce(
      state.qtyHolder,
      (acc, value) => {
        acc += value;
        return acc;
      },
      0
    )
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingCartContainer);
