import React, { useState } from "react";
import ActionsBar from "./ActionsBar";
import PosCartShoppingCartItemList from "./ShoppingCartItemList";
import ProductList from "./ProductList";
import ShoppingCartContainer from "./ShoppingCartContainer";
import PosCartShoppingCartPromotionList from "./ShoppingCartPromotion";
import PosCartSummary from "./Summary";
import { Provider } from "react-redux";
import ShoppingCartProductListConatiner from "./ShoppingCartProductListContainer";

import storeInit from "./store";

function PosCart(props) {
  const [count, setCount] = useState(0);
  const [store, setStore] = useState(storeInit);
  const [view, setView] = useState(0);
  return (
    <Provider store={store}>
      <div class="row">
        <div class="col-lg-12">{/* <ActionsBar /> */}</div>

        {/* ==== Left ====*/}
        <div class="col-lg-8 pl-7 mt-5">
          {/* scan bar,  search bar */}

          <ShoppingCartContainer  view={view} setView={setView}>
            {/* shopping cart */}
            {view === 0 && <PosCartShoppingCartItemList />}
            {view === 0 && <PosCartShoppingCartPromotionList />}
            {/* <ProductList /> */}
            {view == 1 && <ShoppingCartProductListConatiner />}
          </ShoppingCartContainer>
        </div>

        {/* ==== Right ====*/}
        <div class="col-lg-4 pr-7 mt-5">
          {/* Summary Bar */}
          <PosCartSummary />
        </div>
      </div>
    </Provider>
  );
}

export default PosCart;
