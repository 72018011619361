import React from "react";
import StockSummary from "./StockSummary";
import ManageStockExcel from "./ManageStockExcel";
import AddStockExcel from "./AddStockExcel";
import { Route, Switch, withRouter } from "react-router-dom";

class ManageStock extends React.Component {
  render() {
    // className="main-content container-fluid"
    const { match, history } = this.props;
    let path = history.location.pathname;

    return (
      <div>
        <div class="tab-container">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a
                class={`nav-link ${
                  path === `${match.url}` ? "active show" : ""
                }`}
                onClick={() => history.push(match.url)}
              >
                ภาพรวม
              </a>
            </li>
            <li class="nav-item">
              <a
                class={`nav-link ${
                  path === `${match.url}/manage` ? "active show" : ""
                }`}
                onClick={() => history.push(`${match.url}/manage`)}
              >
                จัดการสต๊อค
              </a>
            </li>
            <li class="nav-item">
              <a
                class={`nav-link ${
                  path === `${match.url}/addStock` ? "active show" : ""
                }`}
                onClick={() => history.push(`${match.url}/addStock`)}
              >
                เพิ่มสต๊อค
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane active show" role="tabpanel">
              <Switch>
                <Route
                  path={`${match.url}/addStock`}
                  render={props => <AddStockExcel {...props} />}
                />
                <Route
                  path={`${match.url}/manage`}
                  render={props => <ManageStockExcel {...props} />}
                />
                <Route exact path={match.url} component={StockSummary} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ManageStock);
