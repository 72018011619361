import React, { Component } from "react";
import * as Amplify from "../../shared/awsAPIs/Amplify";
import swal from "sweetalert";
import _ from "lodash";
import province_th_eng from "../../shared/exampleData/thailandProvincesArray";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shopActions from "../../shared/actions/shopActions";

const initData = {
  username: "", //for first time login user
  password: "", //for first time login user
  firstName: "", //for first time login user
  lastName: "", //for first time login user
  email: "abc@niceloop.com",
  rePassword: "",

  shopName: "",
  branchId: "",
  shippingAddress: "",
  province: null,
  tags: [],

  onValidate: false,

  provinceOptions: []
};

class SignUp extends Component {
  state = initData;

  componentWillReceiveProps(nextProps) {
    const { isEdit } = nextProps;
    if (isEdit) {
      this.setInitEditData(nextProps);
    }
  }

  setInitEditData = props => {
    this.setState({ ...initData });
    const { selectedBranch } = props;

    let data = {
      branchId: selectedBranch.branchId,
      shopName: selectedBranch.name,
      province: {
        label: selectedBranch.province,
        value: selectedBranch.province
      },
      shippingAddress: selectedBranch.shippingAddress || ""
    };
    let tags = [];
    if (selectedBranch.tags) {
      _.map(selectedBranch.tags, (bool, tagName) => {
        tags.push({ label: tagName, value: tagName, __isNew__: true });
      });
    }
    data.tags = tags;

    this.setState({ ...data });
    this.createProvinceOptions();
  };

  handleClickSignUp = () => {
    const {
      username,
      password,
      rePassword,
      email,
      shopName,
      province,
      tags,
      branchId,
      shippingAddress,
      firstName,
      lastName
    } = this.state;
    let { match, shopList } = this.props;
    let { params } = match;
    this.setState({ onValidate: true });

    if (
      branchId === "" ||
      shopName === "" ||
      username === "" ||
      password === "" ||
      rePassword === "" ||
      email === "" ||
      //   shippingAddress === "" ||
      firstName === "" ||
      lastName === ""
    ) {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
      return;
    }
    if (password.length < 6) {
      swal(
        "เกิดข้อผิดพลาด",
        "กรุณากรอก Password อย่างน้อย 6 ตัวอักษร",
        "error"
      );
      return;
    }
    if (password !== rePassword) {
      swal(
        "เกิดข้อผิดพลาด",
        "กรุณากรอก Password ให้ตรงกันทั้งสองช่อง",
        "error"
      );
      return;
    }

    //CHECK BRANCH ID EXIST
    let filterExistBranchId = _.filter(shopList, i => i.branchId === branchId);

    if (filterExistBranchId.length > 0) {
      swal("เกิดข้อผิดพลาด", "รหัสสาขานี้ถูกใช้แล้ว", "error");
      return;
    }

    if (!province) {
      swal("เกิดข้อผิดพลาด", "กรุณาเลือกจังหวัด", "error");
      return;
    }

    let computedTags = {};

    if (tags === null || _.size(tags) < 1) {
      computedTags = null;
    } else {
      _.forEach(tags, tag => {
        computedTags[tag.label] = true;
      });
    }

    Amplify.signUp(
      {
        shopName,
        username,
        password,
        firstName,
        lastName,
        email,
        province: province.value,
        tags: computedTags,
        link: params.shopId || null,
        shippingAddress: shippingAddress === "" ? "-" : shippingAddress,
        branchId
      },
      () => {
        swal("ยินดีด้วย!", "ลงทะเบียนสำเร็จ", "success").then(() => {
          if (_.isEmpty(params)) {
            window.location.href = "/logIn";
          } else {
            window.location.href = "/";
          }
        });
      }
    );
  };

  handleOnChangeInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      onValidate: false
    });
  };

  componentDidMount = () => {
    this.createProvinceOptions();
  };

  createProvinceOptions = () => {
    let provinceOptions = [];
    _.map(province_th_eng, name => {
      provinceOptions.push({ value: name, label: name });
    });

    this.setState({
      provinceOptions
    });
  };

  handleChangeProvince = province => {
    this.setState({ province });
  };
  handleChangeTags = (newValue, actionMeta) => {
    this.setState({ tags: newValue });
  };

  editBranchDetail = () => {
    const { branchId, tags, province, shopName, shippingAddress } = this.state;
    const { selectedBranch, actions, shopList, fetchShop } = this.props;

    if (branchId === "" || shopName === "") {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
      return;
    }

    //CHECK BRANCH ID EXIST
    let filterExistBranchId = _.filter(shopList, i => i.branchId === branchId);

    if (
      branchId !== selectedBranch.branchId &&
      filterExistBranchId.length > 0
    ) {
      swal("เกิดข้อผิดพลาด", "รหัสสาขานี้ถูกใช้แล้ว", "error");
      return;
    }

    if (!province) {
      swal("เกิดข้อผิดพลาด", "กรุณาเลือกจังหวัด", "error");
      return;
    }

    let data = {
      name: shopName,
      branchId,
      province: province.value,
      shippingAddress: shippingAddress === "" ? "-" : shippingAddress
    };

    let computedTags = {};

    if (tags === null || _.size(tags) < 1) {
      computedTags = null;
    } else {
      _.forEach(tags, tag => {
        computedTags[tag.label] = true;
      });
    }

    data.tags = computedTags;

    actions.hqUpdateShopDetail(data, selectedBranch.uid, () => {
      fetchShop();
      window.$("#branchDetailModal").modal("hide");
      swal("ยินดีด้วย!", "บันทึกข้อมูลสำเร็จ", "success");
    });
  };

  handleSubmit = () => {
    const { isEdit } = this.props;

    if (isEdit) {
      this.editBranchDetail();
      return;
    }

    this.handleClickSignUp();
  };

  render() {
    let {
      onValidate,
      username,
      password,
      rePassword,
      email,
      shopName,
      province,
      provinceOptions,
      tags,
      branchId,
      shippingAddress,
      firstName,
      lastName
    } = this.state;
    let { match, isEdit } = this.props;
    let { params } = match;
    return [
      <div
        class={`card card-border-color card-border-color-${
          isEdit ? "warning" : "primary"
        }`}
      >
        <div className="card-header">
          {/* <img className="logo-img" src="assets/img/logo-xx.png" alt="logo" width="102" height="27"> */}
          <h2>{isEdit ? "จัดการข้อมูลร้านค้า" : "เปิดร้าน/สาขาใหม่"}</h2>
          {/* <span className="splash-description">Please enter your user information.</span></div> */}
        </div>
        <div className="card-body">
          <form>
            <div className="form-group">
              <label>รหัสสาขา</label>
              <input
                value={branchId}
                className="form-control parsley-error"
                onChange={this.handleOnChangeInput}
                name="branchId"
                type="text"
                required=""
                placeholder="Enter Branch ID"
                autocomplete="off"
                data-parsley-id="5"
                aria-describedby="parsley-id-1"
              />
              {onValidate === true && branchId === "" && (
                <ul className="parsley-errors-list filled" id="parsley-id-1">
                  <li className="parsley-required">This value is required.</li>
                </ul>
              )}
            </div>
            <div className="form-group">
              <label>ชื่อร้าน/สาขา</label>
              <input
                value={shopName}
                className="form-control parsley-error"
                onChange={this.handleOnChangeInput}
                name="shopName"
                type="text"
                required=""
                placeholder="Enter shop name"
                autocomplete="off"
                data-parsley-id="5"
                aria-describedby="parsley-id-1"
              />
              {onValidate === true && shopName === "" && (
                <ul className="parsley-errors-list filled" id="parsley-id-1">
                  <li className="parsley-required">This value is required.</li>
                </ul>
              )}
            </div>
            {/* shippingAddress */}
            <div className="form-group">
              <label>ที่อยู่ในการจัดส่ง</label>
              <textarea
                className="form-control"
                value={shippingAddress}
                onChange={this.handleOnChangeInput}
                name="shippingAddress"
                placeholder="กรอกที่อยู่ในการจัดส่ง"
              ></textarea>
              {/* {onValidate === true && shippingAddress === "" && (
                <ul className="parsley-errors-list filled" id="parsley-id-1">
                  <li className="parsley-required">This value is required.</li>
                </ul>
              )} */}
            </div>
            {!isEdit && [
              <div className="form-group">
                <label>ชื่อจริง - นามสกุล</label>
                <div className="row m-0">
                  <div className="col-md-6 p-0">
                    <input
                      value={firstName}
                      className="form-control parsley-error"
                      onChange={this.handleOnChangeInput}
                      name="firstName"
                      type="text"
                      required=""
                      placeholder="First Name"
                      autocomplete="off"
                      data-parsley-id="5"
                    />
                  </div>
                  <div className="col-md-6 p-0">
                    <input
                      value={lastName}
                      className="form-control parsley-error"
                      onChange={this.handleOnChangeInput}
                      name="lastName"
                      type="text"
                      required=""
                      placeholder="Last Name"
                      autocomplete="off"
                      data-parsley-id="5"
                    />
                  </div>
                </div>
                {onValidate === true && firstName === "" && (
                  <ul className="parsley-errors-list filled" id="parsley-id-5">
                    <li className="parsley-required">
                      This value is required.
                    </li>
                  </ul>
                )}
              </div>,
              <div className="form-group">
                <label>Username (สำหรับ Log in ครั้งแรก)</label>
                <input
                  value={username}
                  className="form-control parsley-error"
                  onChange={this.handleOnChangeInput}
                  name="username"
                  type="text"
                  required=""
                  placeholder="Enter user name"
                  autocomplete="off"
                  data-parsley-id="5"
                />
                {onValidate === true && username === "" && (
                  <ul className="parsley-errors-list filled" id="parsley-id-5">
                    <li className="parsley-required">
                      This value is required.
                    </li>
                  </ul>
                )}
              </div>,
              <div className="form-group">
                <label>Password</label>
                <input
                  value={password}
                  className="form-control parsley-error"
                  onChange={this.handleOnChangeInput}
                  type="password"
                  name="password"
                  required=""
                  placeholder="Enter password"
                  autocomplete="off"
                  data-parsley-id="5"
                  aria-describedby="parsley-id-6"
                />
                {onValidate === true && password === "" && (
                  <ul className="parsley-errors-list filled" id="parsley-id-6">
                    <li className="parsley-required">
                      This value is required.
                    </li>
                  </ul>
                )}
              </div>,
              <div className="form-group">
                <label>Re-password</label>
                <input
                  value={rePassword}
                  className="form-control parsley-error"
                  onChange={this.handleOnChangeInput}
                  type="password"
                  name="rePassword"
                  required=""
                  placeholder="Re-enter password"
                  autocomplete="off"
                  data-parsley-id="5"
                  aria-describedby="parsley-id-7"
                />
                {onValidate === true && rePassword === "" && (
                  <ul className="parsley-errors-list filled" id="parsley-id-7">
                    <li className="parsley-required">
                      This value is required.
                    </li>
                  </ul>
                )}
              </div>
              // <div className="form-group">
              //     <label>E-mail</label>
              //     <input value={email} className="form-control parsley-error" onChange={this.handleOnChangeInput} type="text" name="email" required="" placeholder="E-mail" autocomplete="off" data-parsley-id="5" aria-describedby="parsley-id-8" />
              //     {onValidate === true && email === '' &&
              //         <ul className="parsley-errors-list filled" id="parsley-id-8">
              //             <li className="parsley-required">This value is required.</li>
              //         </ul>
              //     }
              // </div>
            ]}

            <div className="form-group">
              <label>จังหวัด</label>
              <Select
                value={province}
                onChange={this.handleChangeProvince}
                options={provinceOptions}
              />
              {onValidate === true && province === null && (
                <ul className="parsley-errors-list filled" id="parsley-id-8">
                  <li className="parsley-required">This value is required.</li>
                </ul>
              )}
            </div>
            <div className="form-group">
              <label>Tags (optional)</label>
              <CreatableSelect
                value={tags}
                isMulti
                onChange={this.handleChangeTags}
                options={tags}
              />
            </div>

            <div className="form-group login-submit">
              <a
                className={`btn btn-${isEdit ? "warning" : "primary"} btn-xl`}
                href="#"
                onClick={e => {
                  e.preventDefault();
                  this.handleSubmit();
                }}
                data-dismiss="modal"
              >
                {isEdit ? "SAVE" : "SIGN UP"}
              </a>
            </div>
          </form>
        </div>
      </div>,

      !isEdit &&
        (_.isEmpty(params) ? (
          <div className="splash-footer">
            <span>
              Go <a href="/logIn">Back</a> to log in
            </span>
          </div>
        ) : (
          <div className="splash-footer">
            <span>
              Go <a href="/">Back</a> to homepage
            </span>
          </div>
        ))
    ];
  }
}

const mapStateToProps = state => {
  return {
    shopList: state.shop.list.inventory
  };
};
function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...shopActions }, dispatch);

  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
