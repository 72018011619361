import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import swal from "sweetalert";
import _ from "lodash";
import * as employeeActions from "../actions/employeeActions";
import { Auth } from "aws-amplify";

class EmployeePinInputModal extends React.Component {
  state = {
    pinInput: ""
  };
  signOut = () => {
    swal({
      title: `Sign Out ?`,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        Auth.signOut();
        window.location.href = "/";
      }
    });
  };
  submit = () => {
    const { employeeList, actions } = this.props;
    const { pinInput } = this.state;
    let foundEmp = _.find(employeeList, i => i.pin === pinInput);
    if (foundEmp) {
      actions.setLoggedInEmployee(foundEmp);
    } else {
      swal("เกิดข้อผิดพลาด", "ไม่พบ PIN ที่คุณระบุ", "error");
    }
  };

  handleKeyPress = event => {
    if (event.key == "Enter") {
      this.submit();
    }
  };

  render() {
    return (
      <div>
        <div
          class="modal fade show"
          id="employeePinInputModal"
          //   style={{ perspective: "none" }}
          style={{ display: "block" }}
        >
          <div class="modal-content">
            <div class="modal-header">
              <div>
                <span style={{ fontSize: 25 }}>
                  <u>Enter your PIN</u>
                </span>
              </div>
              <button class="close" type="button" onClick={this.signOut}>
                <span class="mdi mdi-close" />
              </button>
            </div>
            <div class="modal-body">
              <div>
                <div>
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="password"
                      value={this.state.pinInput}
                      onChange={e =>
                        this.setState({ pinInput: e.target.value })
                      }
                      onKeyPress={this.handleKeyPress}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer p-0">
              <button
                class="btn btn-space btn-success m-0"
                type="button"
                style={{ width: "100%", borderRadius: 0, height: 50 }}
                onClick={this.submit}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
        <div class="modal-backdrop fade show" />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    employeeList: state.employee.list
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...employeeActions }, dispatch);

  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeePinInputModal);
