import AWS from 'aws-sdk';


const API_KEY = 'AKIAJOXLNF65JP5EFKBQ'
const SECERT_KEY = 'qw0fF6TajLT/RQoWwZ9K30O/66Oj6MSbIIPfnNhE'

export const documentClient = new AWS.DynamoDB.DocumentClient({
    region: 'ap-southeast-1',
    endpoint: 'dynamodb.ap-southeast-1.amazonaws.com',
    accessKeyId: API_KEY,
    secretAccessKey: SECERT_KEY
}) 

export const sqs = new AWS.SQS({
    region: 'ap-southeast-1',
    accessKeyId: API_KEY,
    secretAccessKey: SECERT_KEY
})
  
