import moment from "moment";
import documentClient from "../../shared/awsAPIs/dynamoDb";
export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_CLEAR = "CART_CLEAR";
export const CART_REMOVE_ITEMLINE = "CART_REMOVE_ITEMLINE";

export const CART_OPTIONS_INSERT = "CART_OPTIONS_INSERT";
export const CART_OPTIONS_EDIT = "CART_OPTIONS_EDIT";
export const CART_OPTIONS_REMOVE = "CART_OPTIONS_REMOVE";

export const CART_OPTIONS_SAVE = "CART_OPTIONS_SAVE";

export const addItem = (item, qty = 1) => {
  return { type: CART_ADD_ITEM, payload: { item: item, qty } };
};

export const removeItemLine = (indexRow, uid) => {
  return {
    type: CART_REMOVE_ITEMLINE,
    payload: {
      index: indexRow,
      uid
    }
  };
};

export const clear = () => {
  return { type: CART_CLEAR };
};

export const save = () => (dispatch, getState) => {};

export const optionsInsert = (index, name, value) => {
  return {
    type: CART_OPTIONS_INSERT,
    payload: {
      index,
      name,
      value
    }
  };
};
export const optionsEdit = (index, name, value, sign) => {
  return {
    type: CART_OPTIONS_EDIT,
    payload: {
      index,
      name,
      value,
      sign
    }
  };
};
export const optionsRemove = (index, name, value) => {
  return {
    type: CART_OPTIONS_REMOVE,
    payload: {
      index,
      name,
      value
    }
  };
};
export const optionsSave = data => {
  return {
    type: CART_OPTIONS_SAVE,
    payload: data
  };
};

export const payment = () => {
  return async (dispatch, getState) => {
    var data = {
      shopId: "0000",
      timestamp: moment().toISOString()
      //..._.omit(getState(), ['menu_items', 'inventoryReducer'] )
    };

    var params = {
      TableName: "",
      Item: data
    };

    var res = await documentClient.put(params).promise();

    dispatch(clear() )
  };
};
