import React from "react";
import swal from "sweetalert";
import * as Amplify from "../../shared/awsAPIs/Amplify";
import * as userActions from "../../shared/actions/userActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { s3 } from "../../shared/awsAPIs/s3";
import moment from "moment";
import * as linkedSelector from "../../shared/selector/linkedSelector";

const defaultImg = require("../../shared/image/noImg.png");

const initData = {
  username: "",
  password: "",
  rePassword: "",
  email: "abc@niceloop.com",
  firstName: "",
  lastName: "",
  profileImg: null,
  imgFile: null,
  gender: null,
  ownerApproved: false
};

class CreateAccountModal extends React.Component {
  state = initData;

  componentWillReceiveProps(nextProps) {
    //clear OR init Edit Data
    const { isEdit, selectedUser } = nextProps;

    if (isEdit !== true) {
      //ถ้าไม่ได้ edit
      this.setState({ ...initData });
      return;
    }

    this.setState({
      firstName: selectedUser.firstName,
      lastName: selectedUser.lastName,
      email: selectedUser.email,
      username: selectedUser.username,
      gender: selectedUser.gender || null,
      profileImg: selectedUser.profileImg || null,
      imgFile: null,
      ownerApproved: selectedUser.ownerApproved || false
    });
  }

  handleClickSignUp = () => {
    const {
      username,
      password,
      rePassword,
      email,
      firstName,
      lastName,
      gender,
      imgFile,
      ownerApproved
    } = this.state;
    const { fetchUserList, actions, selectedBranch } = this.props;
    this.setState({ onValidate: true });

    if (
      username === "" ||
      password === "" ||
      rePassword === "" ||
      email === "" ||
      firstName === "" ||
      lastName === "" ||
      gender === null
    ) {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
      return;
    }
    if (password.length < 6) {
      swal(
        "เกิดข้อผิดพลาด",
        "กรุณากรอก Password อย่างน้อย 6 ตัวอักษร",
        "error"
      );
      return;
    }
    if (password !== rePassword) {
      swal(
        "เกิดข้อผิดพลาด",
        "กรุณากรอก Password ให้ตรงกันทั้งสองช่อง",
        "error"
      );
      return;
    }

    function createUser(profileImg) {
      let data = {
        shopId: selectedBranch.value,
        username,
        password,
        email,
        firstName,
        lastName,
        gender,
        ownerApproved
      };

      if (profileImg) {
        data.profileImg = profileImg;
      }
      Amplify.signUp(
        data,
        () => {
          swal("ยินดีด้วย!", "เพิ่ม Account สำเร็จ", "success").then(() => {
            fetchUserList();
            window.$("#createUserModal").modal("hide");
          });
        },
        true
      );
    }
    if (imgFile) {
      this.updateProfileImg(username, imgFile, createUser);
    } else {
      createUser();
    }
  };

  editUserDetail = () => {
    const { selectedUser, actions, fetchUserList } = this.props;
    const {
      firstName,
      lastName,
      email,
      gender,
      imgFile,
      ownerApproved
    } = this.state;
    if (
      email === "" ||
      firstName === "" ||
      lastName === "" ||
      gender === null
    ) {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
      return;
    }
    function editUser(profileImg) {
      let data = {
        ...selectedUser,
        firstName,
        lastName,
        email,
        gender,
        ownerApproved
      };
      if (profileImg) {
        data.profileImg = profileImg;
      }
      actions.editUserDetail(data, () => {
        fetchUserList();
        window.$("#createUserModal").modal("hide");
        swal("ยินดีด้วย!", "แก้ไขข้อมูลสำเร็จ", "success");
      });
    }

    if (imgFile) {
      this.updateProfileImg(selectedUser.username, imgFile, editUser);
    } else {
      editUser();
    }
  };

  handleOnChangeInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
      onValidate: false
    });
  };
  saveImgState = e => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    var reader = new FileReader();
    var url = reader.readAsDataURL(file); //DONT DELETE THIS
    reader.onloadend = function(e) {
      this.setState({
        imgFile: file,
        profileImg: [reader.result] //temporary path
      });
    }.bind(this);
  };

  updateProfileImg = (username, image, cb) => {
    // let fileName = moment().valueOf()
    var params = {
      Bucket: "inventory-management",
      Key: `profileImg/${username}`,
      Body: image
    };
    swal("กรุณารอสักครู่", "ระบบกำลังบันทึกข้อมูล", "info");
    s3.upload(params, (err, res) => {
      if (err) {
        alert(err);
        return;
      }
      cb && cb(res.Location);
    });
  };
  setGender = gender => {
    this.setState({ gender });
  };

  render() {
    const {
      username,
      password,
      rePassword,
      email,
      firstName,
      lastName,
      profileImg,
      gender,
      ownerApproved
    } = this.state;
    const { isEdit, selectedBranch, isLinked } = this.props;

    return (
      <div
        class={`modal colored-header colored-header-${
          isEdit ? "warning" : "success"
        }`}
        id="createUserModal"
        style={{ perspective: "none" }}
      >
        <div class="modal-content" style={{ width: 400 }}>
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">
              {isEdit
                ? "รายละเอียดบัญชีผู้ใช้"
                : `เพิ่มผู้ใช้ - ${selectedBranch.label}`}
            </h3>
            <button
              class="close modal-close"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body form" style={{ padding: 10 }}>
            {isEdit && (
              <div class="form-group">
                <label>Username</label>
                <input
                  value={username}
                  onChange={this.handleOnChangeInput}
                  class="form-control"
                  name="username"
                  placeholder="Enter username"
                  disabled
                />
              </div>
            )}
            <div class="form-group">
              <label>ชื่อจริง - นามสกุล</label>
              <div className="row m-0">
                <div className="col-md-6 p-0">
                  <input
                    value={firstName}
                    onChange={this.handleOnChangeInput}
                    class="form-control"
                    name="firstName"
                    placeholder="First Name"
                  />
                </div>
                <div className="col-md-6 p-0">
                  <input
                    value={lastName}
                    onChange={this.handleOnChangeInput}
                    class="form-control"
                    name="lastName"
                    placeholder="Last Name"
                  />
                </div>
              </div>
            </div>

            {!isEdit && [
              <div class="form-group">
                <label>Username</label>
                <input
                  value={username}
                  onChange={this.handleOnChangeInput}
                  class="form-control"
                  name="username"
                  placeholder="Enter username"
                />
              </div>,
              <div class="form-group">
                <label>Password</label>
                <input
                  value={password}
                  onChange={this.handleOnChangeInput}
                  type="password"
                  class="form-control"
                  name="password"
                  placeholder="Enter Password"
                />
              </div>,
              <div class="form-group">
                <label>Re-password</label>
                <input
                  value={rePassword}
                  onChange={this.handleOnChangeInput}
                  type="password"
                  class="form-control"
                  name="rePassword"
                  placeholder="Re-enter password"
                />
              </div>
            ]}

            {/* <div class="form-group">
                            <label>E-mail</label>
                            <input value={email} onChange={this.handleOnChangeInput} class="form-control" name="email" placeholder="E-mail" />
                        </div> */}
            <label>Gender</label>
            <div className="row m-0">
              <div>
                <label class="custom-control custom-radio custom-control-inline">
                  <input
                    class="custom-control-input"
                    type="radio"
                    // name="radio-inline"
                    checked={gender === "male"}
                    onClick={() => this.setGender("male")}
                  />
                  <span class="custom-control-label">Male</span>
                </label>
              </div>
              <div>
                <label class="custom-control custom-radio custom-control-inline">
                  <input
                    class="custom-control-input"
                    type="radio"
                    // name="radio-inline"
                    checked={gender === "female"}
                    onClick={() => this.setGender("female")}
                  />
                  <span class="custom-control-label">Female</span>
                </label>
              </div>
            </div>

            <label>
              Owner Approve{" "}
              <span style={{ color: "red" }}>(HQ ปรับเท่านั้น)</span>
            </label>
            <div className="row m-0">
              <div>
                <label class="custom-control custom-radio custom-control-inline">
                  <input
                    class="custom-control-input"
                    type="radio"
                    // name="radio-inline"
                    checked={ownerApproved === true}
                    onClick={() => this.setState({ ownerApproved: true })}
                    disabled={isLinked}
                  />
                  <span class="custom-control-label">Yes</span>
                </label>
              </div>
              <div>
                <label class="custom-control custom-radio custom-control-inline">
                  <input
                    class="custom-control-input"
                    type="radio"
                    // name="radio-inline"
                    checked={ownerApproved === false}
                    onClick={() => this.setState({ ownerApproved: false })}
                    disabled={isLinked}
                  />
                  <span class="custom-control-label">No</span>
                </label>
              </div>
            </div>

            <div className="mt-2">
              <center>
                <img
                  src={profileImg || defaultImg}
                  width={150}
                  height={150}
                  className="rounded-circle"
                  key={moment().toISOString()}
                />
              </center>
            </div>
            <div className="mt-2">
              <form>
                <center>
                  <input
                    class="inputfile"
                    id="file-2"
                    type="file"
                    ref="file"
                    onChange={this.saveImgState}
                    accept="image/*"
                  />
                  <label class="btn-primary" for="file-2">
                    {" "}
                    <i class="mdi mdi-upload"></i>
                    <span>Browse Image...</span>
                  </label>
                </center>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary modal-close"
              type="button"
              onClick={() => window.$("#createUserModal").modal("hide")}
            >
              Cancel
            </button>
            {isEdit ? (
              <button
                class="btn btn-warning modal-close"
                type="button"
                onClick={() => {
                  this.editUserDetail();
                }}
              >
                Save
              </button>
            ) : (
              <button
                class="btn btn-success modal-close"
                type="button"
                onClick={() => this.handleClickSignUp()}
              >
                Create
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedInUser: state.user.loggedIn,
    isLinked: linkedSelector.isLinkedSelector(state)
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...userActions }, dispatch);
  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountModal);
