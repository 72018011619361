import React, { useState } from "react";

import _ from 'lodash'

const optionList = [
    {
      name: "ServiceCharge",
      sign: 1
    },
    {
      name: "CreditCard Charge",
      sign: 1
    },
    {
      name: "Addtional Discount",
      sign: -1
    },
  
    {
      name: "Voucher",
      sign: -1
    }
  ];
  
  
  
function Option2Line({ removeLine, index, handleEdit, save, allList, data }) {
    return (
      <a class="list-group-item d-flex list-group-item-action">
        <div class="row">
          <div class="col-3">Option</div>
          <div class="col-9">
            <select
              class="form-control form-control-xs"
              onChange={evt => {
                debugger;
                handleEdit(index, "name", optionList[evt.target.value].name);
                handleEdit(index, "sign", optionList[evt.target.value].sign);
              }}
            >
              <option value="" disabled selected>Select Option</option>
              {_.map(optionList, (o, i2) => {
                return (
                  <option value={i2}>
                    ({o.sign === 1 ? "+" : "-"}) {o.name}
                  </option>
                );
              })}
            </select>
          </div>
  
          <div class="col-3">Value</div>
          <div class="col-9">
            <div class="input-group mb-0">
              <input
                class="form-control form-control-xs"
                type="text"
                value={data.value}
                class="form-control form-control-xs"
                onChange={evt => handleEdit(index, "value", evt.target.value)}
              />
              <div class="input-group-append be-addon">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  {data.mode === 0 ? "%" : "THB"}
                </button>
                <div class="dropdown-menu">
                  <a
                    class="dropdown-item"
                    href="#"
                    onClick={() => handleEdit(index, "mode", 0)}
                  >
                    Percent
                  </a>
                  <a
                    class="dropdown-item"
                    href="#"
                    onClick={() => handleEdit(index, "mode", 1)}
                  >
                    AMOUNT
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">Remark</div>
          <div class="col-9">
            <input
              vale={data.remark}
              onChange={evt => handleEdit(index, "remark", evt.target.value)}
              class="form-control form-control-xs"
            />
          </div>
          <div class="col-3" />
          <div class="col-9">
            <button onClick={()=> save() }>save</button>
            <button onClick={() => removeLine(index)}>remove</button>
          </div>
        </div>
      </a>
    );
  }
  

  export default Option2Line