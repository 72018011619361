import randomString from "random-string";
import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
import moment from "moment";
// import async from "async";
import * as type from "../types/cart";
import * as typeHistory from "../types/history";

import * as inventorySelector from "../selector/inventorySelector";
import * as historySelector from "../selector/historySelector";
import * as linkedSelector from "../selector/linkedSelector";
import * as branchOptionsSelector from "../selector/branchOptionsSelector";
import * as appStateActions from "./appStateActions";
import api from "../../shared/api";
import swal from "sweetalert";
import { fetchInventory } from "./inventoryItemActions";

export const typeProductQty = (itemUid, qty) => {
  return (dispatch, getState) => {
    dispatch({
      type: type.TYPING_PRODUCT_QTY,
      payload: {
        itemUid,
        qty
      }
    });
  };
};

export const addProductQty = itemUid => {
  return (dispatch, getState) => {
    const { cart } = getState();
    let qty = cart.items[itemUid] ? cart.items[itemUid] + 1 : 1;
    dispatch({
      type: type.TYPING_PRODUCT_QTY,
      payload: {
        itemUid,
        qty
      }
    });
  };
};

export const decreaseProductQty = itemUid => {
  return (dispatch, getState) => {
    const { cart } = getState();
    let qty = cart.items[itemUid];
    if (qty === undefined) {
      return;
    }
    if (qty === 1) {
      dispatch(deleteCartProduct(itemUid));
      return;
    }
    qty--;
    dispatch({
      type: type.TYPING_PRODUCT_QTY,
      payload: {
        itemUid,
        qty
      }
    });
  };
};

export const deleteCartProduct = itemUid => {
  return (dispatch, getState) => {
    dispatch({
      type: type.CART_DELETE_PRODUCT,
      payload: {
        itemUid
      }
    });
  };
};

export const clearCart = isClearOnlyItems => {
  return (dispatch, getState) => {
    if (isClearOnlyItems) {
      //CLEAR BRANCH ที่จะซื้อด้วย
      dispatch({
        type: type.CLEAR_CART_ONLY_ITEMS
      });
    } else {
      dispatch({
        type: type.CLEAR_CART
      });
    }
  };
};

export const dontClearCartNextTime = mode => {
  return (dispatch, getState) => {
    dispatch({
      type: type.DONT_CLEAR_CART_NEXT_TIME,
      payload: mode
    });
  };
};

export const purchase = (
  products,
  remark,
  paymentType,
  totalPrice,
  totalQty,
  cbSuccess,
  setLoading
) => {
  return async (dispatch, getState) => {
    setLoading(true);
    let isLinked = linkedSelector.isLinkedSelector(getState()); //CHECK HQ
    const { user, shop, cart } = getState();
    const { loggedIn } = user;
    let timestamp = moment().toISOString();
    let hqId = shop.linked.inventory || "----";
    let params = {
      TableName: "Minor_Invoices",
      Item: {
        shopId: loggedIn.shopId,
        shopName: shop.detail.name,
        timestamp: timestamp,
        // ownerId: '3181',
        // invoiceId,
        items: products,
        grandTotal: totalPrice,
        qtyAll: totalQty,
        remark: remark === "" ? null : remark,
        employee: {
          name: loggedIn.firstName + " " + loggedIn.lastName,
          shopId: loggedIn.shopId,
          username: loggedIn.username
        },
        status: "200",
        statusHistory: [
          {
            by: {
              name: loggedIn.firstName + " " + loggedIn.lastName,
              shopId: loggedIn.shopId,
              username: loggedIn.username
            },
            status: "200",
            // reason: reason,
            timestamp: timestamp
          }
        ],
        payment: [
          {
            ...paymentType,
            amount: totalPrice
          }
          // {
          //   uid: "32kf03f",
          //   name: "Cash",
          //   amount: totalPrice
          // }
        ],
        shippingAddress: shop.detail.shippingAddress,
        branchId: shop.detail.branchId,
        hqId
      }
    };

    if (!isLinked) {
      //[FOR HQ] ใส่ branch ที่ HQ เลือกเข้าไปแทน
      let { value, label, branchName } = cart.cartHqInfo.destination; //value = shopId, label = shopName
      let branchObj = branchOptionsSelector.branchObjSelector(getState());
      hqId = shop.detail.uid;
      params.Item.shopId = value;
      params.Item.shopName = branchName;
      params.Item.shippingAddress =
        branchObj[value].shippingAddress || "[ไม่ได้ระบุที่อยู่]";
      params.Item.branchId = branchObj[value].branchId || "-";
      params.Item.hqId = hqId;
    }

    let priceChange = await api.checkPrice(params.Item, hqId);
    let priceChangedList = priceChange.data;

    if (priceChangedList.length > 0) {
      setLoading(false);
      swal(
        "ราคาสินค้ามีการแก้ไข",
        "ราคาของสินค้าที่คุณเลือกมีการแก้ไขแล้ว กรุณาตรวจสอบข้อมูลการสั่งสินค้าอีกครั้ง",
        "warning"
      ).then(() => {
        let deletedItemList = _.filter(
          priceChangedList,
          i => i.newPrice === -1
        );
        if (deletedItemList.length > 0) {
          //ลบ Items ที่ไม่มีออก
          _.forEach(deletedItemList, item => {
            dispatch(deleteCartProduct(item.uid));
          });

          swal(
            "Error",
            "สินค้าบางชิ้นของคุณถูกนำออกจากตะกร้า เพราะผู้ขายทำการลบสินค้าเหล่านั้นแล้ว",
            "error"
          );
        }
      });

      dispatch(fetchInventory());
      return;
    }

    dispatch(appStateActions.loading(true));

    api
      .checkStock(params.Item)
      .then(res => {
        if (res.data.length > 0) {
          swal(
            "สินค้าไม่เพียงพอ",
            "กรุณาตรวจสอบรายการสินค้าที่ช่องสีแดงด้านบน",
            "error"
          );
          dispatch(setCartFailList(res.data));
          setLoading(false);
          return;
        }
        api
          .getInvoiceId(params.Item.hqId)
          .then(res => {
            params.Item.invoiceId = res.data;
            documentClient.put(params, function(err, data) {
              if (err) {
                alert(err);
                setLoading(false);
              } else {
                dispatch(setCartFailList([]));
                dispatch(clearCart());
                cbSuccess();
                setLoading(false);
              }
            });
          })
          .catch(err => {
            alert(err);
            setLoading(false);
          });
      })
      .catch(err => {
        alert(err);
        setLoading(false);
      })
      .finally(() => {
        dispatch(appStateActions.loading(false));
      });
  };
};

export const initEditInvoiceItem = invoice => {
  return (dispatch, getState) => {
    let itemObj = inventorySelector.itemObjSelector(getState());
    let itemToBeAdded = {};
    _.forEach(invoice.items, item => {
      if (item.type === "group") {
        return;
      }
      if (itemObj[item.uid] !== undefined) {
        itemToBeAdded[item.uid] = item.qty;
      }
    });

    dispatch({
      type: type.INSERT_ITEM,
      payload: itemToBeAdded
    });
  };
};
export const editInvoice = (
  params,
  products,
  paymentType,
  totalPrice,
  totalQty,
  reason,
  cbSuccess,
  setLoading
) => {
  return async (dispatch, getState) => {
    const { user, shop, history } = getState();
    const { loggedIn } = user;
    let isLinked = linkedSelector.isLinkedSelector(getState()); //CHECK HQ
    let hqId = isLinked ? shop.linked.inventory : shop.detail.uid;
    let tab = history.historyPage.tab;

    let purchaseObj = {}; //INVOICE REFERENCE
    if (tab === "pending") {
      purchaseObj = historySelector.purchasePendingObjSelector(getState());
    } else if (tab === "completed") {
      purchaseObj = historySelector.purchaseCompleteObjSelector(getState());
    } else {
      purchaseObj = historySelector.purchaseSearchObjSelector(getState());
    }

    let statusHistory = purchaseObj[params.invoiceId].statusHistory || [];

    statusHistory = [
      ...statusHistory,
      {
        by: {
          name: loggedIn.firstName + " " + loggedIn.lastName,
          shopId: loggedIn.shopId
        },
        status: "300",
        reason: reason,
        timestamp: moment().toISOString()
      }
    ];

    let params2 = {
      TableName: "Minor_Invoices",
      Key: {
        shopId: params.shopId,
        timestamp: params.timestamp
      },
      UpdateExpression:
        "set #items = :items, grandTotal = :grandTotal, qtyAll = :qtyAll, payment= :payment,statusHistory = :statusHistory, #status = :newStatus",
      ExpressionAttributeNames: {
        "#items": "items",
        "#status": "status"
      },
      ExpressionAttributeValues: {
        ":items": products,
        ":grandTotal": totalPrice,
        ":qtyAll": totalQty,
        ":payment": [
          {
            ...paymentType,
            amount: totalPrice
          }
          // {
          //   uid: "32kf03f",
          //   name: "Cash",
          //   amount: totalPrice
          // }
        ],
        ":statusHistory": statusHistory,
        ":newStatus": "300"
      }
    };

    //จะทำการ Void หรือ Edit สินค้า ต้องเช็คก่อนว่าสถานะยัง 200 หรือ edited อยู่หรือไม่
    params2.ConditionExpression =
      "#status = :strFindStatus OR #status = :editedStatus";
    params2.ExpressionAttributeValues[":strFindStatus"] = "200";
    params2.ExpressionAttributeValues[":editedStatus"] = "300";

    let priceChange = await api.checkPrice(
      {
        items: products
      },
      hqId
    );
    let priceChangedList = priceChange.data;

    if (priceChangedList.length > 0) {
      setLoading(false);
      swal(
        "ราคาสินค้ามีการแก้ไข",
        "ราคาของสินค้าที่คุณเลือกมีการแก้ไขแล้ว กรุณาตรวจสอบข้อมูลการสั่งสินค้าอีกครั้ง",
        "warning"
      ).then(() => {
        let deletedItemList = _.filter(
          priceChangedList,
          i => i.newPrice === -1
        );
        if (deletedItemList.length > 0) {
          //ลบ Items ที่ไม่มีออก
          _.forEach(deletedItemList, item => {
            dispatch(deleteCartProduct(item.uid));
          });

          swal(
            "Error",
            "สินค้าบางชิ้นของคุณถูกนำออกจากตะกร้า เพราะผู้ขายทำการลบสินค้าเหล่านั้นแล้ว",
            "error"
          );
        }
      });

      dispatch(fetchInventory());
      return;
    }

    let tx = purchaseObj[params.invoiceId];

    dispatch(appStateActions.loading(true));
    api
      .checkStock({
        items: products,
        hqId
      })
      .then(res => {
        if (res.data.length > 0) {
          swal(
            "สินค้าไม่เพียงพอ",
            "กรุณาตรวจสอบรายการสินค้าที่ช่องสีแดงด้านบน",
            "error"
          );
          dispatch(setCartFailList(res.data));
          setLoading(false);
          return;
        }

        documentClient.update(params2, function(err, data) {
          if (err) {
            if (err.code === "ConditionalCheckFailedException") {
              setLoading(false);
              swal(
                "เกิดข้อผิดพลาด",
                "ไม่สามารถทำรายการได้เนื่องจากสถานะถูกยืนยันแล้ว",
                "error"
              );
            } else {
              setLoading(false);
              alert(err);
            }
          } else {
            //FAKE UPDATE
            if (tab === "completed" || tab === "search") {
              let purchase;
              if (tab === "completed") {
                purchase = history.purchaseComplete;
              } else {
                purchase = history.purchaseSearch;
              }
              const { transactions, currentPage } = purchase;

              let newTx = _.cloneDeep(transactions);
              _.forEach(newTx[currentPage], (data, index) => {
                if (
                  data.shopId === tx.shopId &&
                  data.timestamp === tx.timestamp
                ) {
                  newTx[currentPage][index] = {
                    ...tx,
                    status: "300",
                    statusHistory,
                    items: products,
                    grandTotal: totalPrice,
                    qtyAll: totalQty,
                    payment: [
                      {
                        uid: "32kf03f",
                        name: "Cash",
                        amount: totalPrice
                      }
                    ]
                  };
                }
              });
              if (tab === "completed") {
                dispatch({
                  type: typeHistory.FAKE_UPDATE_TRANSACTION,
                  payload: newTx
                });
              } else {
                dispatch({
                  type: typeHistory.FAKE_UPDATE_TRANSACTION_SEARCH,
                  payload: newTx
                });
              }
            }
            dispatch(setCartFailList([]));
            cbSuccess && cbSuccess();
            setLoading(false);
          }
        });
      })
      .catch(err => {
        setLoading(false);
        alert(err);
      })
      .finally(() => {
        dispatch(appStateActions.loading(false));
      });
  };
};

export const setCartFailList = list => {
  return (dispatch, getState) => {
    dispatch({
      type: type.SET_FAIL_LIST,
      payload: list
    });
  };
};
