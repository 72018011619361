import * as actions from "./actions";

//ส้งเท่านี้พอ ที่เหลือเอาใน selector
let jobInit = {
  items: [],
  options: [],
   qtyHolder: {},
  customers: 1,
  ref1: "RICE", //table, job
  ref2: "", //comment
  uuid: "",
  // employee : {},
  timestamp: "",

  menu_items: [],
  inventoryReducer: {},
  saveInstance: {},
  promotionList :[],  // from original outside
};

export default (state = jobInit, action) => {
  switch (action.type) {
    case actions.CART_ADD_ITEM:
      let currentQty = state.qtyHolder[action.payload.item.uid] || 0;
      var newQtyHolder = {
        ...state.qtyHolder,
        [action.payload.item.uid]: currentQty + (action.payload.qty || 1)
      };

      var newItems = null;
      if (currentQty === 0) {
        // new
        newItems = [...state.items, { ...action.payload.item }];
      } else {
        newItems = state.items;
      }

      return { ...state, qtyHolder: newQtyHolder, items: newItems };

    case actions.CART_CLEAR:
      return { ...state, qtyHolder: {}, items: [] };
      break;

    case actions.CART_REMOVE_ITEMLINE:
      const newState = Object.assign({}, state.qtyHolder);
      delete newState[action.payload.uid];

      var newItems = state.items.filter((line, i) => {
        return i !== action.payload.index;
      });

      return { ...state, qtyHolder: newState, items: newItems };

    case "MENU_ITEMS":
      return { ...state, menu_items: action.payload };
    case "INVENTORY_REDUCER":
      return { ...state, inventoryReducer: action.payload };
    case actions.CART_OPTIONS_SAVE:
      return { ...state, options: action.payload };

      case 'PROMOTION_LIST': 
        return {...state, promotionList : action.payload}

         
    default:
      return state;
  }
};
