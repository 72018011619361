import React from 'react'
import { SortableContainer, SortableElement, arrayMove, SortableHandle } from 'react-sortable-hoc';
import _ from 'lodash'

const DragHandle = SortableHandle(() => <i className="icon icon-left mdi mdi-sort-amount-desc"></i>); // This can be any component you want

const SortableItem = SortableElement(({ readOnly, value, handleClickGroup, pickedGroupUid, editGroupName, deleteGroup, pickedTypeUid, moveToAnotherType }) =>
    // active
    <span href={`#${value.uid}`} class={"list-group-item  list-group-item-action " + (value.uid === pickedGroupUid ? "active" : "")}
        onClick={(e) => {
            e.preventDefault()
            handleClickGroup(value.uid)
        }}
        // style={readOnly ? {} : { zIndex: 9999 }}
        style={{ paddingLeft: 12, paddingRight: 12, paddingTop: 10, paddingBottom: 10 }}
    >
        <div className="row">
            <div className="col-md-1">
                {readOnly !== true && value.uid !== 'other' && pickedTypeUid !== 'all' && <DragHandle />}
            </div>
            <div className="col-md-6">
                <span className="text" style={{ color: value.uid === pickedGroupUid ? "white" : "" }}>{value.name}</span>
            </div>
            {readOnly !== true && value.uid !== 'other' &&
                <div className="col-md-4">
                    <div className="row">
                        <a href="#"
                            style={{ width: 24 }}
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                editGroupName(value)
                            }}><i className="icon mdi mdi-edit"></i></a>
                        <a href="#"
                            style={{ width: 24 }}
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                moveToAnotherType(value)
                            }}><i className="icon mdi mdi-swap"></i></a>
                        <a href="#"
                            style={{ width: 24 }}
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                deleteGroup(value)
                            }}><i className="icon mdi mdi-delete"></i></a>
                    </div>
                </div>}
        </div>
    </span>
);

const SortableList = SortableContainer(({ readOnly, items, handleClickGroup, pickedGroupUid, editGroupName, deleteGroup, hasOtherType, pickedTypeUid, moveToAnotherType }) => {

    let item2 = hasOtherType ? [...items, { name: 'อื่น ๆ', uid: 'other' }] : items

    return (
        <div className="list-group d-flex">
            {_.map(item2, (value, index) => (
                <SortableItem
                    pickedGroupUid={pickedGroupUid}
                    key={`item-${index}`}
                    index={index} value={value}
                    handleClickGroup={handleClickGroup}
                    editGroupName={editGroupName}
                    deleteGroup={deleteGroup}
                    readOnly={readOnly}
                    pickedTypeUid={pickedTypeUid}
                    moveToAnotherType={moveToAnotherType} />
            ))}
        </div>
    );
});

class SortableComponent extends React.Component {
    state = {
        items: [],
        timestamp: 1,

    }
    componentWillReceiveProps(nextProps) {
        let { groups, timestamp } = nextProps
        //  
        // if (timestamp !== this.state.timestamp) {
        this.setState({ items: groups, timestamp })
        // }
    }

    componentDidMount() {
        let { groups, timestamp } = this.props
        this.setState({ items: groups, timestamp })
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        let { actions, mode } = this.props
        let newItems = arrayMove(this.state.items, oldIndex, newIndex)
        this.setState({
            items: newItems
        })

        if (mode === 'type') {
            actions.reorderInventoryTypes(newItems)
        } else {
            actions.reorderInventoryGroups(newItems)
        }
    };
    render() {
        let { pickedGroupUid, handleClickGroup, editGroupName, deleteGroup, readOnly, hasOtherType, pickedTypeUid, moveToAnotherType } = this.props
        return <SortableList
            readOnly={readOnly}
            useDragHandle={true}
            handleClickGroup={handleClickGroup}
            pickedGroupUid={pickedGroupUid}
            items={this.state.items}
            onSortEnd={this.onSortEnd}
            editGroupName={editGroupName}
            deleteGroup={deleteGroup}
            hasOtherType={hasOtherType}
            pickedTypeUid={pickedTypeUid}
            moveToAnotherType={moveToAnotherType} />;
    }
}

export default SortableComponent