import React from "react";
import _ from "lodash";
import BranchDetailModal from "./BranchDetailModal";
import moment from "moment";

class ShopListTable extends React.Component {
  state = {
    selectedBranch: {}
  };

  getTagsString = tagsObj => {
    let strTags = [];
    let index = 0;
    _.map(tagsObj, (bool, tagName) => {
      strTags.push(
        <span
          class="badge badge-pill badge-primary"
          style={{ marginLeft: index === 0 ? 0 : 5 }}
        >
          {tagName}
        </span>
      );
      index++;
    });
    return strTags;
  };
  selectBranch = branch => {
    this.setState({
      selectedBranch: {
        ...branch,
        timestamp: moment().toISOString() //FORCE STATE TO CHANGE
      }
    });
  };

  render() {
    const { shops, match, fetchShop } = this.props;
    const { selectedBranch } = this.state;
    return (
      <div>
        <BranchDetailModal
          match={match}
          selectedBranch={selectedBranch}
          timestamp={moment().toISOString()}
          fetchShop={fetchShop}
        />
        <table className="table table-sm table-hover table-striped table-bordered">
          <thead>
            <tr>
              <th>รหัสสาขา</th>
              <th>ชื่อร้าน/สาขา</th>
              <th>จังหวัด</th>
              <th>Tags</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {_.map(shops, (shop, i) => {
              return (
                <tr>
                  <td>
                    <span style={{ paddingLeft: 8 }}>{shop.branchId}</span>
                  </td>
                  <td>
                    <span style={{ paddingLeft: 3 }}>{shop.name}</span>
                  </td>
                  <td>
                    <span style={{ paddingLeft: 3 }}>
                      {(shop.province && shop.province.label) || shop.province}
                    </span>
                  </td>
                  <td>
                    <span style={{ paddingLeft: 3 }}>
                      {this.getTagsString(shop.tags)}
                    </span>
                  </td>
                  <td>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#branchDetailModal"
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.selectBranch(shop);
                      }}
                    >
                      View
                    </a>

                    <a
                      href="#"
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.deleteShop(shop);
                      }}
                      style={{ color: "red", paddingLeft: 10 }}
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ShopListTable;
