import logger from "redux-logger";
import ReduxThunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
 
import InitMiddleware from './middleware/init.middleware'
import reducers from './combineReducers'
import PubSub from "pubsub-js";

const store = createStore(
    reducers,
    applyMiddleware(
        ReduxThunk,
        logger,
        InitMiddleware
    )
);

store.dispatch({
    type: "INIT"
})



let lastInventoryState = null
store.subscribe( ()=>{
     
    var inventoryState  =  store.getState().inventory;
    
    if(lastInventoryState !== inventoryState){
         
        lastInventoryState = inventoryState;
        PubSub.publish("INVENTORY_REDUCER", inventoryState);

    }
})


export default store
 