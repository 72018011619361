import React, { useState } from "react";
import TopUpRequest from "./TopUpRequest";
import BalanceSummary from "./BalanceSummary";

function TxContainer(props) {
  const [view, setView] = useState("branch"); //topUpRequest
  const { match, location, shopList } = props;
  // const pathname = location.pathname;
  return (
    <div className="row m-0">
      <div className="col-10 offset-1">
        <div className="card">
          {/* <div className="tab-container">
          <ul className="nav nav-tabs" role="tablist">
            <Tab
              tabLabel="Summary"
              icon="mdi-balance-wallet"
              onChangeTab={() => setView("branch")}
              // active={pathname === `${match.url}/summary`}
              active={view === "branch"}
            />
            <Tab
              tabLabel="TopUp Request"
              icon="mdi-notifications-active"
              active={view === "topUpRequest"}
              onChangeTab={() => setView("topUpRequest")}
            />
          </ul>
        </div> */}
          <div className="tab-content" style={{ padding: 0 }}>
            <div className="tab-pane show active">
              {view === "branch" && <BalanceSummary {...props} />}
              {view === "topUpRequest" && <TopUpRequest {...props} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TxContainer;

const Tab = ({ onChangeTab, tabLabel, icon, active }) => {
  return (
    <li className="nav-item">
      <a
        class={`nav-link show ${active ? "active" : ""}`}
        href={`#`}
        role="tab"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onChangeTab();
        }}
      >
        <span className={`icon mdi ${icon}`} />
        {tabLabel}
      </a>
    </li>
  );
};
