import React, { useState } from "react";
import Pending from "./Pending";

function index() {
  const [count, setCount] = useState(0);
  return (
    <div className="main-content">
      <Navbar />
      <Pending/>
    </div>
  );
}

export default index;

function Navbar() {
  const [count, setCount] = useState(0);
  return (
    <div>
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <a class={`nav-link show`} href="#home5" role="tab">
            <span className="icon mdi mdi-time" />
            Pending
          </a>
        </li>
        <li className="nav-item">
          <a class={`nav-link show`} href="#home5" role="tab">
            <span className="icon mdi mdi-time" />
            Bookings
          </a>
        </li>
        <li className="nav-item">
          <a class={`nav-link show`} href="#home5" role="tab">
            <span className="icon mdi mdi-time" />
            Bills
          </a>
        </li>
      </ul>
    </div>
  );
}
