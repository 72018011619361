import * as type from "../../types/inventoryItems";

import PubSub from "pubsub-js";

const reducer = (state = [], action) => {
  switch (action.type) {
    case type.FETCH_INVENTORY_ITEMS:
      PubSub.publish("MENU_ITEMS", action.payload);
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
