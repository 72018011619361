
import * as type from '../../types/appState'
import _ from 'lodash'
const initData = {
    loading : false
}
const reducer = (state = initData, action) => {
    switch (action.type) {
        case type.APP_STATE_LOADING:
            return { ...state, loading : action.payload }
         
        default:
            return state
    }
}

export default reducer