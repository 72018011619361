import randomString from "random-string";
import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
import moment from "moment";
import async from "async";
import swal from 'sweetalert'
import * as type from '../types/inventoryTypes'
import * as typeShop from '../types/shop'
import * as inventoryGroupActions from './inventoryGroupActions'

export const fetchInventoryType = () => {
    return (dispatch, getState) => {
        const { user, shop } = getState()
        let shopId = user.loggedIn.shopId
        const link = shop.detail.link

        if (link !== undefined) {
            if (link.inventory !== undefined) {
                shopId = link.inventory
                dispatch({
                    type: typeShop.IS_LINK_INVENTORY,
                    payload: shopId || null
                });
            }
        }

        let param = {
            TableName: "Minor_Inventory_Types",
            KeyConditionExpression: "shopId = :shopId",
            ExpressionAttributeValues: {
                ":shopId": shopId
            }
        };
        fetch(param, data => {
            dispatch({
                type: type.FETCH_INVENTORY_TYPE,
                payload: (data && _.sortBy(data.Items, i => i.row)) || []
            });
        });
    };
};



const fetch = (params, cb) => {
    documentClient.query(params, (err, data) => {
        if (err) {
            console.log(err);
            // alert(err)
        } else {
            cb(data);
        }
    });
};


export function addInventoryTypes(items, callBack) {
    return (dispatch, getState) => {
        const { user } = getState()

        async.eachSeries(
            items,
            function (name, cb) {
                let randomUID = randomString({
                    length: 8,
                    numeric: true,
                    letters: true,
                    special: false
                });
                let params = {
                    TableName: "Minor_Inventory_Types",
                    Item: {
                        shopId: user.loggedIn.shopId,
                        uid: randomUID,
                        name: name,
                        row: moment().valueOf()
                    }
                };
                documentClient.put(params, function (err, data) {
                    if (err) {
                        swal("เกิดข้อผิดพลาด", err, "error");
                        return cb(err);
                    } else {
                        cb(null);
                    }
                });
            },
            function (err) {
                if (err) {
                    swal("เกิดข้อผิดพลาด", err, "error");
                } else {
                    dispatch(fetchInventoryType());
                    if (callBack !== undefined) {
                        callBack();
                    }
                }
            }
        );
    };
}

export const reorderInventoryTypes = (groups, callBack) => {
    return (dispatch, getState) => {
        const { user } = getState()

        let index = 1
        async.eachSeries(groups, (group, cb) => {
            let params = {
                TableName: "Minor_Inventory_Types",
                Key: {
                    shopId: user.loggedIn.shopId,
                    uid: group.uid
                },
                UpdateExpression: "set #row = :newRow",
                ExpressionAttributeNames: { "#row": "row" },
                ExpressionAttributeValues: {
                    ":newRow": index
                }
            };
            documentClient.update(params, function (err, data) {
                if (err) {
                    alert(err)
                } else {
                    index++
                    cb()
                }
            });

        }, (err) => {
            if (err) {
                swal("เกิดข้อผิดพลาด", err, "error");
            } else {
                dispatch(
                    fetchInventoryType()
                );
                if (callBack !== undefined) {
                    callBack();
                }
            }
        })



    };
}


export const editTypeName = (typeName, type) => {
    return (dispatch, getState) => {
        const { user } = getState()

        let params = {
            TableName: "Minor_Inventory_Types",
            Key: {
                shopId: user.loggedIn.shopId,
                uid: type.uid
            },
            UpdateExpression: "set #name = :newName",
            ExpressionAttributeNames: { "#name": "name" },
            ExpressionAttributeValues: {
                ":newName": typeName
            }
        };
        documentClient.update(params, function (err, data) {
            if (err) {
                alert(err)
            } else {
                swal("สำเร็จ", 'เปลี่ยนชื่อ Type สำเร็จ', "success");
                dispatch(fetchInventoryType());
            }
        });
    };
}

export const deleteType = (type, cb) => { //หลังจากลบ Type แล้ว Group ทั้งหมดที่อยู่ใน Type จะย้ายไปอยู่ Type other
    return (dispatch, getState) => {

        const { user } = getState()

        let shopId = user.loggedIn.shopId

        const fetchGroup = (typeUid) => {
            let param = {
                TableName: "Minor_Inventory_Groups",
                IndexName: 'shopId-type-index',
                KeyConditionExpression: "shopId = :shopId and #type = :pickedType",
                ExpressionAttributeNames: { "#type": "type" },
                ExpressionAttributeValues: {
                    ":shopId": shopId,
                    ":pickedType": typeUid,
                },
            };
            fetch(param, (data, err) => {
                async.each(data.Items, (group, callback) => {
                    dispatch(inventoryGroupActions.editGroupType(group, callback))
                }, (err) => {
                    if (err) {
                        alert(err)
                        return
                    }
                    dispatch(inventoryGroupActions.fetchInventoryGroup());
                })
            });
        }


        let params = {
            TableName: "Minor_Inventory_Types",
            Key: {
                shopId: user.loggedIn.shopId,
                uid: type.uid
            },
        };
        documentClient.delete(params, function (err, data) {
            if (err) {
                alert(err)
            } else {
                cb && cb()
                fetchGroup(type.uid)
                swal("สำเร็จ", 'ลบกลุ่มสำเร็จ', "success");
                dispatch(fetchInventoryType());
            }
        });


    };
}

