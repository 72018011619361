
import * as type from '../../types/shop'

const reducer = (state = {
    inventory: null
}, action) => {
    switch (action.type) {
        case type.IS_LINK_INVENTORY:
            return { ...state, inventory: action.payload }
        default:
            return state
    }
}

export default reducer