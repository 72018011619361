import React, { Component } from 'react'
// import { bindActionCreators } from 'redux' 
import _ from 'lodash'
import HistoryList from './HistoryList.js'
import PurchaseDetail from './PurchaseDetail'
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux'

class PurchaseHistory extends Component {
    state = {
        currentTX: {}
    }

    componentDidMount() {
        const { purchase } = this.props
        if (_.size(purchase) > 0) {
            this.setState({ currentTX: purchase[0] })
        }
    }



    render() {
        const { match, initLoad } = this.props
         
        if(initLoad){
            return (
            <div className='main-content'>
                Loading...
            </div>
            )
        }
        return (
            <div className='main-content' >
                <Switch>
                    <Route path={`${match.url}/:shopId/:timestamp`} component={PurchaseDetail} />
                    <Route exact path={`${match.url}`} component={HistoryList} />
                    {/* <Route exact path={`/:shopId/:timestamp`} component={PurchaseDetail} /> */}
                    {/* <Route exact path="/" component={HistoryList} /> */}
                </Switch>
                {/* <HistoryList purchase={purchase} selectTX={this.selectTX} currentTX={currentTX} /> */}
                {/* {_.size(purchase) > 0 &&
                    <PurchaseDetail currentTX={currentTX} />
                } */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        initLoad: state.loading.initLoad
    }
}

export default connect(mapStateToProps)(PurchaseHistory)