import React, { Component } from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle
} from "react-sortable-hoc";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import * as inventoryItemActions from "../../shared/actions/inventoryItemActions";
import swal from "@sweetalert/with-react";
import CustomContentModal from "../../shared/components/CustomContentModal";

const DragHandle = SortableHandle(() => (
  <i class="icon icon-left mdi mdi-sort-amount-desc" />
)); // This can be any component you want

class Inventory extends Component {
  state = { modalTitle: "", imgUrl: "" };

  editItem = item => {
    const { actions } = this.props;
    let newItemName = window.prompt("เปลี่ยนชื่อสินค้า", item.name);
    if (newItemName === null || newItemName === undefined) {
      return;
    }
    actions.editItemName(newItemName, item);
  };

  editItemPrice = item => {
    const { actions } = this.props;
    let newItemName = window.prompt("เปลี่ยนราคา", item.price);
    if (newItemName === null || newItemName === undefined) {
      return;
    }

    if (_.isNaN(parseInt(newItemName))) {
      return alert("กรุณาใส่ตัวเลขเท่านั้น");
    }
    actions.editItem("price", parseInt(newItemName) || 0, item);
  };

  editItemUnit = item => {
    const { actions } = this.props;
    let newItemName = window.prompt("เปลี่ยนหน่วยสินค้า", item.unit);
    if (newItemName === null || newItemName === undefined) {
      return;
    }
    actions.editItem("unit", newItemName, item);
  };
  editItemBarCode = item => {
    const { actions } = this.props;
    let newItemName = window.prompt("เปลี่ยน SKU/Barcode", item.barcode || "");
    if (newItemName === null || newItemName === undefined) {
      return;
    }
    actions.editItem("barcode", newItemName, item);
  };

  deleteItem = item => {
    const { actions } = this.props;
    if (window.confirm(`คุณแน่ใจว่าต้องการลบ ${item.name} ?`)) {
      actions.deleteItem(item);
    }
  };

  moveToAnotherGroup = () => {
    const { groups, pickedItemsUid, actions, setForceRender } = this.props;
    let selectedGroupUid = "none";
    swal({
      buttons: ["Cancel", "Move"],
      content: (
        <div>
          <span>Move to Group</span>
          <div style={{ marginTop: 15 }} />
          <select
            class="form-control"
            onChange={e => (selectedGroupUid = e.target.value)}
          >
            <option value="1" selected={selectedGroupUid === "none"}>
              เลือกกลุ่ม
            </option>
            {_.map(groups, group => {
              return (
                <option
                  value={group.uid}
                  selected={group.uid === selectedGroupUid}
                >
                  {group.name}
                </option>
              );
            })}
          </select>
        </div>
      )
    }).then(confirm => {
      if (confirm && selectedGroupUid !== "none") {
        actions.moveItemsToAnotherGroup(
          pickedItemsUid,
          selectedGroupUid,
          () => {
            swal("สำเร็จ!", "เปลี่ยนกลุ่มสำเร็จ", "success");
            setForceRender(true);
          }
        );
      }
    });
  };

  fileChangedHandler = uid => {
    // window.$('#OpenImgUpload' + uid).click(function () {
    window.$("#imgupload" + uid).trigger("click");
    // });
    // e.preventDefault()
    // e.stopPropagation()
  };

  setModalImg = (modalTitle, imgUrl) => {
    this.setState({ modalTitle, imgUrl });
  };

  replaceUrl(imgUrl){
    return  imgUrl.replace('items_thumb','items')
  }

  render() {
    const { modalTitle, imgUrl } = this.state;
    let {
      items,
      actions,
      pickedGroupUid,
      forceRender,
      setForceRender,
      timestamp,
      history,
      match,
      readOnly,
      pickItem,
      pickedItemsUid
    } = this.props;
    let items2 = _.filter(items, item => {
      return item.groupUid === pickedGroupUid;
    });
    return (
      <div class="main-content container-fluid">
        <CustomContentModal title={modalTitle}>
          <center>
            <img src={this.replaceUrl(imgUrl)} width={400} height={400} />
          </center>
        </CustomContentModal>
        <div class="row">
          <div class="col-12 col-md-12 ">
            <div class="card">
              <div class="card-header">
                <div
                  style={{ marginLeft: 5, marginRight: 5 }}
                  class="row justify-content-between"
                >
                  <div>
                    <span style={{ fontSize: 20 }}>Items</span>
                  </div>
                  <div>
                    {readOnly !== true && (
                      <div>
                        <button
                          class="btn btn-space btn-secondary"
                          style={{ marginLeft: 10 }}
                          onClick={() => this.moveToAnotherGroup()}
                          disabled={
                            pickedGroupUid === "none" ||
                            _.isEmpty(pickedItemsUid)
                          }
                        >
                          <i class="icon icon-left mdi mdi-swap" />
                          เปลี่ยนกลุ่ม
                        </button>
                        <button
                          class="btn btn-space btn-primary"
                          style={{ marginLeft: 10 }}
                          onClick={() =>
                            history.push(
                              `${match.url}/addItem/${pickedGroupUid}`
                            )
                          }
                          disabled={pickedGroupUid === "none"}
                        >
                          <i class="icon icon-left mdi mdi-plus-circle-o" />
                          Add Item
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <SortableComponent
                items={items2}
                actions={actions}
                forceRender={forceRender}
                setForceRender={setForceRender}
                timestamp={timestamp}
                editItem={this.editItem}
                editItemPrice={this.editItemPrice}
                editItemUnit={this.editItemUnit}
                deleteItem={this.deleteItem}
                readOnly={readOnly}
                pickItem={pickItem}
                pickedItemsUid={pickedItemsUid}
                fileChangedHandler={this.fileChangedHandler}
                editItemBarCode={this.editItemBarCode}
                setModalImg={this.setModalImg}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class SortableComponent extends Component {
  state = {
    items: [],
    timestamp: 1
  };
  componentWillReceiveProps(nextProps, previous) {
    let { items, timestamp, forceRender, setForceRender } = nextProps;
    if (forceRender === true || timestamp !== this.state.timestamp) {
      setForceRender(false);

      setTimeout(() => {
        this.setState({ items: items, timestamp });
      }, 200);
    }
  }

  componentDidMount() {
    let { items, timestamp } = this.props;
    this.setState({ items: items, timestamp });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    let { actions } = this.props;
    let newItems = arrayMove(this.state.items, oldIndex, newIndex);
    this.setState({
      items: newItems
    });
    actions.reorderInventoryItems(newItems);
  };
  render() {
    const {
      editItem,
      editItemPrice,
      editItemUnit,
      deleteItem,
      readOnly,
      pickItem,
      pickedItemsUid,
      fileChangedHandler,
      actions,
      setForceRender,
      editItemBarCode,
      setModalImg
    } = this.props;
    return (
      <SortableList
        actions={actions}
        useDragHandle={true}
        items={this.state.items}
        onSortEnd={this.onSortEnd}
        editItem={editItem}
        editItemPrice={editItemPrice}
        editItemUnit={editItemUnit}
        deleteItem={deleteItem}
        readOnly={readOnly}
        pickItem={pickItem}
        pickedItemsUid={pickedItemsUid}
        fileChangedHandler={fileChangedHandler}
        setForceRender={setForceRender}
        editItemBarCode={editItemBarCode}
        setModalImg={setModalImg}
      />
    );
  }
}
const SortableList = SortableContainer(
  ({
    items,
    editItem,
    editItemPrice,
    editItemUnit,
    deleteItem,
    readOnly,
    pickItem,
    pickedItemsUid,
    fileChangedHandler,
    actions,
    setForceRender,
    editItemBarCode,
    setModalImg
  }) => {
    return (
      <div class="list-group d-flex">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            index2={index}
            value={value}
            editItem={editItem}
            editItemPrice={editItemPrice}
            editItemUnit={editItemUnit}
            deleteItem={deleteItem}
            readOnly={readOnly}
            pickItem={pickItem}
            pickedItemsUid={pickedItemsUid}
            fileChangedHandler={fileChangedHandler}
            actions={actions}
            setForceRender={setForceRender}
            editItemBarCode={editItemBarCode}
            setModalImg={setModalImg}
          />
        ))}
      </div>
    );
  }
);
const SortableItem = SortableElement(
  ({
    value,
    editItem,
    editItemPrice,
    editItemUnit,
    deleteItem,
    readOnly,
    pickItem,
    pickedItemsUid,
    fileChangedHandler,
    actions,
    setForceRender,
    index2,
    editItemBarCode,
    setModalImg
  }) => {
    let selected = pickedItemsUid[value.uid];
    let image = value.image
      ? value.image.url
      : require("../../shared/image/no_img.jpg");
    return (
      <span
        class={
          "list-group-item  list-group-item-action" +
          (selected ? " active" : "")
        }
        onClick={() => pickItem(value.uid)}
      >
        <div class="row" style={{ marginLeft: 5, marginRight: 5 }}>
          <span style={{ paddingRight: 10 }}>{index2 + 1}.</span>

          {readOnly !== true && [
            <DragHandle />,
            <div class="be-checkbox custom-control custom-checkbox">
              <input
                className="custom-control-input"
                type="checkbox"
                checked={selected}
              />
              <label class="custom-control-label" for="check1" />
            </div>
          ]}
          <div class="col-md-2">
            {/* IMAGE */}
            <img
              src={image}
              key={image}
              width={60}
              height={60}
              onClick={() => {
                setModalImg(value.name, image);
              }}
              data-toggle="modal"
              data-target="#customContentModal"
            />
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <span
                class="hover-underline"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  editItem(value);
                }}
                style={selected ? { color: "white" } : {}}
              >
                {value.name}
              </span>
            </div>
            <div>
              <span
                class="hover-underline mt-3"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  editItemBarCode(value);
                }}
                style={selected ? { color: "white" } : {}}
              >
                {value.barcode ? value.barcode : "[SKU/Barcode]"}
              </span>
            </div>
          </div>
          {/* <div class="col-md-2"><span style={selected ? { color: 'white' } : {}}>Price: {value.price}</span></div> */}
          <div class="col-md-2">
            <div class="mb-3">
              <span
                class="hover-underline"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  editItemPrice(value);
                }}
                style={selected ? { color: "white" } : {}}
              >
                Price: {value.price}
              </span>
            </div>
            <div>
              <span
                class="hover-underline mt-3"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  editItemUnit(value);
                }}
                style={selected ? { color: "white" } : {}}
              >
                Unit: {value.unit}
              </span>
            </div>
          </div>
          <div class="col-md-2">
            {readOnly !== true && (
              <div className="row">
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    fileChangedHandler(value.uid);
                  }}
                >
                  <i class="icon mdi mdi-camera-add" />
                </a>
                <input
                  type="file"
                  id={"imgupload" + value.uid}
                  style={{ display: "none" }}
                  accept="image/png, image/jpeg"
                  onChange={e => {
                    const file = e.target.files[0];
                    // const filePath = e.target.value
                    actions.uploadItemImage(value, file);
                    // if (selected) {
                    pickItem(value.uid, true);
                    // }
                  }}
                />
                {/* <a href="#" onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                editItem(value)
                            }}><i class="icon icon-left mdi mdi-edit"></i></a> */}
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteItem(value);
                  }}
                >
                  <i class="icon mdi mdi-delete" />
                </a>
              </div>
            )}
          </div>
        </div>
      </span>
    );
  }
  // active
);

const mapStateToProps = state => {
  return {
    groups: state.inventory.groups,
    items: state.inventory.items,
    timestamp: state.inventory.timestamp
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...inventoryItemActions }, dispatch);
  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Inventory);
