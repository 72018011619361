import React, { useState } from 'react';
 import Setting_InputList from '@niceloop/niceloop-components/dist/crm_components/Setting_InputList'
import { TextField , Radioboxes , Checkboxes, TextArea, Select} from '@niceloop/niceloop-components/dist/FormInputRow'
function Test() {
const [count, setCount] = useState(0);
return (
<div>
   <Setting_InputList  brandUid="1234qazx" section="wallet_topup"/>


  <hr/>
<div class="container pt-5">
    <h4>TextField</h4>
    <TextField
      label="Email"
      initialValue=""
      // table="Users"
      uid="3180"
      name="email"
      placeHolder="Your Email"
      helperText="Must be valid email"
    />
    <h4>TextField (Int)</h4>
    <TextField
      label="My Balance"
      initialValue=""
      uid="3180"
      name="balance"
      isInteger={true}
      type="number"
    />
    <div class="dropdown-divider" />
    <h4>TextArea</h4>
    <TextArea
      label="Password"
      initialValue=""
      table="Settings"
      uid="3180"
      name="password"
    />
    <div class="dropdown-divider" />
    <h4>Select</h4>
    <Select
      label="City"
      initialValue=""
      table="Settings"
      uid="3180"
      name="city"
      options={[
        { value: "bangkok", label: "Bangkok" },
        { value: "chiangmai", label: "Chiangmai" },
        { value: "nakornsawan", label: "Nakornsawan" }
      ]}
      helperText="Must be valid email"
    />
    <div class="dropdown-divider" />
    <h4>Checkboxes</h4>
    <Checkboxes
      label="Permission"
      initialValue=""
      table="Settings"
      uid="3180"
      name="permission"
      options={[
        { value: "pos", label: "Pos" },
        { value: "inventory", label: "Inventory" },
        { value: "setting", label: "Setting" }
      ]}
      helperText="Must be valid email"
    />
    <div class="dropdown-divider" />
    <h4>Radioboxes</h4>
    <Radioboxes
      label="Gender"
      initialValue=""
      table="Settings"
      uid="3180"
      name="gender"
      options={[
        { value: "male", label: "Male" },
        { value: "female", label: "Female" }
      ]}
      helperText="Must be valid email"
    />
    </div>
 </div>
);
}

export default Test