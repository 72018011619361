import AWS from 'aws-sdk';


const API_KEY = 'AKIAJOXLNF65JP5EFKBQ'
const SECERT_KEY = 'qw0fF6TajLT/RQoWwZ9K30O/66Oj6MSbIIPfnNhE'

const documentClient = new AWS.DynamoDB.DocumentClient({
    region: 'ap-southeast-1',
    endpoint: 'dynamodb.ap-southeast-1.amazonaws.com',
    accessKeyId: API_KEY,
    secretAccessKey: SECERT_KEY
}) 
export const cognitoidentityserviceprovider =
    new AWS.CognitoIdentityServiceProvider(
        {
            apiVersion: '2016-04-18',
            region: 'ap-southeast-1',
            accessKeyId: API_KEY,
            secretAccessKey: SECERT_KEY
        });

export default documentClient;