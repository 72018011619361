import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
// import moment from "moment";
import * as type from "../types/history";
// import async from 'async'
import moment from "moment";
import * as linkedSelector from "../selector/linkedSelector";
import swal from "sweetalert";
import * as appStateAction from "./appStateActions";

const MINOR_INVOICES_TABLE = "Minor_Invoices";
const MAX_PURCHASE_HISTORY_ITEMS_PER_PAGE = 25;

export const clearPurchaseHistory = () => {
  return (dispatch, getState) => {
    dispatch({
      type: type.CLEAR_PURCHASE_HISTORY
    });
  };
};
export const clearPurchaseHistorySearch = () => {
  return (dispatch, getState) => {
    dispatch({
      type: type.CLEAR_PURCHASE_HISTORY_SEARCH
    });
  };
};
export const fetchPurchaseHistory = page => {
  return (dispatch, getState) => {
    const { user, history } = getState();

    let purchaseComplete = history.purchaseComplete;
    let { LastEvaluatedKey, transactions } = purchaseComplete;

    if (transactions[page]) {
      //ใช้ข้อมูลเดิม ไม่ fetch
      dispatch({
        type: type.SWITCH_PAGE_PURCHASE_HISTORY,
        payload: { currentPage: page }
      });
      return;
    }
    let isLinked = linkedSelector.isLinkedSelector(getState()); //CHECK HQ

    let param = {};
    if (isLinked) {
      //CHECK MOTHER
      param = {
        TableName: "Minor_Invoices",
        KeyConditionExpression: "shopId = :shopId",
        ExpressionAttributeValues: {
          ":shopId": user.loggedIn.shopId
        },
        ScanIndexForward: false
      };
    } else {
      param = {
        TableName: "Minor_Invoices",
        IndexName: "hqId-timestamp-index",
        KeyConditionExpression: "hqId = :hqId",
        ExpressionAttributeValues: {
          ":hqId": user.loggedIn.shopId
        },
        ScanIndexForward: false
      };
    }

    //FIXED ZONE-----
    param.ExpressionAttributeNames = { "#status": "status" };
    param.ExpressionAttributeValues[":status200"] = "200";
    param.ExpressionAttributeValues[":status300"] = "300";
    param.FilterExpression = "#status <> :status200 AND #status <> :status300";
    param.Limit = MAX_PURCHASE_HISTORY_ITEMS_PER_PAGE;
    //FIXED ZONE-----

    //LASTEST KEY
    let lastestKey = LastEvaluatedKey;
    if (lastestKey) {
      param.ExclusiveStartKey = lastestKey;
    }
    fetchSpecialWithFullItems(param, data => {
      dispatch({
        type: type.FETCH_PURCHASE_HISTORY,
        payload: {
          currentPage: page || 0,
          ...data,
          LastEvaluatedKey: data.LastEvaluatedKey || undefined
        }
      });
    });
  };
};
export const fetchPurchaseSearchHistory = (page, branchId) => {
  return (dispatch, getState) => {
    const { user, history } = getState();

    let purchaseSearch = history.purchaseSearch;
    let { LastEvaluatedKey, transactions } = purchaseSearch;

    if (!page && !branchId) {
      dispatch({
        type: type.CLEAR_PURCHASE_HISTORY_SEARCH
      });
      return;
    }

    if (transactions[page]) {
      //ใช้ข้อมูลเดิม ไม่ fetch
      dispatch({
        type: type.SWITCH_PAGE_PURCHASE_HISTORY_SEARCH,
        payload: { currentPage: page }
      });
      return;
    }

    let param = {};

    if (branchId) {
      param = {
        TableName: "Minor_Invoices",
        IndexName: "branchId-timestamp-index",
        KeyConditionExpression: "branchId = :branchId",
        ExpressionAttributeValues: {
          ":branchId": branchId
        },
        ScanIndexForward: false
      };
    } else {
      param = {
        TableName: "Minor_Invoices",
        KeyConditionExpression: "hqId = :hqId",
        IndexName: "hqId-timestamp-index",
        ExpressionAttributeValues: {
          ":hqId": user.loggedIn.shopId
        },
        ScanIndexForward: false
      };
    }

    //FIXED ZONE-----
    param.Limit = MAX_PURCHASE_HISTORY_ITEMS_PER_PAGE;
    //FIXED ZONE-----

    //LASTEST KEY
    let lastestKey = LastEvaluatedKey;
    if (lastestKey) {
      param.ExclusiveStartKey = lastestKey;
    }
    fetch(param, data => {
      dispatch({
        type: type.FETCH_PURCHASE_HISTORY_SEARCH,
        payload: {
          currentPage: page || 0,
          ...data,
          LastEvaluatedKey: data.LastEvaluatedKey || undefined
        }
      });
    });
  };
};
export const fetchPurchaseHistoryPending = () => {
  return (dispatch, getState) => {
    const { user } = getState();
    let isLinked = linkedSelector.isLinkedSelector(getState()); //CHECK HQ
    let param = {};
    if (isLinked) {
      param = {
        TableName: "Minor_Stock_Pending",
        IndexName: "shopId-timestamp-index",
        KeyConditionExpression: "shopId = :shopId",
        ExpressionAttributeValues: {
          ":shopId": user.loggedIn.shopId
        },
        ScanIndexForward: false
      };
    } else {
      param = {
        TableName: "Minor_Stock_Pending",
        IndexName: "hqId-timestamp-index",
        KeyConditionExpression: "hqId = :hqId",
        FilterExpression: "ownerApproved <> :status", //ต้อง Approve จาก เจ้าของร้านย่อนก่อน
        ExpressionAttributeValues: {
          ":hqId": user.loggedIn.shopId,
          ":status": false
        },
        ScanIndexForward: false
      };
    }

    fetch(param, data => {
      dispatch({
        type: type.FETCH_PENDING_PURCHASE_HISTORY,
        payload: {
          transactions: (data && data.Items) || [],
          lastUpdate: moment().toISOString()
        }
      });
    });
  };
};

const fetch = (params, cb) => {
  documentClient.query(params, (err, data) => {
    if (err) {
      console.log(err);
      // alert(err)
    } else {
      cb(data);
    }
  });
};

function fetchSpecialWithFullItems(params, cb, accItems = []) {
  documentClient.query(params, (err, data) => {
    if (err) {
      console.log(err);
      // alert(err)
    } else {
      //more items to comes
      if (
        accItems.length + data.Items.length <
          MAX_PURCHASE_HISTORY_ITEMS_PER_PAGE &&
        data.LastEvaluatedKey
      ) {
        //fetch specila again
        params.ExclusiveStartKey = data.LastEvaluatedKey;
        var acc_items = _.concat(accItems, data.Items);

        return fetchSpecialWithFullItems(params, cb, acc_items);
      }

      //enough sum then too many for this loop
      if (
        accItems.length + data.Items.length >=
        MAX_PURCHASE_HISTORY_ITEMS_PER_PAGE
      ) {
        var acc_items = _.concat(accItems, data.Items);
        var acc_items_only_this_slot_loop = _.take(
          acc_items,
          MAX_PURCHASE_HISTORY_ITEMS_PER_PAGE
        );

        var isMoreThanSlot =
          acc_items.length > [MAX_PURCHASE_HISTORY_ITEMS_PER_PAGE];
        if (isMoreThanSlot) {
          var lastItem = _.last(acc_items_only_this_slot_loop);
          data.LastEvaluatedKey = {
            shopId: lastItem.shopId,
            timestamp: lastItem.timestamp,
            hqId: lastItem.hqId
          };
        }
        data.Items = acc_items_only_this_slot_loop;
        return cb(data);
      }

      // this loop has items  but no more data
      if (!data.LastEvaluatedKey) {
        var acc_items = _.concat(accItems, data.Items);
        data.Items = acc_items;
        //exit
        return cb(data);
      }
    }
  });
}

export const updateInvoiceStatus = ({
  tx,
  status,
  cbSuccess,
  cbSuccess2,
  reason
}) => {
  return (dispatch, getState) => {
    const { user, history, shop } = getState();
    const { loggedIn } = user;

    let hqUid = shop.detail.uid;

    dispatch(appStateAction.loading(true));
    let isLinked = linkedSelector.isLinkedSelector(getState()); //CHECK HQ

    let params = {
      TableName: "Minor_Invoices",
      Key: {
        shopId: tx.shopId,
        timestamp: tx.timestamp
      },
      UpdateExpression: "set #status = :newStatus",
      ExpressionAttributeNames: { "#status": "status" },
      ExpressionAttributeValues: {
        ":newStatus": status
      }
    };

    let newStatus = {
      by: {
        name: loggedIn.firstName + " " + loggedIn.lastName,
        shopId: loggedIn.shopId
      },
      status: status,
      reason: reason,
      timestamp: moment().toISOString()
    };

    if (reason) {
      newStatus.reason = reason;
    }

    let statusHistory = [...tx.statusHistory, newStatus];

    params.UpdateExpression += ",statusHistory = :statusHistory";
    params.ExpressionAttributeValues[":statusHistory"] = statusHistory;
    if (isLinked) {
      //หากเป็นสาขาลูก จะทำการ Void หรือ Edit สินค้า ต้องเช็คก่อนว่าสถานะยัง 200 อยู่หรือไม่
      params.ConditionExpression =
        "#status = :intFindStatus OR #status = :strFindStatus OR #status = :editedStatus";
      params.ExpressionAttributeValues[":strFindStatus"] = "200";
      params.ExpressionAttributeValues[":intFindStatus"] = 200;
      params.ExpressionAttributeValues[":editedStatus"] = "300";
    }

    //ADD WALLET TRANSACTION
    if (status === "600" && tx.payment[0].uid === "onAccount") {
      addWalletTranction(
        tx.shopId,
        "ORDER",
        tx.payment[0].amount,
        hqUid,
        `ตัดเงินบิลเลขที่ ${tx.invoiceId}`
      );
    }

    if (
      status === "900" &&
      parseInt(tx.status) <= 600 &&
      tx.payment[0].uid === "onAccount"
    ) {
      addWalletTranction(
        tx.shopId,
        "VOID",
        tx.payment[0].amount,
        hqUid,
        `คืนเงินบิลเลขที่ ${tx.invoiceId}`
      );
    }

    documentClient.update(params, function(err, data) {
      if (err) {
        if (err.code === "ConditionalCheckFailedException") {
          swal(
            "เกิดข้อผิดพลาด",
            "ไม่สามารถทำรายการได้เนื่องจากผู้รับรายการได้ทำการยืนยันแล้ว",
            "error"
          );
        } else {
          console.log(err);
        }
        dispatch(appStateAction.loading(false));
      } else {
        if (cbSuccess2 !== undefined) {
          cbSuccess2();
        }
        if (cbSuccess !== undefined) {
          cbSuccess();
        }
        dispatch(appStateAction.loading(false));

        //FAKE UPDATE
        let tab = history.historyPage.tab;
        if (tab === "completed" || tab === "search") {
          let purchase;
          if (tab === "completed") {
            purchase = history.purchaseComplete;
          } else {
            purchase = history.purchaseSearch;
          }
          const { transactions, currentPage } = purchase;

          let newTx = _.cloneDeep(transactions);
          _.forEach(newTx[currentPage], (data, index) => {
            if (data.shopId === tx.shopId && data.timestamp === tx.timestamp) {
              newTx[currentPage][index] = {
                ...tx,
                status,
                statusHistory
              };
            }
          });
          if (tab === "completed") {
            dispatch({
              type: type.FAKE_UPDATE_TRANSACTION,
              payload: newTx
            });
          } else {
            dispatch({
              type: type.FAKE_UPDATE_TRANSACTION_SEARCH,
              payload: newTx
            });
          }
        }
      }
    });
  };
};

export const updateOwnerApprove = ({ tx, cbSuccess }) => {
  return (dispatch, getState) => {
    const { history } = getState();

    dispatch(appStateAction.loading(true));

    let params = {
      TableName: "Minor_Invoices",
      Key: {
        shopId: tx.shopId,
        timestamp: tx.timestamp
      },
      UpdateExpression: "set ownerApproved = :ownerApproved",
      // ExpressionAttributeNames: { "#status": "status" },
      ExpressionAttributeValues: {
        ":ownerApproved": true
      }
    };

    documentClient.update(params, function(err, data) {
      if (err) {
        console.log(err);
        dispatch(appStateAction.loading(false));
      } else {
        cbSuccess && cbSuccess();
        dispatch(appStateAction.loading(false));

        //FAKE UPDATE
        let tab = history.historyPage.tab;
        if (tab === "completed" || tab === "search") {
          let purchase;
          if (tab === "completed") {
            purchase = history.purchaseComplete;
          } else {
            purchase = history.purchaseSearch;
          }
          const { transactions, currentPage } = purchase;

          let newTx = _.cloneDeep(transactions);
          _.forEach(newTx[currentPage], (data, index) => {
            if (data.shopId === tx.shopId && data.timestamp === tx.timestamp) {
              newTx[currentPage][index] = {
                ...tx,
                ownerApproved: true
              };
            }
          });
          if (tab === "completed") {
            dispatch({
              type: type.FAKE_UPDATE_TRANSACTION,
              payload: newTx
            });
          } else {
            dispatch({
              type: type.FAKE_UPDATE_TRANSACTION_SEARCH,
              payload: newTx
            });
          }
        }
      }
    });
  };
};

export const addWalletTranction = (shopId, type, amount, hqUid, remark, cb) => {
  if (!hqUid) {
    alert("กรุณาระบุ hqId");
    return;
  }

  let convertedAmount = parseInt(amount);

  let newAmount = type === "ORDER" ? -convertedAmount : convertedAmount;
  var params = {
    TableName: "Minor_Wallet_Transactions",
    Item: {
      shopId,
      timestamp: moment().toISOString(),
      type, //TOPUP, ORDER, VOID
      amount: newAmount,
      remark: remark || null,
      hqId: hqUid
    }
  };
  documentClient.put(params, (err, res) => {
    if (err) return console.log(err);
    cb && cb();
  });
};

export const markInvoiceAsRead = invoice => {
  return async (dispatch, getState) => {
    if (invoice.read) {
      return "this invoice is already read";
    }

    var params = {
      TableName: MINOR_INVOICES_TABLE,
      Key: {
        shopId: invoice.shopId,
        timestamp: invoice.timestamp
      },
      UpdateExpression: "set #read = :readTrue",
      ExpressionAttributeNames: { "#read": "read" },
      ExpressionAttributeValues: {
        ":readTrue": true
      }
    };
    await documentClient.update(params).promise();
  };
};

//ex email_sent,  pdf,   excel
export const markCustomFlag = (invoice, flagName) => {
  return async (dispatch, getState) => {
    var params = {
      TableName: MINOR_INVOICES_TABLE,
      Key: {
        shopId: invoice.shopId,
        timestamp: invoice.timestamp
      },
      UpdateExpression: "set #flagName = :true",
      ExpressionAttributeNames: { "#flagName": flagName },
      ExpressionAttributeValues: {
        ":true": true
      }
    };
    await documentClient.update(params).promise();
  };
};
