import { Auth } from "aws-amplify";
import async from "async";
import { fetchInventoryGroup } from "../shared/actions/inventoryGroupActions";
import { fetchInventoryType } from "../shared/actions/inventoryTypeActions";
import { fetchInventory } from "../shared/actions/inventoryItemActions";
import {
  fetchLoggedInUser,
  fetchUserList
} from "../shared/actions/userActions";
import { fetchShopList } from "../shared/actions/shopActions";
import { setInitLoading } from "../shared/actions/loadingAction";
import { listenNotification } from "../shared/actions/notificationActions";
import { fetchEmployee } from "../shared/actions/employeeActions";
import { fetchPromotionList } from "../shared/actions/promotionActions";

const InitMiddleware = store => next => {
  return action => {
    if (action.type === "INIT") {
      //fetch
      Auth.currentAuthenticatedUser()
        .then(function(user) {
          async.waterfall([
            cb => {
              store.dispatch(fetchLoggedInUser(user, cb));
            },
            cb => {
              store.dispatch(fetchUserList());
              // store.dispatch(fetchAllUserList())
              store.dispatch(fetchShopList());
              store.dispatch(fetchInventoryType());
              store.dispatch(fetchInventoryGroup());
              store.dispatch(listenNotification());
              store.dispatch(fetchEmployee());
              store.dispatch(fetchPromotionList());
              store.dispatch(fetchInventory(cb));
            },
            cb => {
              store.dispatch(setInitLoading(false));
            }
          ]);
        })
        .catch(err => {
          // alert(err)
          console.log(err);
        });
    }
    next(action);
  };
};

export default InitMiddleware;
