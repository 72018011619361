import React, { useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import * as posCartActions from "./actions";

function PosCartShoppingCartItemList(props) {
  const [count, setCount] = useState(0);
  const { qtyHolder, items, removeLine } = props;
  return (
    <div class="row">
      <div class="col-lg-12">
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th style={{ width: '5%' }}>Qty</th>

              <th style={{width : '55%'}}>Item</th>
              <th class="number" style={{width : '15%'}}>Price</th>
              <th class="number" style={{width : '15%'}}>Total</th>

              <th class="actions" style={{width : '10%'}} />
            </tr>
          </thead>
          <tbody>
            {_.map(items, (line, index) => {
              return (
                <tr>
                  <td class=" " style={{ width: 48 }}>
                    <b>{qtyHolder[line.uid]}</b>
                  </td>

                  <td>{line.name}</td>
                  <td class="number">{line.price}</td>
                  <td class="number">{qtyHolder[line.uid] * line.price}</td>

                  <td class="actions">
                    <a
                      class="icon"
                      href="#"
                      onClick={() => removeLine(index, line.uid)}
                    >
                      {/* <i class="mdi mdi-minus" /> */}x
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    items: state.items,
    qtyHolder: state.qtyHolder
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    removeLine: (index, uid) => {
      dispatch(posCartActions.removeItemLine(index, uid));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PosCartShoppingCartItemList);
