


export const downloadableLinkUrl = (fileName, url,)=>{

    let link = window.document.createElement("a");
    link.download = fileName;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
}