import React, { Component } from "react";
import { connect } from "react-redux";
import Navbar from "./navbar";
import LeftSideBar from "./leftSideBar";
import MainContent from "./MainContent";
import "react-datasheet/lib/react-datasheet.css";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import BlockUi from "react-block-ui";
import EmployeePinInputModal from "../shared/components/EmployeePinInputModal";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
class Container extends Component {
  componentDidMount = () => {
    // NotificationManager.info('Info message');
  };

  showPinPromptModal = () => {
    const { empList, currentEmp } = this.props;
    if (empList.length < 1) {
      return false;
    } else if (!currentEmp) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { history, currentEmp } = this.props;

    let path = history.location.pathname;
    let containerClassName = "be-wrapper";
    if (
      _.includes(path, "/inventory") ||
      // _.includes(path, "/promotion_retail") ||
      _.includes(path, "/shopping")
    ) {
      containerClassName = "be-wrapper be-aside be-fixed-sidebar";
    }

    return (
      <BlockUi blocking={this.props.isLoading} message="Loading...">
        <div className={containerClassName}>
          <NotificationContainer />
          <Navbar />
          <LeftSideBar history={this.props.history} />
          <div className="be-content">
            <MainContent />
          </div>
        </div>
        {/* {this.showPinPromptModal() && <EmployeePinInputModal />} */}
      </BlockUi>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.appState.loading,
    currentEmp: state.employee.current,
    empList: state.employee.list
  };
};
export default withRouter(connect(mapStateToProps)(Container));
