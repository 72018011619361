import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import ProductSummary from "./ProductSummary";
import ProductSummaryAside from "./ProductSummaryAside";
import { cartSummarySelector } from "../../shared/selector/inventorySelector";
import { bindActionCreators } from "redux";
import * as cartAction from "../../shared/actions/cartActions";
import swal from "sweetalert";
import ProductValidate from "./ProductValidate";
import api from "../../shared/api/index";
class Step2ProductSummary extends Component {
  state = {
    loading: false
  };

  setLoading = loading => {
    this.setState({ loading });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    setTimeout(() => {
      var w = window.$("#fix-right-summary").width();

      window.$("#fix-right-summary").css("position", "fixed");
      window.$("#fix-right-summary").css("top", 86);
      window.$("#fix-right-summary").css("right", 10);
      window.$("#fix-right-summary").css("width", w + 30);

      window.scrollTo(0, 0);
    }, 400);
  }
  onClickPurchase = (remark, paymentType) => {
    const {
      products,
      totalPrice,
      totalQty,
      actions,
      handleClickBackStep
    } = this.props;

    swal({
      title: "ยืนยันการสั่งสินค้า ?",
      icon: "warning",
      buttons: ["Cancel", "Purchase"]
    }).then(confirm => {
      if (confirm) {
        // this.setLoading(true);
        actions.purchase(
          products,
          remark,
          paymentType,
          totalPrice,
          totalQty,
          () => {
            handleClickBackStep();
            swal("สำเร็จ", "สั่งซื้อสินค้าสำเร็จ", "success");
            actions.dontClearCartNextTime(false);
          },
          this.setLoading
        );
      }
    });
  };
  onClickEditInvoice = paymentType => {
    const {
      products,
      totalPrice,
      totalQty,
      actions,
      params,
      history
    } = this.props;

    swal({
      title: `คุณต้องการแก้ไข Invoice เลขที่ \"${params.invoiceId}\" ?`,
      text: "กรอกเหตุผล",
      icon: "warning",
      content: "input",
      buttons: ["Cancel", "Submit"]
    }).then(reason => {
      if (reason) {
        this.setLoading(true);
        actions.editInvoice(
          params,
          products,
          paymentType,
          totalPrice,
          totalQty,
          reason,
          () => {
            history.push(
              `/purchaseHistory/${params.shopId}/${params.timestamp}`
            );
            // history.push(`/purchaseHistory`)
            swal("สำเร็จ", "แก้ไขสินค้าสำเร็จ", "success");
            actions.dontClearCartNextTime(false);
          },
          this.setLoading
        );
      } else {
        swal("ไม่สำเร็จ", "กรุณากรอกเหตุผล", "error");
      }
    });
  };

  render() {
    const {
      products,
      totalPrice,
      totalQty,
      isEdit,
      handleClickBackStep
    } = this.props;
    const { loading } = this.state;

    return (
      <div style={{ padding: 25 }}>
        <div class="row ">
          <div class="col-md-8">
            <ProductValidate />
            <ProductSummary products={products} />
          </div>

          <div class="col-md-4" id="fix-right-summary">
            <ProductSummaryAside
              totalQty={totalQty}
              totalPrice={totalPrice}
              onClickPurchase={this.onClickPurchase}
              isEdit={isEdit}
              loading={loading}
              onClickEditInvoice={this.onClickEditInvoice}
              handleClickBackStep={handleClickBackStep}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const cartSummary = cartSummarySelector(state);

  return {
    products: cartSummary.items,
    totalQty: cartSummary.totalQty,
    totalPrice: cartSummary.totalPrice
  };
};
function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...cartAction }, dispatch);
  return {
    actions
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step2ProductSummary);
