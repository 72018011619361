import React from 'react'
// import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as inventorySelector from '../../shared/selector/inventorySelector'
import _ from 'lodash'
// import documentClient from '../../shared/awsAPIs/dynamoDb'
import Select from 'react-select';
import swal from 'sweetalert'
import api from '../../shared/api'
import PendingShopListModal from './PendingShopListModal'
import moment from 'moment';

class StockSummary extends React.Component {

    state = {
        stock: {},
        groupOptions: [],
        selectedGroup: { value: 'all', label: 'All' },
        groups: [],
        currentPendingList: [],
        currentItemName: ''
    }

    componentDidMount = () => {
        this.fetchInventoryStock()
        this.createGroupOptions()
        this.setGroups('all')
    }

    setGroups = (groupUid) => {
        const { inventory } = this.props
        let groups = []
        if (groupUid === 'all') {
            groups = inventory
        }
        else {
            groups = _.filter(inventory, i => i.uid === groupUid)
        }

        this.setState({ groups })

    }

    createGroupOptions = () => {
        const { inventory } = this.props
        let groupOptions = [{ value: 'all', label: 'All' }]
        _.forEach(inventory, group => {
            if (_.size(group.items) < 1) {
                return
            }
            groupOptions.push({ value: group.uid, label: group.name })
        })
        this.setState({ groupOptions })
    }

    handleChangeBranch = (selectedGroup) => {
        this.setGroups(selectedGroup.value)
        this.setState({ selectedGroup })
    }


    fetchInventoryStock = (isAlert) => {
        const { loggedIn } = this.props
        api.fetchPendingInventory(loggedIn.shopId)
            .then((res) => {
                this.setState({
                    stock: res.data
                })

                if (isAlert) {
                    swal('Reload Success', 'โหลดข้อมูล Stock สำเร็จ', 'success')
                }
            })
    }

    setPendingListForModal = (currentPendingList, currentItemName) => {
        this.setState({ currentPendingList, currentItemName })
    }


    render() {
        // const { inventory } = this.props
        const { stock, selectedGroup, groupOptions, groups, currentPendingList, currentItemName } = this.state

        return (
            <div>
                <PendingShopListModal currentPendingList={currentPendingList} currentItemName={currentItemName} timestamp={moment().toISOString()} />
                <div className="row m-0">
                    <div className="col-md-8 offset-md-2">
                        <div className="row m-0 mb-1 justify-content-between">
                            <div className="col-md-6 p-0">
                                <Select
                                    value={selectedGroup}
                                    onChange={this.handleChangeBranch}
                                    options={groupOptions}
                                />
                            </div>
                            <div>
                                <button class="btn btn-space btn-primary" style={{ height: 35 }}
                                    onClick={() => this.fetchInventoryStock(true)}><i class="icon icon-left mdi mdi-refresh-alt"></i>Reload</button>
                            </div>
                        </div>
                        <table className="table table-sm table-hover table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th >Name</th>
                                    <th >Price</th>
                                    <th >Unit</th>
                                    <th style={{ textAlign: 'right' }}>OnHand</th>
                                    <th style={{ textAlign: 'right' }}>ยอดจองปัจจุบัน<br/>Total Pending</th>
                                    <th style={{ textAlign: 'right' }}>Balance</th>

                                </tr>
                            </thead>
                            <tbody>
                                {_.map(groups, group => {
                                    if (_.size(group.items) < 1) {
                                        return
                                    }
                                    return (
                                        [
                                            <tr key={group.uid} style={{ backgroundColor: 'lightgrey' }}>
                                                <td colSpan={6} class="cell-detail"><span>{group.name}</span></td>
                                            </tr>,
                                            _.map(group.items, item => {
                                                let isUnCutStock = stock[item.uid]
                                                let onHand = (stock[item.uid] && stock[item.uid].onHand) || 0
                                                let totalPending = (stock[item.uid] && stock[item.uid].pending) || 0
                                                let balance = (stock[item.uid] && stock[item.uid].balance) || 0
                                                let pendingList = (stock[item.uid] && stock[item.uid].pendingList) || []


                                                return (
                                                    <tr key={item.uid} >
                                                        <td class="cell-detail"><span>{item.name}</span></td>
                                                        <td class="cell-detail" ><span>{(item.price || '0').toLocaleString() || '0'}</span></td>
                                                        <td class="cell-detail" >{item.unit}</td>
                                                        {
                                                            isUnCutStock ?
                                                                [
                                                                    <td class="cell-detail" ><span style={{ color: 'blue', textAlign: 'right' }}>{onHand.toLocaleString() || '0'}</span></td>,
                                                                    <td class="cell-detail" ><span style={{ color: 'orange', textAlign: 'right' }}>
                                                                        <a href="#"
                                                                            // data-toggle="modal" data-target="#pendingShopListModal"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                e.preventDefault()
                                                                                if (totalPending > 0) {
                                                                                    this.setPendingListForModal(pendingList, item.name)
                                                                                    window.$('#pendingShopListModal').modal('toggle')
                                                                                }
                                                                            }}
                                                                            style={{ color: 'orange' }}>
                                                                            <u>{totalPending.toLocaleString() || '0'}</u>
                                                                        </a>
                                                                    </span></td>,
                                                                    <td class="cell-detail" ><span style={{ color: 'red', textAlign: 'right' }}>{(balance).toLocaleString() || '0'}</span></td>
                                                                ] :
                                                                <td colSpan={3} class="cell-detail" style={{ textAlign: 'center' }}><span>ไม่ตัดสต๊อค</span></td>

                                                        }


                                                    </tr>
                                                )
                                            })
                                        ]
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        inventory: inventorySelector.groupItemSelector(state)
    }
}

export default connect(mapStateToProps)(StockSummary)