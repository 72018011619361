import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import documentClient from "../../shared/awsAPIs/dynamoDb";
import getTxTypeColor from "../../shared/functions/getTxTypeColor";
import WalletTopUpModal from "../../shared/components/WalletTopUpModal";

function BranchWalletTxHistory(props) {
  const [txList, setTxList] = useState([]);
  let storeUid = props.match.params.storeUid;
  let currentBranch = props.branchObj[storeUid];
  const fetchTxList = () => {
    var params = {
      TableName: "Minor_Wallet_Transactions",
      // IndexName: "Index",
      KeyConditionExpression: "shopId = :shopId",
      ExpressionAttributeValues: {
        ":shopId": storeUid
      }
    };
    documentClient.query(params, function(err, data) {
      if (err) console.log(err);
      else {
        setTxList(data.Items);
      }
    });
  };

  useEffect(() => {
    fetchTxList();
  }, []);

  return (
    <div>
      <WalletTopUpModal
        {...props}
        shop={currentBranch}
        fetchTxList={fetchTxList}
      />
      <div className="row m-0">
        <div className="col-10 offset-1">
          <div class="card card-table">
            <div class="card-header row justify-content-between">
              <div>
                <button
                  class="btn btn-space btn-secondary"
                  onClick={() => props.history.goBack()}
                >
                  <span class="mdi mdi-chevron-left"></span>
                  {" Back"}
                </button>
                <span className="pl-3">
                  <b>Wallet Transactions - {currentBranch.name}</b>
                </span>
              </div>
              <div>
                <button
                  class="btn btn-space btn-success"
                  data-toggle="modal"
                  data-target="#walletTopUpModal"
                >
                  TOPUP
                </button>
              </div>
            </div>
            <div class="card-body">
              <table class="table table-striped table-borderless">
                <thead>
                  <tr>
                    <th>วันที่/เวลา</th>
                    <th>รูปแบบ</th>
                    <th>ยอดเงิน</th>
                    <th>หมายเหตุ</th>
                    {/* <th class="actions"></th> */}
                  </tr>
                </thead>
                <tbody class="no-border-x">
                  {/* timestamp */}
                  {_.map(txList, tx => {
                    return (
                      <tr>
                        <td>
                          {moment(tx.timestamp).format("DD MMM YYYY HH:mm:ss")}
                        </td>
                        <td>
                          <span style={{ color: getTxTypeColor(tx.type) }}>
                            {tx.type || ""}
                          </span>
                        </td>
                        <td>
                          {tx.amount > 0 && "+"}
                          {tx.amount.toLocaleString()}
                        </td>
                        <td>{tx.remark}</td>
                        {/* <td class="actions">
                          <a class="icon" href="#">
                            <i class="mdi mdi-delete"></i>
                          </a>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BranchWalletTxHistory;
