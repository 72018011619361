import React, { useState } from "react";
import  Option2Line from './SummaryOptionLine'
import _ from 'lodash'


function Option2_Container(props) {
    const [list, setList] = useState([
      // { value: "", remark: "", mode: 0, name: "", sign: 1 }
    ]);
  
    function addLine() {
      setList([...list, { value: "", remark: "", mode: 0, name: "" }]);
    }
  
    function handleRemove(index) {
      var newList = _.filter(list, (r, i) => i != index);
      setList(newList);
      save(newList)
    }
  
    function save(forceData) {
      props.saveOptions(forceData || list);
    }
  
    function handleEdit(index, key, value) {
      var newList = _.map(list, (l, i) => {
        if (i == index) {
          return { ...l, [key]: value };
        }
        return l;
      });
      setList(newList);
    }
    return (
      <div>
        <div class="card-header card-header pt-0">
          {props.options}  <button class="btn btn-link" onClick={() => addLine()}> Add </button>
          <span class="card-subtitle" onClick={() => addLine()}>
            Options
          </span>
        </div>
        { list.length >=1  && <div class="card-body mt-0">
          <div class="list-group d-flex">
            {_.map(list, (l, i) => {
              return (
                <Option2Line
                  removeLine={handleRemove}
                  index={i}
                  handleEdit={handleEdit}
                  data={l}
                  save={save}
                  allList={list}
                />
              );
            })}
          </div>
        </div>}
      </div>
    );
  }
  

  export default Option2_Container