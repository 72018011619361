import { combineReducers } from 'redux'


import items from './inventoryItemReducer'
import groups from './inventoryGroupReducer'
import types from './inventoryTypeReducer'

import moment from 'moment';


export default combineReducers({
    items,
    groups,
    types,
    timestamp: (state = moment().valueOf(), action) => {
        switch (action.type) {
            case "FETCH_INVENTORY_TYPE":
                return moment().valueOf()
            case "FETCH_INVENTORY_GROUP":
                return moment().valueOf()
            case "FETCH_INVENTORY_ITEMS":
                return moment().valueOf()

            default:
                return state
        }
    }
})
