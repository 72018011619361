import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import TxContainer from "./TxContainer";
import BranchWalletTxHistory from "./BranchWalletTxHistory";
import * as purchaseHistoryActions from "../../shared/actions/purchaseHistoryActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as branchOptionsSelector from "../../shared/selector/branchOptionsSelector";

function Wallet(props) {
  const { match, location, shopList } = props;
  const pathname = location.pathname;

  return (
    <div className="main-content">
      <Switch>
        <Route
          path={`${match.url}/branchWalletTxHistory/:storeUid`}
          render={rProps => <BranchWalletTxHistory {...props} {...rProps} />}
        />
        <Route
          path={`${match.url}`}
          render={rProps => <TxContainer {...props} {...rProps} />}
        />
      </Switch>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  let branchOptions = [{ value: "all", label: "All" }];

  branchOptions = [
    ...branchOptions,
    ...branchOptionsSelector.branchOptionsSelector(state)
  ];
  return {
    branchOptions,
    shopList: state.shop.list.inventory,
    shopDetail: state.shop.detail,
    branchObj: branchOptionsSelector.branchObjSelector(state)
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...purchaseHistoryActions }, dispatch);
  return {
    actions
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wallet));
