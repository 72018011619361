import React, { useState } from "react";
import { connect } from "react-redux";
import { subTotalAmountSelector } from "./selector";
import * as actions from "./actions";
import * as computeSelector from "./selector";
import _ from "lodash";
 import Option2_Container from './SummaryOptionsContainer'
  


const grandTotalStyle ={ 
  border : '1px solid rgba(0,0,0,0.125)',
  borderRadius : 8,
  
  cursor : 'pointer'
};

const grandTotal_hoder_Style ={ 
  border : '1px solid rgba(0,0,0,0.125)',
  cursor : 'pointer',
  color : 'white',
  borderRadius : 8,

  backgroundColor : '#4285f4'

};


function PosCartSummary(props) {
  const [count, setCount] = useState(0);
  const { subTotal, vat, rounding, grandTotal, options, promotions } = props;
  const [ isGrandTotalHover ,  setIsGrandTotalHover] = useState(false);

  return (
    <div class="card card-flat">
       
      <div class="card-header card-header ">
        {subTotal}
        <span class="card-subtitle">Items Price</span>
      </div>
      <div class="card-header card-header pt-0">
        {promotions}
        <span class="card-subtitle">Promotion Discount</span>
      </div>

      <Option2_Container {...props} />

      <div class="card-header card-header pt-0">
        {vat}
        <span class="card-subtitle">Vat (Included)</span>
      </div>

      <div class="card-header card-header pt-0">
        {rounding}
        <span class="card-subtitle">Rounding</span>
      </div>
      <div  onDoubleClick={()=> props.doPayment()  } onMouseEnter={()=>setIsGrandTotalHover(true)}  onMouseLeave={()=>setIsGrandTotalHover(false)}  class="card-header  text-center card-header p-1" style={  isGrandTotalHover ? grandTotal_hoder_Style : grandTotalStyle   }>
        <b>{grandTotal}</b>
        <span class="card-subtitle"  style={isGrandTotalHover ? {color : 'white'}: {}} >GrandTotal</span>
      </div>

      <div class="card-body" />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    subTotal: subTotalAmountSelector(state),
    options: computeSelector.optionsAmountSelector(state),
    promotions: computeSelector.promotionItemsAmountSelector(state),
    vat: computeSelector.vatAmountSelector(state),
    grandTotal: computeSelector.grandTotalSelector(state),
    rounding: computeSelector.roundingAmountSelector(state)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addNewOptionLine: index => {
      dispatch(actions.optionsInsert());
    },

    editOptionLine(index, name, value, sign) {
      dispatch(actions.optionsEdit(index, name, value, sign));
    },

    removeOptionLine(index) {
      dispatch(actions.optionsRemove(index));
    },

    saveOptions(data) {
      dispatch(actions.optionsSave(data));
    },

    doPayment(){
      dispatch(actions.payment())
      dispatch(actions.clear())
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PosCartSummary);
