import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SortableGroupAndTypeList from './SortableGroupAndTypeList'
import moment from 'moment'
import * as inventoryGroupActions from '../../shared/actions/inventoryGroupActions'
import * as inventoryTypeActions from '../../shared/actions/inventoryTypeActions'
import * as cartHqActions from '../../shared/actions/cartHqActions'
import swal from 'sweetalert'

class ManageTypeModal extends React.Component {
    state = {
        username: '',
        password: '',
        rePassword: '',
        email: ''
    };

    render() {
        // const { username, password, rePassword, email } = this.state
        const { history, match, types, actions } = this.props

        return (
            <div class="modal" id="manageTypeModal" style={{ perspective: 'none' }}>
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h3 class="modal-title">Manage Type</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span class="mdi mdi-close"></span></button>
                    </div>
                    <div class="modal-body form">
                        <div className='row m-0'>
                            <div className="col-md-8 offset-md-2">
                               
                                <button className="btn btn-space btn-primary"
                                    onClick={() => {
                                        window.$('#manageTypeModal').modal('hide')
                                        history.push(`${match.url}/addType`)
                                    }}
                                    style={{ marginTop: 10, width: '100%' }} >
                                    <i className="icon icon-left mdi mdi-plus-circle-o"></i>Add Type</button>
                                <SortableGroupAndTypeList
                                    // readOnly={readOnly}
                                    // pickedGroupUid={pickedTypeUid}
                                    // handleClickGroup={handleClickType}
                                    handleClickGroup={() => { }}
                                    mode="type"
                                    groups={types}
                                    actions={actions}
                                    timestamp={moment().toISOString()}
                                    editGroupName={(type) => this.editTypeName(type)}
                                    deleteGroup={(group) => this.deleteType(group, true)}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                    </div> */}
                </div>
            </div>
        );
    }
    deleteType = (type) => {
        const { actions } = this.props

        swal({
            title: `${'คุณต้องการลบ Type '} \"${type.name}\" ?`,
            text: 'กรุณากรอกชื่อ Type ที่ต้องการลบ / กลุ่มทั้งหมดใน Type จะย้ายไปอยู่ Type Other',
            icon: "warning",
            dangerMode: true,
            content: "input",
            buttons: ["Cancel", "Submit"],
        })
            .then((reason) => {
                if (reason) {
                    if (reason !== type.name) {
                        return
                    }
                    actions.deleteType(type, () => { })
                }
            });
    }
    editTypeName = (type) => {
        const { actions } = this.props
        let newName = window.prompt('เปลี่ยนชื่อ Type', type.name)
        if (newName === null || newName === undefined) {
            return
        }

        actions.editTypeName(newName, type)

    }
}

const mapStateToProps = (state) => {
    return {
        loggedInUser: state.user.loggedIn,
        types: state.inventory.types,

    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ ...inventoryGroupActions, ...inventoryTypeActions, ...cartHqActions }, dispatch)

    return {
        actions,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageTypeModal)