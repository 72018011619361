import React, { useState } from "react";
import swal from "sweetalert";
import _ from "lodash";

function WalletTopUpModal(props) {
  const [amount, setAmount] = useState("");
  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState(false);

  const { shop } = props;

  const topUp = () => {
    if (amount === "" || amount === "0") {
      swal("กรุณากรอกจำนวนเงิน", "", "error");
      return;
    }

    let convertedAmount = parseInt(amount);

    if (_.isNaN(convertedAmount)) {
      swal("กรุณากรอกจำนวนเงินเ็นตัวเลขเท่านั้น", "", "error");
      return;
    }

    try {
      setLoading(true);
      props.actions.addWalletTranction(
        shop.uid,
        "TOPUP",
        convertedAmount,
        props.shopDetail.uid, //HQ UID
        remark !== "" ? remark : null,
        () => {
          props.fetchTxList();
          swal("สำเร็จ !", "", "success").then(() => {
            window.$("#walletTopUpModal").modal("hide");
          });
          setAmount("");
          setRemark("");
          setLoading(false);
        }
      );
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div class="modal" id="walletTopUpModal">
      <div class="modal-content" style={{ width: 400 }}>
        <div class="modal-header">
          <h3 class="modal-title" id="walletTopUpModalLabel">
            TOPUP - {shop.name}
          </h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body form">
          <div class="form-group pt-2">
            <label>จำนวนเงิน</label>
            <input
              class="form-control"
              value={amount}
              name="name"
              type="number"
              onChange={e => setAmount(e.target.value)}
            />
          </div>
          <div class="form-group pt-2">
            <label>หมายเหตุ</label>
            <input
              class="form-control"
              value={remark}
              name="name"
              onChange={e => setRemark(e.target.value)}
            />
          </div>
        </div>
        <div class="modal-footer pt-2">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            CLOSE
          </button>
          <button
            type="button"
            class="btn btn-success"
            onClick={topUp}
            disabled={loading}
          >
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  );
}

export default WalletTopUpModal;
