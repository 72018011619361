import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import moment from "moment";
import swal from "sweetalert";
import EmployeeLockBtn from "../shared/components/EmployeeLockBtn";

class Navbar extends Component {
  signOut = e => {
    e.preventDefault();
    swal({
      title: `Sign Out ?`,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        Auth.signOut();
        window.location.href = "/";
      }
    });
  };
  render() {
    const { user, shop, hqDetail, actions } = this.props;
    let logoUrl = shop.logoUrl || hqDetail.logoUrl;
    return (
      <nav className="navbar navbar-expand fixed-top be-top-header">
        <div className="container-fluid">
          <div className="be-navbar-header">
            {logoUrl ? (
              <img
                src={logoUrl}
                alt="Avatar"
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
                className="center"
                height={60}
              />
            ) : (
              <div />
            )}

            {/* <a className="navbar-brand" href="index.html"></a> */}
          </div>
          <div className="be-right-navbar">
            <ul className="nav navbar-nav float-right be-user-nav align-items-baseline">
              {/* <li className="nav-item align-self-center  show">
                <div class='text-right'>Usename</div>
                <div  style={{color : 'gray', fontSize : '0.85em'}}>firstname LastName</div>
              </li> */}
              <li className="nav-item align-self-center  show">
                <div class="text-right">
                  <strong>{(user && user.username) || ""}</strong>
                </div>
              </li>
              <li className="nav-item align-self-center  show ml-1">
                <div class="text-right">
                  {(user && user.firstName) || ""}{" "}
                  {(user && user.lastName) || ""}
                </div>
              </li>
              <li
                style={{ marginRight: 8, marginLeft: 8 }}
                className="ml-3 nav-item dropdown show"
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  data-toggle="dropdown"
                  role="button"
                  aria-expanded="true"
                >
                  <img
                    src={user.profileImg || `assets/img/avatar.png`} //TODO: ใส่รูปจริง
                    alt="Avatar"
                    key={moment().toISOString() + "1"}
                  />

                  <span
                    className="user-name"
                    style={{ textTransform: "uppercase" }}
                  >
                    .....
                  </span>
                </a>
              </li>
              {/* <li
                className="nav-item  ml-3 show"
                style={{ cursor: "pointer" }}
                style={{ marginRight: 12 }}
              >
                <EmployeeLockBtn /> 
              </li> */}
              <li
                className="nav-item  ml-3 show"
                style={{ cursor: "pointer" }}
                onClick={e => this.signOut(e)}
                style={{ marginRight: 12 }}
              >
                <span className="icon mdi mdi-power" /> Logout
              </li>
            </ul>
            <div className="page-title">
              <span>
                {shop.name || "Loading..."} - {shop.branchId || ""}
              </span>
            </div>
            {/* PASTE BACK HERE */}
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.loggedIn,
    shop: state.shop.detail,
    hqDetail: state.shop.hqDetail
  };
};

// function mapDispatchToProps(dispatch) {
//   let actions = bindActionCreators({ ...employeeActions }, dispatch);
//   return {
//     actions
//   };
// }

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Navbar);
