import React, { Component } from "react";
import Inventory from "../pages/inventory";
import PurchaseHistory from "../pages/PurchaseHistory";
import Setting from "../pages/Setting";
import { Route, Switch } from "react-router-dom";
import Shopping from "../pages/Shopping";
import ManageUser from "../pages/ManageUser";
import ManageShop from "../pages/ManageShop";
import ManageStock from "../pages/ManageStock";
import PosCart from "../pages/PosCart";
import Employee from "../pages/Employee";
import PromotionRetail from "../pages/PromotionRetail";
import Report from '../pages/Report'
import Wallet from "../pages/Wallet";  //chagen to this line becasue this always bugs
import Test from "../pages/Test";
import SpaContainer from '../pages/Spa/index'
class MainContent extends Component {
  render() {
    return (
      <Switch>
        <Route path="/setting" component={Setting} />
        <Route path="/manageStock" component={ManageStock} />
        <Route path="/manageUser" component={ManageUser} />
        <Route path="/manageShop" component={ManageShop} />
        <Route
          path="/shopping/edit/:invoiceId/:shopId/:timestamp"
          component={Shopping}
        />

        <Route path="/spa" component={SpaContainer} />
        <Route path="/spa_pos" component={SpaContainer} />


        <Route path="/shopping" component={Shopping} />
        <Route path="/inventory" component={Inventory} />
        <Route path="/wallet" component={Wallet} />
        <Route path="/purchaseHistory" component={PurchaseHistory} />
        <Route path="/pos_retail" component={PosCart} />
        <Route path="/promotion_retail" component={PromotionRetail} />
        <Route path="/report" component={Report} />
        <Route path="/employee" component={Employee} />
        <Route path="/test" component={Test} />

        <Route exact path="/" component={PurchaseHistory} />
        {/* <Route path={'/'} render={(props) => <Home {...props} mood={8} />} /> */}
      </Switch>
    );
  }
}

export default MainContent;
