import _ from "lodash";
import documentClient from "../awsAPIs/dynamoDb";
import swal from "sweetalert";
import * as type from "../types/user";
import { fetchShopDetail } from "../actions/shopActions";
import { Auth } from "aws-amplify";

const signOut = () => {
  Auth.signOut();
  window.location.href = "/";
};

export const findUser = (username, cb) => {
  // for test
  return (dispatch, getState) => {
    let param = {
      TableName: "Minor_Users",
      Key: {
        username: username
      }
    };
    fetchGet(param, data => {
      cb();
    });
  };
};

export const fetchLoggedInUser = (user, cb) => {
  return (dispatch, getState) => {
    let param = {
      TableName: "Minor_Users",
      Key: {
        username: user.username
      }
    };
    fetchGet(param, data => {
      if (_.isEmpty(data)) {
        swal("เกิดข้อผิดพลาด", "ไม่พบข้อมูลบัญชีของคุณ", "error").then(() => {
          signOut();
        });
        return;
      }

      dispatch({
        type: type.FETCH_LOGGEDIN_USER,
        payload: (data && data.Item) || {}
      });
      dispatch(fetchShopDetail(data.Item.shopId, cb));
      // cb && cb();
    });
  };
};

const fetchGet = (params, cb) => {
  documentClient.get(params, (err, data) => {
    if (err) {
      console.log(err);
      // alert(err)
    } else {
      cb(data);
    }
  });
};

export const fetchUserList = () => {
  return (dispatch, getState) => {
    const { user } = getState();

    let param = {
      TableName: "Minor_Users",
      IndexName: "shopId-index",
      KeyConditionExpression: "shopId = :shopId",
      ExpressionAttributeValues: {
        ":shopId": user.loggedIn.shopId
      }
    };
    fetch(param, data => {
      dispatch({
        type: type.FETCH_USER_LIST,
        payload: (data && _.sortBy(data.Items, i => i.username)) || []
      });
    });
  };
};

// export const fetchAllUserList = () => {
//     return (dispatch, getState) => {
//         const { user } = getState()

//         let param = {
//             TableName: "Minor_Users",
//             IndexName: 'shopId-index',
//             KeyConditionExpression: "shopId = :shopId",
//             ExpressionAttributeValues: {
//                 ":shopId": user.loggedIn.shopId
//             }
//         };

//         fetch(param, data => {
//             dispatch({
//                 type: type.FETCH_USER_LIST,
//                 payload: (data && _.sortBy(data.Items, i => i.username)) || []
//             });
//         });
//     };
// };
const fetch = (params, cb) => {
  documentClient.query(params, (err, data) => {
    if (err) {
      console.log(err);
      // alert(err)
    } else {
      cb(data);
    }
  });
};

export const deleteUser = (username, cb) => {
  return (dispatch, getState) => {
    const { user } = getState();
    let params = {
      TableName: "Minor_Users",
      Key: {
        username: username
      }
    };
    documentClient.delete(params, function(err, data) {
      if (err) {
        alert(err);
        return;
      }
      cb && cb();
      dispatch(fetchUserList());
    });
  };
};

export const editUserDetail = (user, cb) => {
  return (dispatch, getState) => {
    const loggedIn = getState().user.loggedIn;
    let params = {
      TableName: "Minor_Users",
      Key: {
        username: user.username
      },
      UpdateExpression:
        "set firstName = :newFirstName,lastName = :newLastName, #email = :newEmail ,gender = :newGender, ownerApproved = :ownerApproved",
      ExpressionAttributeNames: {
        "#email": "email"
      },
      ExpressionAttributeValues: {
        ":newFirstName": user.firstName,
        ":newLastName": user.lastName,
        ":newEmail": user.email,
        ":newGender": user.gender,
        ":ownerApproved": user.ownerApproved
      }
    };

    if (user.profileImg) {
      params.UpdateExpression += ", profileImg = :newProfileImg";
      params.ExpressionAttributeValues[":newProfileImg"] = user.profileImg;
    }

    documentClient.update(params, function(err, data) {
      if (err) {
        alert(err);
      } else {
        cb && cb();
        dispatch(fetchUserList());
        dispatch(fetchLoggedInUser(loggedIn));
      }
    });
  };
};
