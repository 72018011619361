import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import swal from "sweetalert";
import _ from "lodash";
import * as employeeActions from "../actions/employeeActions";
const initData = {
  name: "",
  oldPin: "",
  pin: "",
  permission: {},
  mode: "create",
  uid: ""
};
class EmployeeInfoModal extends React.Component {
  state = initData;

  componentWillReceiveProps(nextProps) {
    const { currentEmp, mode } = nextProps;
    if (mode === "create") {
      this.setState({
        ...initData
      });
      return;
    } else {
      this.setState({
        ...currentEmp,
        oldPin: currentEmp.pin,
        mode: "edit"
      });
      return;
    }
  }

  handleOnChange = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  createEmployee = () => {
    const { empList, actions } = this.props;
    const { pin, name } = this.state;
    if (name === "" || pin === "") {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
      return;
    }
    let foundEmp = _.find(empList, i => i.pin === pin);

    if (foundEmp) {
      swal("เกิดข้อผิดพลาด", "PIN นี้ถูกใช้แล้ว", "error");
      return;
    }

    actions.createEmployee(this.state, () => {
      swal("สำเร็จ", "เพิ่ม Employee สำเร็จ", "success").then(() => {
        window.$("#employeeInfoModal").modal("hide");
      });
    });
  };

  editEmployee = () => {
    const { empList, actions } = this.props;
    const { oldPin, pin, name } = this.state;
    let foundEmp = _.find(empList, i => i.pin === pin);
    if (name === "" || pin === "") {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกข้อมูลให้ครบถ้วน", "error");
      return;
    }
    if (oldPin !== pin && foundEmp) {
      swal("เกิดข้อผิดพลาด", "PIN นี้ถูกใช้แล้ว", "error");
      return;
    }

    actions.editEmployee(this.state, () => {
      swal("สำเร็จ", "แก้ไขข้อมูล Employee สำเร็จ", "success").then(() => {
        window.$("#employeeInfoModal").modal("hide");
      });
    });
  };

  setPermission = name => {
    let newPermission = _.cloneDeep(this.state.permission);
    if (newPermission[name]) {
      delete newPermission[name];
    } else {
      newPermission[name] = true;
    }
    this.setState({
      permission: newPermission
    });
  };
  render() {
    const { name, pin, permission, mode } = this.state;

    return (
      <div class="modal" id="employeeInfoModal">
        <div class="modal-content" style={{ width: 400 }}> 
          <div class="modal-header">
            <div>
              <span style={{ fontSize: 25 }}>
                <u>Employee's Information</u>
              </span>
            </div>
            <button
              class="close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close" />
            </button>
          </div>
          <div class="modal-body form" style={{ padding: 10 }}>
            <div class="form-group pt-2">
              <label>Name</label>
              <input
                class="form-control"
                value={name}
                name="name"
                onChange={this.handleOnChange}
              />
            </div>
            <div class="form-group pt-2">
              <label>PIN</label>
              <input
                class="form-control"
                value={pin}
                name="pin"
                onChange={this.handleOnChange}
                type="password"
              />
            </div>
            <div class="pt-2">
              <div
                class="custom-control custom-checkbox"
                onClick={() => this.setPermission("all")}
              >
                <input
                  class="custom-control-input"
                  type="checkbox"
                  checked={permission.all === true}
                />
                <label class="custom-control-label">All</label>
              </div>
              <div
                class="custom-control custom-checkbox"
                onClick={() => this.setPermission("voidBills")}
              >
                <input
                  class="custom-control-input"
                  type="checkbox"
                  checked={permission.voidBills === true}
                />
                <label class="custom-control-label">Void Bills</label>
              </div>
              <div
                class="custom-control custom-checkbox"
                onClick={() => this.setPermission("discount")}
              >
                <input
                  class="custom-control-input"
                  type="checkbox"
                  checked={permission.discount === true}
                />
                <label class="custom-control-label">Discount</label>
              </div>
              <div
                class="custom-control custom-checkbox"
                onClick={() => this.setPermission("changePrice")}
              >
                <input
                  class="custom-control-input"
                  type="checkbox"
                  checked={permission.changePrice === true}
                />
                <label class="custom-control-label">Change Price</label>
              </div>
            </div>
          </div>
          <div class="modal-footer p-0">
            <button
              class="btn btn-space btn-light m-0"
              type="button"
              style={{
                width: "50%",
                borderRadius: 0,
                height: 50,
                backgroundColor: "lightgrey",
                border: "none"
              }}
              onClick={this.submit}
            >
              Cancel
            </button>

            {mode === "create" ? (
              <button
                class={`btn btn-space btn-success m-0`}
                type="button"
                style={{ width: "50%", borderRadius: 0, height: 50 }}
                onClick={this.createEmployee}
              >
                Create
              </button>
            ) : (
              <button
                class={`btn btn-space btn-warning m-0`}
                type="button"
                style={{ width: "50%", borderRadius: 0, height: 50 }}
                onClick={this.editEmployee}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    empList: state.employee.list
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...employeeActions }, dispatch);

  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeInfoModal);
