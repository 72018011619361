import React from "react";
import _ from "lodash";
import swal from "sweetalert";

class PromotionDetail extends React.Component {
  setDiscountLineValue = (line, e, index) => {
    const { setDicountLine } = this.props;
    let data = _.cloneDeep(line);
    let value = parseInt(e.target.value);
    if (e.target.value === "") {
      data.value = "";
    } else if (_.isNaN(value) && value !== 0) {
      return;
    } else {
      data.value = value;
    }
    setDicountLine(data, index);
  };
  setDiscountLineMode = (line, e, index) => {
    const { setDicountLine } = this.props;
    let data = _.cloneDeep(line);
    data.mode = e.target.value;
    setDicountLine(data, index);
  };
  backPage = () => {
    const { history } = this.props;
    swal({
      title: `ย้อนกลับ ?`,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        history.goBack();
      }
    });
  };

  render() {
    const {
      name,
      type,
      qty,
      description,
      handleOnchange,
      handleChangeType,
      handleChangeQty,
      discountList,
      printDisplay,
      createPromotion,
      history,
      mode,
      editPromotion
    } = this.props;
    return (
      <div className="card">
        <div style={{ padding: 20 }}>
          <div className="form-group row">
            <label class="col-3 col-lg-3 col-form-label text-right">
              ชื่อโปรโมชัน
              {/* <span style={{ color: "red" }}>*</span> */}
            </label>
            <div class="col-9 col-lg-9">
              <input
                className="form-control"
                type="text"
                placeholder="ชื่อโปรโมชัน"
                value={name}
                name="name"
                onChange={handleOnchange}
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-3 col-lg-3col-form-label text-sm-right">
              รายละเอียด
            </label>
            <div class="col-9 col-lg-9 col-lg-6">
              <textarea
                class="form-control"
                value={description}
                name="description"
                onChange={handleOnchange}
                placeholder="รายละเอียดโปรโมชัน"
              />
            </div>
          </div>
          <div className="form-group row">
            <label class="col-3 col-lg-3 col-form-label text-right">
              Print Display
            </label>
            <div class="col-9 col-lg-9">
              <input
                className="form-control"
                type="text"
                placeholder="(แสดงที่ใบเสร็จ)"
                value={printDisplay}
                name="printDisplay"
                onChange={handleOnchange}
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-3 col-lg-3 col-form-label text-sm-right">
              Type
            </label>
            <div class="col-9 col-lg-9 form-check mt-1">
              <label class="custom-control custom-radio custom-control-inline">
                <input
                  class="custom-control-input"
                  type="radio"
                  onClick={e => handleChangeType("qty")}
                  checked={type === "qty"}
                />
                <span class="custom-control-label">Quantity</span>
              </label>
              <label class="custom-control custom-radio custom-control-inline">
                <input
                  class="custom-control-input"
                  type="radio"
                  name="radio-inline"
                  onClick={e => handleChangeType("amount")}
                  checked={type === "amount"}
                />
                <span class="custom-control-label">Amount</span>
              </label>
            </div>
          </div>
          <div className="form-group row pt-0">
            <label class="col-3 col-lg-3 col-form-label text-right">
              จำนวน
            </label>
            <div class="col-9 col-lg-9">
              <select
                class="form-control"
                value={qty}
                onChange={e => handleChangeQty(e.target.value)}
              >
                {_.times(20, i => {
                  return (
                    <option value={i + 1} selected={qty === i + 1}>
                      {i + 1}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="card-header-divider m-0" />
          <div className="mt-6" />
          {_.map(discountList, (line, i) => {
            return (
              <div className={`row m-0 ${i !== 0 ? "pt-3" : ""}`}>
                <div className="col-md-4 align-self-center pl-0">
                  <span>ส่วนลด</span>
                  <span className="pl-3">ชิ้นที่ {i + 1}</span>
                </div>
                <div className="col-md-4">
                  <input
                    class="form-control"
                    type="email"
                    placeholder="Value"
                    value={line.value}
                    onChange={e => this.setDiscountLineValue(line, e, i)}
                  />
                </div>
                <div className="col-md-4 pr-0">
                  <select
                    class="form-control"
                    value={line.mode}
                    onChange={e => this.setDiscountLineMode(line, e, i)}
                  >
                    <option value={0} selected={line.mode === 0}>
                      Discount %
                    </option>
                    <option value={1} selected={line.mode === 1}>
                      Discount THB
                    </option>
                    <option value={2} selected={line.mode === 2}>
                      Fixed Price At %
                    </option>
                    <option value={3} selected={line.mode === 3}>
                      Fixed Price THB
                    </option>
                  </select>
                </div>
              </div>
            );
          })}
          <div className="row m-0 justify-content-between">
            <div />
            <div>
              <button
                class="btn btn-space btn-secondary mt-4 pr-2"
                onClick={() => this.backPage()}
                style={{ width: 100 }}
              >
                CANCEL
              </button>
              {mode === "add" ? (
                <button
                  class="btn btn-space btn-success mt-4"
                  onClick={() =>
                    createPromotion(() => {
                      history.goBack();
                    })
                  }
                  style={{ width: 100 }}
                >
                  SUBMIT
                </button>
              ) : (
                <button
                  class="btn btn-space btn-warning mt-4"
                  onClick={() =>
                    editPromotion(() => {
                      history.goBack();
                    })
                  }
                  style={{ width: 100 }}
                >
                  EDIT
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PromotionDetail;
