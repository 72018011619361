import React from 'react'
import _ from 'lodash'
import moment from 'moment';

class PendingShopListModal extends React.Component {
    state = {
        shopList: []
    }
    // componentWillReceiveProps(nextProps) {
    //     // const { isEdit, selectedUser } = nextProps

    //     this.setState({
    //         // firstName: selectedUser.firstName,
    //     })

    // }

    render() {
        const { currentPendingList, currentItemName } = this.props
        return (
            <div class="modal" id="pendingShopListModal" style={{ perspective: 'none' }} >
                <div class="modal-content" >
                    <div class="modal-header modal-header-colored">
                        <h3 class="modal-title">{currentItemName}</h3>
                        <button class="close modal-close" type="button" class="close" data-dismiss="modal" aria-label="Close" aria-hidden="true"><span class="mdi mdi-close"></span></button>
                    </div>
                    <div class="modal-body form" style={{ padding: 10 }}>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th >Invoice ID</th>
                                    <th >วันที่/เวลา</th>
                                    <th >ชื่อร้าน/สาขา</th>
                                    <th class="number">จำนวน</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _.map(currentPendingList, line => {
                                        return (
                                            <tr>
                                                <td>{line.invoiceId}</td>
                                                <td>{moment(line.timestamp).format('HH:mm DD/MMM/YYYY')}</td>
                                                <td>{line.shopName}</td>
                                                <td class="number">{line.amount}</td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>


                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary modal-close" type="button" onClick={() => window.$('#pendingShopListModal').modal('hide')}>Close</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default PendingShopListModal