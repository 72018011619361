import React, { useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import * as posCartActions from "./actions";

function ShoppingCartProductListConatiner(props) {
  const [department, setDepartment] = useState(null);
  const [groupsList, setGroupsList] = useState([]);
  const [items, setItemsList] = useState([]);

  function filterGroups(evt) {
    var typeUidToFind = evt.target.value;

    var res = _.filter(props.groups, g => g.type === typeUidToFind);

    var sorted = _.sortBy(res, g => g.row);
    setGroupsList(sorted);
  }

  function filterItems(groupUid) {
    var res = _.filter(props.items, line => line.groupUid === groupUid);

    var sorted = _.sortBy(res, g => g.row);
    setItemsList(sorted);
  }

  function addItem(item){
    props.dispatch(posCartActions.addItem(item, 1));
      
  }

  const { qtyHolder } = props;
  return (
    <div class="row">
      <div class="col-lg-4">
        <h5>Departments</h5>
        <select class='form-control' value={department} onChange={filterGroups}>
          <option disabled={true} selected={true} value={-1}>
            Select Department
          </option>
          {_.map(props.types, (t, i) => {
            return <option value={t.uid}>{t.name}</option>;
          })}
        </select>
        <h5>Groups</h5>
        {_.map(groupsList, g => (
          <Line {...g} onClick={() => filterItems(g.uid)} qtyHolder={qtyHolder}/>
        ))}
      </div>
      <div class="col-lg-8">
        <h5>Items</h5>
        {_.map(items, line => (
          <Line {...line} onClick={ ()=>{addItem(line)} }  qtyHolder={qtyHolder}/>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.inventoryReducer,
    qtyHolder : state.qtyHolder
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingCartProductListConatiner);

function Line({ onClick = ()=>{}, name, unit, uid  , barcode, price, qtyHolder }) {
  return (
    <a class="list-group-item d-flex list-group-item-action"   onClick={onClick}>
        {qtyHolder[uid] && <span style={{fontWeight : 'bold', color : 'blue' , marginRight : 4}}>{qtyHolder[uid]} X</span>}
      <span class="text">{name} <span style={{color : 'gray', marginLeft : 8, fontWeight : '100'}}>{barcode}</span></span>
      <span class="badge badge-pill badge-primary">{price}</span>
    </a>
  );
}
