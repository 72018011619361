import React, { Component, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import * as cartAction from "../../shared/actions/cartActions";
import {
  groupItemSelector,
  cartSummarySelector
} from "../../shared/selector/inventorySelector";
import swal from "sweetalert";
import CustomContentModal from "../../shared/components/CustomContentModal";

class ProductPicker extends Component {
  state = { modalTitle: "", imgUrl: "" };

  handleOnChange = (itemUid, e) => {
    const { actions } = this.props;
    if (e.target.value === "") {
      actions.deleteCartProduct(itemUid);
    }
    let qty = parseInt(e.target.value);
    if (_.isNaN(parseInt(qty))) {
      return;
    }
    actions.typeProductQty(itemUid, qty);
  };
  addProductQty = itemUid => {
    const { actions } = this.props;
    actions.addProductQty(itemUid);
  };
  decreaseProductQty = itemUid => {
    const { actions } = this.props;
    actions.decreaseProductQty(itemUid);
  };
  clearCart = () => {
    const { actions } = this.props;
    swal({
      title: "คุณต้องการ Clear ตะกร้าสินค้า ?",
      text: "หากคุณตกลง คุณจะต้องทำการเลือกสินค้าใหม่ทั้งหมด",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        actions.clearCart(true);
      }
    });
  };
  componentWillUnmount() {
    const { isEdit, actions } = this.props;
    if (!isEdit) {
      //ถ้าไม่ใช่การ edit invoice กดไปหน้า shopping ครั้งต่อไปจะไม่ clear cart
      actions.dontClearCartNextTime(true);
    } else {
      actions.dontClearCartNextTime(false);
    }
  }
  sortGroupByType = () => {
    const { types, groups } = this.props;
    let groupArr = [];
    _.forEach(
      [...types, { name: "Other", uid: "other", row: 99999999 }],
      type => {
        let filteredGroups = _.filter(groups, group => group.type === type.uid);

        if (_.size(filteredGroups) > 0) {
          groupArr = [...groupArr, ...filteredGroups];
        }
      }
    );
    return groupArr;
  };
  setModalImg = (modalTitle, imgUrl) => {
    this.setState({ modalTitle, imgUrl });
  };
  replaceUrl(imgUrl){
    return  imgUrl.replace('items_thumb','items')
  }
  render() {
    let {
      carItems,
      handleClickNextStep,
      totalQty,
      totalPrice,
      failList
    } = this.props;
    const { modalTitle, imgUrl } = this.state;

    let sortedGroup = this.sortGroupByType();
    // style={{ borderStyle: 'solid', borderColor: 'red',borderWidth }}
    return [
      <CustomContentModal title={modalTitle}>
        <center>
          <img src={this.replaceUrl(imgUrl)} width={400} height={400} />
        </center>
      </CustomContentModal>,

      <ControlBar {...this.props} clearCart={this.clearCart} />,
      _.size(failList) > 0 && (
        <div style={{ marginTop: 50 }}>
          <GroupAndItemsList
            {...this.state}
            {...this.props}
            isFail={true}
            sortedGroup={[
              {
                name: "สินค้าที่ไม่เพียงพอ",
                uid: "fail",
                items: failList
              }
            ]}
            addProductQty={this.addProductQty}
            carItems={carItems}
            decreaseProductQty={this.decreaseProductQty}
            handleOnChange={this.handleOnChange}
            handleClickNextStep={handleClickNextStep}
            isDisableNextBtn={true}
          />
        </div>
      ),
      <GroupAndItemsList
        {...this.state}
        {...this.props}
        sortedGroup={sortedGroup}
        addProductQty={this.addProductQty}
        carItems={carItems}
        decreaseProductQty={this.decreaseProductQty}
        handleOnChange={this.handleOnChange}
        handleClickNextStep={handleClickNextStep}
        setModalImg={this.setModalImg}
      />
    ];
  }
}

const mapStateToProps = state => {
  const cartSummary = cartSummarySelector(state);

  return {
    groups: groupItemSelector(state),
    carItems: state.cart.items,
    types: state.inventory.types,
    failList: state.cart.cartValidate.failList,

    // isLinked: linkedSelector.isLinkedSelector(state),

    products: cartSummary.items,
    totalQty: cartSummary.totalQty,
    totalPrice: cartSummary.totalPrice
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...cartAction }, dispatch);
  return {
    actions
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductPicker);

const GroupAndItemsList = props => {
  return (
    <div className="row mt-3">
      <div
        className="col-md-12 col-lg-10 offset-lg-1"
        style={
          props.isFail
            ? { borderStyle: "solid", borderColor: "red", borderWidth: 2 }
            : {}
        }
      >
        {_.map(props.sortedGroup, group => {
          return [
            <div
              class={props.isFail ? "mt-2" : "mt-4"}
              style={{ paddingBottom: 10 }}
              key={`g${group.uid}`}
            >
              <span style={{ fontSize: 20 }} id={group.uid} key={group.uid}>
                <td
                  class="cell-detail"
                  colSpan={5}
                  style={props.isFail ? { color: "red" } : {}}
                >
                  <span>
                    <b>
                      <u>{group.name}</u>
                    </b>
                  </span>
                </td>
              </span>
            </div>,
            _.map(group.items, item => {
              //return (<CardItemLine   item={item}  {...this.state}/>)

              //below original
              let image = item.image
                ? item.image.url
                : require("../../assets/images/hundredpixel.jpg");
              return (
                <div
                  class="card ripple"
                  style={{ marginBottom: 5 }}
                  key={`i${item.uid}`}
                  onClick={() => props.addProductQty(item.uid)}
                >
                  <div style={{ padding: 10 }}>
                    <div className="row m-0 justify-content-between">
                      <div>
                        <div className="row m-0 ">
                          <div>
                            <img
                              onClick={e => {
                                e.stopPropagation();
                                props.setModalImg(item.name, image);
                              }}
                              data-toggle="modal"
                              data-target="#customContentModal"
                              src={image}
                              width={50}
                              height={50}
                            />
                          </div>
                          <div style={{ paddingLeft: 15 }}>
                            <div>
                              <span style={{ fontSize: "1.2em" }}>
                                {item.name}
                              </span>{" "}
                              {props.carItems[item.uid] && (
                                <span
                                  style={{
                                    marginLeft: 6,
                                    fontSize: "1.1em",
                                    color: "blue",
                                    fontWeight: "bold"
                                  }}
                                >
                                  X {props.carItems[item.uid]}
                                </span>
                              )}{" "}
                            </div>
                            {props.isFail ? (
                              <div style={{ paddingTop: 4 }}>
                                <span style={{ color: "red" }}>
                                  เหลือในสต๊อค {item.balance.toLocaleString()}{" "}
                                  {item.unit}
                                </span>
                              </div>
                            ) : (
                              <div style={{ paddingTop: 4 }}>
                                <span style={{ color: "gray" }}>
                                  {item.price.toLocaleString()} THB/{item.unit}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <ControlItemLine
                        {...props}
                        item={item}
                        addProductQty={props.addProductQty}
                        decreaseProductQty={props.decreaseProductQty}
                        handleOnChange={props.handleOnChange}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          ];
        })}

        {props.isDisableNextBtn !== true && (
          <div style={{ textAlign: "right", marginTop: 20, minWidth: 50 }}>
            <button
              className="btn btn-space btn-success"
              onClick={props.handleClickNextStep}
            >
              NEXT
            </button>
          </div>
        )}
      </div>
      {/* <div className="col-12" style={{ height: 50 }}></div> */}
    </div>
  );
};

function ControlBar(props) {
  return (
    <div
      class="d-flex flex-row justify-content-center align-items-stretch"
      style={{
        backgroundColor: "white",
        margin: -25,
        height: 48,
        width: "calc(100% - 510px)",
        // zIndex: 9999,
        zIndex: 99,
        position: "fixed",
        borderBottom: "1px solid rgb(212, 212, 212)"
      }}
    >
      <div
        class="w-50"
        style={{ backgroundColor: " ", borderRight: "1px solid #d4d4d4" }}
      >
        <h4 class="text-center mt-2">
          <span style={{ marginRight: 12 }}>
            สินค้า{" "}
            <span style={{ color: "blue" }}>
              {props.totalQty.toLocaleString()}
            </span>{" "}
            รายการ{" "}
          </span>
          <span style={{ marginRight: 12 }}>
            ยอดรวม{" "}
            <span style={{ color: "blue" }}>
              {props.totalPrice.toLocaleString()}
            </span>{" "}
            บาท
          </span>
          <button
            style={{ marginRight: 0 }}
            onClick={props.clearCart}
            class="btn btn-xs btn-rounded btn-space btn-secondary"
          >
            <i class="icon icon-left mdi mdi-delete" />
            Clear
          </button>
        </h4>
      </div>
      <div class="w-50 d-flex" style={{ backgroundColor: " " }}>
        <button
          onClick={props.handleClickNextStep}
          type="button"
          class="flex-fill btn btn-link"
        >
          CHECK OUT
        </button>
      </div>
    </div>
  );
}

function ControlItemLine(props) {
  return (
    <div className="align-self-center">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <button
            style={{
              border: "1px solid #bdc0c7",
              boxShadow: "none",
              height: 36
            }}
            class="btn btn-outline-secondary"
            type="button"
            onClick={e => {
              e.stopPropagation();
              props.decreaseProductQty(props.item.uid);
            }}
          >
            -
          </button>
        </div>
        <input
          style={{ width: 60, height: 36 }}
          type="text"
          value={props.carItems[props.item.uid] || ""}
          onClick={e => e.stopPropagation()}
          onChange={e => props.handleOnChange(props.item.uid, e)}
          class="form-control text-center"
        />

        <div class="input-group-append">
          <button
            style={{
              border: "1px solid #bdc0c7",
              boxShadow: "none",
              height: 36
            }}
            onClick={e => {
              e.stopPropagation();
              props.addProductQty(props.item.uid);
            }}
            class="btn btn-outline-secondary"
            type="button"
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
}
