import React, { Component } from "react";
import "react-datasheet/lib/react-datasheet.css";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import _ from "lodash";
import Container from "./layout/Container";
import "react-block-ui/style.css";

import "./Custom.css";
import "react-notifications/lib/notifications.css";

class App extends Component {
  render() {
    return (
      <Provider store={this.props.store}>
        <Router>
          <Container />
        </Router>
      </Provider>
    );
  }
}

export default App;
