import { combineReducers } from "redux";
import inventory from "./shared/reducers/inventory/combinedReducers";
import cart from "./shared/reducers/cart/combineReducers";
import history from "./shared/reducers/history/combineReducers";
import user from "./shared/reducers/user/combineReducer";
import shop from "./shared/reducers/shop/combineReducer";
import loading from "./shared/reducers/loading/loadingReducer";
import appState from "./shared/reducers/appState/index";
import notification from "./shared/reducers/notification/notificationReducer";
import employee from "./shared/reducers/employee/employeeReducer";
import promotion from "./shared/reducers/promotion/combineReducer";

 import cartPos from "./pages/PosCart/reducer";
export default combineReducers({
  inventory,
  cart,
  history,
  user,
  shop,
  appState,
  loading,
  notification,
  employee,
  promotion,
  cartPos
});
 
