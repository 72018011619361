import React, { Component } from 'react'
import CreateUserForm from './CreateUserForm'
import { BrowserRouter as Router, withRouter, Route } from 'react-router-dom'

class SignUp extends Component {

    render() {
        const { match } = this.props

        return (
            <body class="be-splash-screen">
                <div class="be-wrapper be-login">
                    <div class="be-content">
                        <div class="main-content container-fluid">
                            <div class="splash-container">
                                <CreateUserForm match={match} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="be-scroll-top"></div>
            </body>
        );
    }
}

export default withRouter(SignUp)