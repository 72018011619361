import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import * as shopActions from "../../shared/actions/shopActions";
import swal from "sweetalert";
import { s3 } from "../../shared/awsAPIs/s3";
import moment from "moment";

const labelClass = "col-12 col-sm-3 col-form-label text-sm-right";
const inputContainerClass = "col-12 col-sm-8 col-lg-6";

class Setting extends React.Component {
  state = {
    name: "",
    emailReport: "",
    autoApproveInvoice: false
  };

  componentDidMount() {
    const { shopDetail } = this.props;
    this.setState({
      name: shopDetail.name,
      emailReport: shopDetail.emailReport || "",
      autoApproveInvoice: shopDetail.autoApproveInvoice || false
      // shippingAddress: getAddress(shopDetail, 'shipping')
    });
  }

  onChangeInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onClickSave = () => {
    const { name, emailReport, autoApproveInvoice } = this.state;
    const { actions } = this.props;

    if (name === "") {
      swal("เกิดข้อผิดพลาด", "กรุณากรอกชื่อร้าน", "error");
      return;
    }

    const data = {
      name,
      emailReport: emailReport === "" ? null : emailReport,
      autoApproveInvoice
    };

    actions.selfUpdateShopDetail(data, () => {
      swal("สำเร็จ !", "บันทึกข้อมูลร้านค้าสำเร็จ", "success");
      return;
    });
  };

  updateLogo = image => {
    const { actions, loggedIn } = this.props;
    let fileName = moment().valueOf();
    var params = {
      Bucket: "inventory-management",
      Key: `${loggedIn.shopId}/logo/${fileName}`,
      Body: image
      // ContentType: "application/pdf",
      // ContentDisposition: 'inline'
    };

    s3.upload(params, (err, res) => {
      console.log("upload complete pdf");
      if (err) {
        alert(err);
        return;
      }
      actions.updateLogoUrl(res.Location);
    });
  };

  render() {
    const { name, emailReport, autoApproveInvoice } = this.state;

    return (
      <div className="main-content container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-border-color card-border-color-primary">
              <div class="card-header card-header-divider">
                About Cafe
                {/* <span class="card-subtitle">These are the basic bootstrap form elements</span> */}
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group row">
                    <label class={labelClass} for="inputText3">
                      ชื่อร้าน/สาขา
                    </label>
                    <div class={inputContainerClass}>
                      <input
                        class="form-control"
                        value={name}
                        name="name"
                        onChange={this.onChangeInput}
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class={labelClass} for="inputText3">
                      Email Report
                    </label>
                    <div class={inputContainerClass}>
                      <input
                        class="form-control"
                        value={emailReport}
                        name="emailReport"
                        onChange={this.onChangeInput}
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class={labelClass} for="inputText3">
                      Logo บริษัท
                    </label>
                    <div class={inputContainerClass}>
                      <input
                        class="inputfile"
                        id="file-2"
                        type="file"
                        name="file-2"
                        data-multiple-caption="{count} files selected"
                        onChange={e => {
                          const file = e.target.files[0];
                          this.updateLogo(file);
                        }}
                        accept="image/*"
                      />
                      <label class="btn-primary" for="file-2">
                        {" "}
                        <i class="mdi mdi-upload" />
                        <span>Browse files...</span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class={labelClass} for="inputText3">
                      Auto Approve Invoice
                    </label>
                    <div class={inputContainerClass}>
                      <label
                        class="custom-control custom-radio custom-control-inline"
                        onClick={() =>
                          this.setState({
                            autoApproveInvoice: true
                          })
                        }
                      >
                        <input
                          class="custom-control-input"
                          type="radio"
                          name="radio-inline"
                          checked={autoApproveInvoice === true}
                        />
                        <span class="custom-control-label">Yes</span>
                      </label>
                      <label
                        class="custom-control custom-radio custom-control-inline"
                        onClick={() =>
                          this.setState({
                            autoApproveInvoice: false
                          })
                        }
                      >
                        <input
                          class="custom-control-input"
                          type="radio"
                          name="radio-inline"
                          checked={autoApproveInvoice === false}
                        />
                        <span class="custom-control-label">No</span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class={labelClass} />
                    <div class={inputContainerClass}>
                      <button
                        class="btn btn-space btn-success hover"
                        onClick={e => {
                          e.preventDefault();
                          this.onClickSave();
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  {/* <span style={{color:'red'}}>ทำแค่บันทึกชื่อ HQ</span> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    shopDetail: state.shop.detail,
    loggedIn: state.user.loggedIn
  };
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ ...shopActions }, dispatch);
  return {
    actions
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
