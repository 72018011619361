import React from "react";
import _ from "lodash";

class ProductList extends React.Component {
  state = {
    expandHolder: {}
  };

  setExpand = uid => {
    const { expandHolder } = this.state;
    let newExpandHolder = _.cloneDeep(expandHolder);
    if (newExpandHolder[uid]) {
      delete newExpandHolder[uid];
    } else {
      newExpandHolder[uid] = true;
    }
    this.setState({
      expandHolder: newExpandHolder
    });
  };

  render() {
    const { products, uidHolder } = this.props;
    const { expandHolder } = this.state;
    return (
      <div>
        {_.map(products, type => {
          return [
            <ItemLine
              mode="type"
              item={type}
              {...this}
              {...this.state}
              {...this.props}
              selected={uidHolder[type.uid]}
              selectedSymbol={uidHolder[type.uid]}
            />,
            expandHolder[type.uid] &&
              _.map(type.groups, group => {
                return [
                  <ItemLine
                    mode="group"
                    item={group}
                    {...this}
                    {...this.state}
                    {...this.props}
                    selected={uidHolder[group.uid]}
                    selectedSymbol={uidHolder[type.uid] || uidHolder[group.uid]}
                  />,
                  expandHolder[group.uid] &&
                    _.map(group.items, item => {
                      return (
                        <ItemLine
                          mode="item"
                          item={item}
                          {...this}
                          {...this.state}
                          {...this.props}
                          selected={uidHolder[item.uid]}
                          selectedSymbol={
                            uidHolder[type.uid] ||
                            uidHolder[group.uid] ||
                            uidHolder[item.uid]
                          }
                        />
                      );
                    })
                ];
              })
          ];
        })}
      </div>
    );
  }
}

const ItemLine = ({
  mode,
  item,
  expandHolder,
  setExpand,
  selectItem,
  selected,
  selectedSymbol
}) => {
  function getItemLabel() {
    if (mode === "type") {
      return (
        <span style={{ color: "blue", fontWeight: "bolder" }}>
          ===={item.name}====
        </span>
      );
    } else if (mode === "group") {
      return (
        <span style={{ fontWeight: "bolder", paddingLeft: 15 }}>{`${
          item.name
        }`}</span>
      );
    } else {
      return <span style={{ paddingLeft: 35 }}>{`- ${item.name}`}</span>;
    }
  }
  function getBackgroundColor() {
    if (mode === "group") {
      return "#b3c5e2";
    } else if (mode === "item") {
      return "#d1e1fc";
    } else {
      return "white";
    }
  }
  return (
    <span
      href={`#${mode}${item.uid}`}
      class={"list-group-item"}
      onClick={e => {
        e.preventDefault();
        if (mode === "item") return;
        setExpand(item.uid);
      }}
      // style={readOnly ? {} : { zIndex: 9999 }}
      style={{
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: getBackgroundColor()
      }}
    >
      <div className="row m-0 justify-content-between">
        <div>
          <span
            class="mdi mdi-check-circle pr-2"
            style={{
              fontSize: 25,
              color: "green",
              visibility: selectedSymbol ? "visible" : "hidden"
            }}
          />

          <span>{getItemLabel()}</span>
        </div>
        <div>
          <div className="row m-0">
            {mode !== "item" && (
              <div>
                <span
                  class={`mdi mdi-chevron-${
                    expandHolder[item.uid] ? "up" : "down"
                  } pr-3`}
                  style={{ fontSize: 25, color: "blue" }}
                />
              </div>
            )}
            <div
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                if (item.uid === "other") return;
                selectItem(item.uid, mode);
              }}
            >
              <label
                class="custom-control custom-checkbox m-0 p-0"
                style={{
                  visibility: item.uid !== "other" ? "visible" : "hidden"
                }}
              >
                <input
                  class="custom-control-input"
                  type="checkbox"
                  checked={selected}
                />
                <span class="custom-control-label custom-control-color">
                  {/* Option 1 */}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default ProductList;
