import * as type from '../types/notification'
import * as firebaseRef from '../firebaseApi/firebaseRef'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment'

let notShow = true

export const listenNotification = () => {
    return (dispatch, getState) => {
        let { user } = getState()
        let shopId = user.loggedIn.shopId
        firebaseRef.notificationRef.child(shopId)
            .on('value', (snapshot) => {
                let data = snapshot.val();
                if (!notShow) {
                    NotificationManager.info(data);
                } else {
                    notShow = false
                }
                dispatch({
                    type: type.SET_LAST_NOTI_TIMESTAMP,
                    payload: moment().toISOString()
                });
            })

    };
};